import { api, escalateError, getResponseData } from './index';

export default class userPartners {
    static async getUser(body) {
        return api.post('/api/userAdmin', body)
            .then(getResponseData)
            .catch(escalateError);
    }

    static async updateUser(emailUser, body) {
        const paramsOne = `emailUser=${emailUser}`;

        return api.post(`api/userAdmin/updateUser?${paramsOne}`, body)
            .then(getResponseData)
            .catch(escalateError);
    }

    static async createUser(dataPartner, lastEditor, notificationEmail, notificationTelegram) {
        return api.post('/api/userAdmin/createUser', {
            dataPartner,
            lastEditor,
            notificationEmail,
            notificationTelegram,
        })
        .then(getResponseData)
        .catch(escalateError);
    }

    static async allRoutes() {
        return api.get('/api/userAdmin/routes')
        .then(getResponseData)
        .catch(escalateError);
    }
}
