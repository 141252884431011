import React, { useState, useEffect, useContext } from 'react';
import MDIcon from '../mdc/MDIcon';
import { ListItem, ListItemText } from '@rmwc/list';
import style from './navbarLate.module.css';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../../contexts/user-context';
import imgLogWhite from '../../imgs/logoWhite.png';
const NavBarLate = () => {
  const { currentUser } = useContext(UserContext);
  const [navbarExtends, setNavbarExtends] = useState(false);
  const [rute, setRute] = useState([]);
  const history = useHistory();

  useEffect(() => {
    if (!currentUser) return;
    const { allowRoutes } = currentUser;
    setRute((prev) => prev.concat(allowRoutes));
  }, [currentUser]);
  return (
    <>
      {
        currentUser
        && (<>
          <header
            className={`${style.lateralHeader}
            ${navbarExtends ? style.navbarOpen : style.navbarClose}`}>
            <img src={imgLogWhite} alt={'Go Oportunidades'} className={style.imgLogo}/>
            <button className={`${style.buttonlateral} ${style.btnMenu}`}
                    onClick={() => setNavbarExtends(!navbarExtends)}>
              <span className={!navbarExtends ? style.createArrow : style.createdX }/>
            </button>
            <ul>
              {
                (rute.includes('showComponents') || rute.includes('*'))
                && (
                  <ListItem onClick={(e) => {
 history.push('/showComponents');
                  window.location.reload();
}}>
                    <ListItemText className={style.itemNavbar}>
                      <MDIcon icon={'filter'} />
                      <p >Intereses</p>
                    </ListItemText>
                  </ListItem>
                )
              }
              <ListItem onClick={(e) => history.push('/')}>
                <ListItemText className={style.itemNavbar}>
                  <MDIcon icon={'magnify'} />
                  <p>Buscar Oportunidad</p>
                </ListItemText>
              </ListItem>

              {
                (rute.includes('showFolder') || rute.includes('*'))
                && (
                  <ListItem>
                    <ListItemText className={style.itemNavbar}
                                  onClick={() => history.push('/showFolder')}>
                      <MDIcon icon={'folder'} />
                      <p>Ver Carpeta</p>
                    </ListItemText>
                  </ListItem>
              )
              }
              {
              (rute.includes('showpartners') || rute.includes('*'))
              && (
                <ListItem>
                  <ListItemText className={style.itemNavbar}
                                onClick={() => history.push('/showpartners')}>
                    <MDIcon icon={'account-multiple'} />
                    <p>Colaboradores</p>
                  </ListItemText>
                </ListItem>
              )
              }

              {
                (rute.includes('admin') || rute.includes('*'))
                && (
                  <ListItem>
                    <ListItemText className={style.itemNavbar}
                                  onClick={() => history.push('/admin')}>
                      <MDIcon icon={'account-lock-outline'} />
                      <p>Administrador</p>
                    </ListItemText>
                  </ListItem>
                )
              }
              {
              (rute.includes('editUser') || rute.includes('*'))
              && (
                <ListItem>
                  <ListItemText className={style.itemNavbar}
                                onClick={() => history.push('/editUser')}>
                    <MDIcon icon={'account'} />
                    <p>Mi perfil</p>
                  </ListItemText>
                </ListItem>
              )
              }
              {
              (rute.includes('statistics') || rute.includes('*'))
              && (
                <ListItem>
                  <ListItemText className={style.itemNavbar}
                                onClick={() => history.push('/statistics')}>
                    <MDIcon icon={'chart-bar'} />
                    <p>Dashboard</p>
                  </ListItemText>
                </ListItem>
              )
              }
              {(rute.includes('statistics_Admin') || rute.includes('*'))
                    && (
                      <ListItem>
                        <ListItemText className={style.itemNavbar}
                                      onClick={() => history.push('/statisticsAdmin')}>
                          <MDIcon icon={'chart-pie'} />
                          <p>Dashboard Administrador</p>
                        </ListItemText>
                      </ListItem>)
              }
              {
                    (rute.includes('profileActions') || rute.includes('*'))
                    && (
                      <ListItem>
                        <ListItemText className={style.itemNavbar}
                                      onClick={() => history.push('/editProfils')}>
                          <MDIcon icon={'card-account-details'} />
                          <p>Preferencias Usuario</p>
                        </ListItemText>
                      </ListItem>
                    )
                  }
              {
                    (rute.includes('interpretationDocuments') || rute.includes('*'))
                    && (
                      <ListItem>
                        <ListItemText className={style.itemNavbar}
                                      onClick={() => history.push('/interpretationDocuments')}>
                          <MDIcon icon={'file-document-edit-outline'} />
                          <p>Interpretacion de Documentos</p>
                        </ListItemText>
                      </ListItem>
                    )
                  }
            </ul>
          </header>
          <div className={`${navbarExtends ? style.navbarOpen : style.navbarClose}
            ${style.overlayNavbar}`} onClick={() => setNavbarExtends(false)}></div>
        </>)
      }
    </>
  );
};

export default NavBarLate;
