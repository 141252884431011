import { api, getResponseData, escalateError } from './index';
export default class DataApi {
    static async getDataApi(body) {
        return api.post('/api/readData', body)
            .then(getResponseData)
            .catch(escalateError);
    };

    static async getDataApiSecopI({
        limite = '',
        pag = '',
        idProcess = '',
        sendIdProcesPagSup = '',
        idArray = 'null',
        pagesBack = 'null',
    }) {
        const paramsOne = `limite=${limite}&pagina=${pag}&idProcess=${idProcess}`;
        const paramsTwo = `&idProcessNext=${sendIdProcesPagSup}&arrayPags=${idArray}`;
        const paramsTree = `&numPages=${pagesBack}`;
        return api.get(`/api/readDataSecopI?${paramsOne}${paramsTwo}${paramsTree}`)
            .then(getResponseData)
            .catch(escalateError);
    }

    static async getPerfilUser(userId, enterprise, rol) {
        const paramsOne = `userId=${userId}&nameEnterprise=${enterprise}`;
        return api.get(`/api/userPreferences?${paramsOne}&rol=${rol}`)
            .then(getResponseData)
            .catch(escalateError);
    }

    static async getDataByProfile({ nameProfile, userId, nameEnterprise }) {
        const params = `nameProfile=${nameProfile}&userId=${userId}&enterprise=${nameEnterprise}`;
        return api.get(`/api/readData?${params}`)
            .then(getResponseData)
            .catch(escalateError);
    }
};
