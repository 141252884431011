import React, { useState, createContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
// Crea un objeto de contexto para el historial de navegación
export const NavigationContext = createContext([]);

const NavigationProvider = ({ children }) => {
  const history = useHistory();
  const [navigationHistory, setNavigationHistory] = useState([]);
  const addToHistory = (componentName) => {
    setNavigationHistory([...navigationHistory, componentName]);
  };
  const goBack = () => {
      setNavigationHistory(prevHistory => {
        const updatedHistory = [...prevHistory];
        updatedHistory.pop();
        // Redirecciona al componente anterior
        const previousComponent = updatedHistory[updatedHistory.length - 1];
        history.push(`/${previousComponent}`);
        return updatedHistory;
      });
  };
  return (
    <NavigationContext.Provider
      value={{
        navigationHistory,
        addToHistory,
        goBack,
      }}
    >
      {children}
    </NavigationContext.Provider>
  );
};
NavigationProvider.propTypes = {
  children: PropTypes.oneOfType(
      [PropTypes.object, PropTypes.arrayOf(PropTypes.element)]).isRequired,
};

export default NavigationProvider;
