import { api, getResponseData, escalateError } from './index.js';
export default class DataUNSPSC {
    static async getInfoBySegment() {
        return api.get('api/segmenUNSPSC').then(responseData => {
            const dataSend = {};
            const { data } = responseData;
            dataSend.dataUNSPSC = {};
            Object.keys(data.data).forEach((responseNames) => {
                if (responseNames !== 'Familias') {
                    dataSend.dataUNSPSC[responseNames] = (data.data[responseNames]);
                    return dataSend; // asi no continua
                }
                dataSend.Familia = data.data.Familias;
                return dataSend;
            });
            return dataSend;
        })
            .catch(escalateError);
    }

    static async getInfoProducts(data, limit = 10) {
        return api.get(`api/segmenUNSPSC/searchData?valueSearch=${data}&limit=${limit}`)
        .then(responseData => {
            const { data } = responseData;
            return data;
        })
            .catch(escalateError);
    }

    static async postPerfil(profileUser, enterprise, userId) {
        return api.post('api/segmenUNSPSC', {
            enterprise,
            userId,
            profileUser,
        }).then(getResponseData)
            .catch(escalateError);
    }

    static async updateProfiles({ userId, nameEnterprise, profileId, dataProfile }) {
        return api.post('api/userPreferences/update', {
            userId,
            nameEnterprise,
            profileId,
            dataProfile,
        })
            .then(getResponseData)
            .catch(escalateError);
    }

    static async deletedProfiles({ userId, nameEnterprise, idProfile, dataProfile }) {
        return api.post('api/userPreferences/deleted', {
            userId,
            nameEnterprise,
            idProfile,
            dataProfile,
        })
            .then(getResponseData)
            .catch(escalateError);
    }

    static async getDtaProfileByRol(userId, enterprise, rol) {
        const paramsOne = `userId=${userId}&nameEnterprise=${enterprise}`;
        return api.get(`/api/userPreferences/profiles?${paramsOne}&rol=${rol}`)
            .then(getResponseData)
            .catch(escalateError);
    }
}
