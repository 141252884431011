import React, { useContext, useState, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import LoginForm from '../components/login/Login';
import ShowInfoProcess from '../components/showInfoProcess/ShowInfoProcess';
import NotFound from '../components/sites/NotFound';
import ShowAllComponents from '../components/perfilUsers/showAllComponents';
import Result from '../components/resultSearch/Result';
import CreatedPartnerEnterprise from '../components/showAndCreatedPJ/CreatedPartner';
import CreatedPartnerAdmin from '../components/admin/CreateUser';
import ShowPartners from '../components/showAndCreatedPJ/ShowPartner';
import CreatedFolder from '../components/folder/createFolder';
import ShowFolder from '../components/folder/showFolder';
import ShowUsers from '../components/admin/ShowUser';
import Dashboard from '../components/dashboardGen/Dashboard';
import EditUserForm from '../components/editUser/editUser';
import Statistics from '../components/statistics/statistics';
import StaticsAdmin from '../components/statistics/staticsAdmin';
import UsersProfileActions from '../components/perfilUsers/UsersProfileActions';
import { UserContext } from '../contexts/user-context';
import InterpretationDocument from '../components/interpretationDocument/interpretationDocument';

const Routes = () => {
  const { currentUser } = useContext(UserContext);
  const [allowRoutes, setAllowRoutes] = useState([]);
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (!currentUser) return;
    setUser(currentUser);
    setAllowRoutes(currentUser.allowRoutes);
  }, [currentUser]);

  return (
    <Switch>
      <Route exact path={'/'} component={!user ? LoginForm : Result} />
      <Route path={'/resultProcess/:idOportunity'} component={ShowInfoProcess} />
      <Route exact path={'/dashboardGen'}>
        { allowRoutes.includes('dashboardGen') || allowRoutes.includes('*')
            ? <Dashboard />
            : <NotFound />
        }
      </Route>
      <Route exact path={'/editUser'}>
        { allowRoutes.includes('editUser') || allowRoutes.includes('*')
            ? <EditUserForm />
            : <NotFound />
        }
      </Route>
      <Route exact path={'/showpartners'}>
        {
          allowRoutes.includes('showpartners') || allowRoutes.includes('*')
            ? <ShowPartners />
            : <NotFound />
        }
      </Route>
      <Route exact path={'/createdPartners'}>
        {
          allowRoutes.includes('createdPartners') || allowRoutes.includes('*')
            ? <CreatedPartnerEnterprise />
            : <NotFound />
        }
      </Route>
      <Route exact path={'/folders/:idFolder'}>
        {
          allowRoutes.includes('folders') || allowRoutes.includes('*')
            ? <Result />
            : <NotFound />
        }
      </Route>
      <Route exact path={'/showComponents'}>
        {
          allowRoutes.includes('showComponents') || allowRoutes.includes('*')
            ? <ShowAllComponents />
            : <NotFound />
        }
      </Route>
      <Route exact path={'/showComponents/:profileId'}>
        {
          allowRoutes.includes('profileActions') || allowRoutes.includes('*')
            ? <ShowAllComponents />
            : <NotFound />
        }
      </Route>
      <Route exact path={'/admin'}>
        {
          allowRoutes.includes('admin') || allowRoutes.includes('*')
            ? <ShowUsers />
            : <NotFound />
        }
      </Route>
      <Route exact path={'/showFolder'}>
        {
          allowRoutes.includes('showFolder') || allowRoutes.includes('*')
            ? <ShowFolder />
            : <NotFound />
        }
      </Route>
      <Route exact path={'/createdFolder'}>
        {
          allowRoutes.includes('createdFolder') || allowRoutes.includes('*')
            ? <CreatedFolder />
            : <NotFound />
        }
      </Route>
      <Route exact path={'/statistics'}>
        {
          allowRoutes.includes('statistics') || allowRoutes.includes('*')
            ? <Statistics />
            : <NotFound />
        }
      </Route>
      <Route exact path={'/statisticsAdmin'}>
        {
          allowRoutes.includes('statistics_Admin') || allowRoutes.includes('*')
            ? <StaticsAdmin />
            : <NotFound />
        }
      </Route>
      <Route exact path={'/createUser'}>
        {
          allowRoutes.includes('createUserAdmin') || allowRoutes.includes('*')
            ? <CreatedPartnerAdmin />
            : <NotFound />
        }
      </Route>
      <Route exact path={'/editProfils'}>
        {
          allowRoutes.includes('profileActions') || allowRoutes.includes('*')
          ? <UsersProfileActions />
          : <NotFound />
        }
      </Route>
      <Route exact path={'/interpretationDocuments'}>
        {
          allowRoutes.includes('interpretationDocuments') || allowRoutes.includes('*')
          ? <InterpretationDocument />
          : <NotFound />
        }
      </Route>
      <Route component={NotFound} />
      <Route component={NotFound} />
    </Switch>);
};

export default Routes;
