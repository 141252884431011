import React, { useState, useEffect, useContext } from 'react';
import style from './showUser.module.css';
import {
    DataTable, DataTableContent,
    DataTableHead, DataTableBody, DataTableCell, DataTableRow, DataTableHeadCell
} from '@rmwc/data-table';
import { Switch } from '@rmwc/switch';
import { Button } from '@rmwc/button';
import user from '../../api/admin';
import Loader from '../loader/Loader';
import MDIcon from '../mdc/MDIcon';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../../contexts/user-context';
import NotificationContext from '../../contexts/notification-context';

const ShowUsers = () => {
    const { currentUser } = useContext(UserContext);
    const { dispatchData } = useContext(NotificationContext);
    const history = useHistory();
    const [dataPartners, setDataPartners] = useState();
    const [idUserForPags, setIdUserForPags] = useState([]);
    const [pag, setPag] = useState(1);
    const [, setAllRoutes] = useState([]);
    const [lastIndex, setLastIndex] = useState('');
    const [firstIndex, setFirstIndex] = useState('');
    const [haveNextPage, setHaveNextPage] = useState(false);
    const [previous, setPrevious] = useState(false);

    useEffect(() => {
        user.allRoutes().then(({ data = [] }) => {
        const { allowRoutes } = data;
        setAllRoutes((prv) => prv.concat(allowRoutes));
        })
        .catch(e => {
          const { data } = e;
          dispatchData({ text: data.error, error: e });
        });
    }, []);

    useEffect(() => {
        const { rol } = currentUser;
        const dataUser = {
          rol, lastIndex, firstIndex, previous,
        };
        user.getUser(dataUser)
            .then(({ data: dataUsers }) => {
              const { firstIndex, lastIndex, data, nextPage } = dataUsers;
              setFirstIndex(firstIndex);
              setLastIndex(lastIndex);
              setHaveNextPage(nextPage);
              setIdUserForPags(idUserForPags);
              setDataPartners(data.filter(({ rol }) => rol !== 'admin'));
            })
            .catch(e => {
              const { data } = e;
              dispatchData({ text: data.error, error: e });
            });
    }, [pag]);

    const editCollaborator = (email, status) => {
        const userRow = dataPartners.find(({ emailUser }) => emailUser === email);
        const userIndex = dataPartners.indexOf(userRow);
        const firstPart = dataPartners.slice(0, userIndex);
        const lastPart = dataPartners.slice(userIndex + 1, dataPartners.lenght);
        const body = {
            delete: !status,
        };

        user.updateUser(email, body)
            .then(() => {
                userRow.delete = !status;
                firstPart.push(userRow);
                setDataPartners([...firstPart, ...lastPart]);
            })
            .catch((error) => dispatchData({ text: error, error }));
    };

    const showDataTable = () => {
      return dataPartners.map((responseNames, index) => (
        <DataTableRow key={index}>
          <DataTableCell>{responseNames.nameUser || null}</DataTableCell>
          <DataTableCell>{responseNames.emailUser || null}</DataTableCell>
          <DataTableCell>
            <Switch label={responseNames.delete ? 'Eliminado' : 'Activo'}
                    checked={!responseNames.delete}
                    onChange={(evt) =>
                          editCollaborator(
                              responseNames.emailUser,
                              !!evt.currentTarget.checked
                          )}
                  />
          </DataTableCell>
        </DataTableRow>
      ));
    };

    return (<>
      <div className={'generalContainer'}>
        <div className={'containerModule flex-column'}>
          <div className={style.contentUser}>
            <h1 className={style.title}>USUARIOS GO OPORTUNIDADES</h1>
            <Button className={style.button} id={style.create}
                    onClick={() => history.push('createUser')}>
              <MDIcon icon={'account-plus-outline'} />
                            &nbsp;Crear Usuario
            </Button>
            <DataTable>
              <DataTableContent>
                <DataTableHead>
                  <DataTableRow>
                    <DataTableHeadCell>Nombre</DataTableHeadCell>
                    <DataTableHeadCell>Correo</DataTableHeadCell>
                    <DataTableHeadCell>Estado</DataTableHeadCell>
                  </DataTableRow>
                </DataTableHead>
                <DataTableBody>
                  {
                    dataPartners
                    ? <> {showDataTable()} </>
                    : <Loader />
                  }
                </DataTableBody>
              </DataTableContent>
            </DataTable>
            <div className={style.container__butonsPag}>
              { pag > 1
                    && <button className={style.butonsPag}
                               onClick={() => {
                   setPrevious(true);
                   setPag((pag - 1));
                }}>
                      <MDIcon icon={'arrow-left'} />Anterior
                    </button> }
              <div className={style.centro}>
                <Button key={pag} className={style.butonsPagNumber} label={pag} />
              </div>
              { haveNextPage
                    && <button className={style.butonsPag} onClick={() => {
                      setPrevious(false);
                      setPag((pag + 1));
                    }}>
                      Siguiente
                      <MDIcon icon={'arrow-right'} />
                    </button>
                    }
            </div>
          </div>
        </div>
      </div>
    </>
    );
};
export default ShowUsers;
