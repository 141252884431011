import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/analytics';
import 'firebase/messaging';
import 'firebase/auth';
import firebaseConfigDev from './firebase-config.json';
import firebaseConfigProd from './firebase-config-prod.json';
const firebaseConfigData = (!process.env.REACT_APP_ENV || process.env.REACT_APP_ENV === 'dev')
  ? firebaseConfigDev
  : firebaseConfigProd;
firebase.initializeApp(firebaseConfigData);
try {
  firebase.analytics();
} catch (error) {
  // eslint-disable-next-line no-console
  console.log(error);
}

if (firebase.messaging.isSupported()) {
  const messaging = firebase.messaging();
  messaging.requestPermission()
    .then(() => {
      return messaging.getToken();
    })
    .then((token) => {
      localStorage.setItem('pushToken', token);
    })
    .catch(() => { });
}

export default firebase;
