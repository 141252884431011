import { apiOpenai, escalateError, getResponseData } from './index';

export default class OpenaiConsultAPI {
  static async responsesUploadedFile(formData) {
    return await apiOpenai.post('/respuestas/', formData)
      .then(getResponseData)
      .catch(escalateError);
  }

  static async getLoadedPdfs() {
    return await apiOpenai.get('/pdf_cargados/')
    .then(getResponseData)
    .catch(escalateError);
  }

  static async getResponsesExistingFile(nameData) {
    return await apiOpenai.post('/consultar_respuestas/', nameData)
      .then(getResponseData)
      .catch(escalateError);
  }

  static async getResponseFreeQuestion(questionData) {
    return await apiOpenai.post('/pregunta_libre/', questionData)
      .then(getResponseData)
      .catch(escalateError);
  }

  static async responsesUploadedFileScanned(formDataScan) {
    return await apiOpenai.post('/respuestas_escaneado/', formDataScan)
      .then(getResponseData)
      .catch(escalateError);
  }
}
