export default class Utils {
  static preventTextInNumberInput(e) {
    const key = e.keyCode || e.which || -1;
    if (key < 48 || key > 57) e.preventDefault();
  }

  static validateEmail(email) {
    try {
      return (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,7})+$/.test(email));
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);
      return false;
    }
  }

  static validateFields(fields) {
    const objectFields = Object.entries(fields);
    let error = 0;
    objectFields.forEach(([key, value]) => {
      if (!value || value.length < 0) {
        error += 1;
      }
    });
    return error;
  };

  static validateNotLeter(e) {
    const key = e.keyCode || e.which || -1;
    if (key < 48 || key > 57) e.preventDefault();
  }

  static orderArray(list, param, order) {
    let data = [];
    try {
      data = (list || []).sort((a, b) => {
        return a[param].toLowerCase() > b[param].toLowerCase() ? order : -order;
      });
    } catch (err) {
      data = list;
    }
    return data;
  };
}
