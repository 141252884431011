import React, { useState, useEffect, useContext } from 'react';
import { TextField } from '@rmwc/textfield';
import style from './editUser.module.css';
import DataRegister from '../../api/Register';
import userPartners from '../../api/showAndCreatedPartners';
import { Switch } from '@rmwc/switch';
import { UserContext } from '../../contexts/user-context';
import NotificationContext from '../../contexts/notification-context';
import Utils from '../../utils/Utils';
import { ERROR, SUCCESSFULL } from '../../utils/Messages';
import userImage from '../../imgs/user avatar.png';
import { NavigationContext } from '../../contexts/navigation-context';

const EditUserForm = () => {
  const { currentUser } = useContext(UserContext);
  const { picture } = currentUser || {};
  const { dispatchData } = useContext(NotificationContext);
  const { emailUser } = currentUser;
  const [, setDisabled] = useState(false);
  const [rol, setRol] = useState('user');
  const [entrepriseNit, setEntrepriseNit] = useState('');
  const [cedUser, setCedUser] = useState('');
  const [cityEnterprise, setCityEnterprise] = useState('');
  const [nameUser, setNameUSer] = useState('');
  const [telEnterprise, setTelEnterprise] = useState('');
  const [telUser, setTelUser] = useState('');
  const [sendEmails, setSendEmails] = useState(false);
  const [sendMsgTelegram, setSendMsgTelegram] = useState(false);
  const { navigationHistory, goBack, addToHistory } = useContext(NavigationContext);

  useEffect(() => {
    addToHistory('editUser');
    DataRegister.getUserByEmail(emailUser).then((dataResponse) => {
      const {
        entrepriseNit,
        cedUser,
        cityEnterprise,
        nameUser,
        telEnterprise,
        telUser,
        rol,
        notificationEmail,
        notificationTelegram,
      } = dataResponse.data;
      setCedUser(cedUser);
      setEntrepriseNit(entrepriseNit);
      setCityEnterprise(cityEnterprise);
      setNameUSer(nameUser);
      setTelEnterprise(telEnterprise);
      setTelUser(telUser);
      setRol(rol);
      setSendEmails(notificationEmail);
      setSendMsgTelegram(notificationTelegram);
    });
  }, []);

  const insertUser = () => {
    const { nameEnterprise } = currentUser;
      const body = nameEnterprise
      ? {
        entrepriseNit,
        cedUser,
        cityEnterprise,
        nameUser,
        telEnterprise,
        telUser,
        rol,
      }
      : {
        cedUser,
        nameUser,
        telUser,
        rol,
      };
      const errors = Utils.validateFields(body);
        if (errors > 0) return dispatchData({ text: ERROR.INCOMPLETE_FORM });
      if (rol === 'naturalPerson') {
        return DataRegister.editUser(emailUser, body)
        .then((dataResponse) => dispatchData({ text: SUCCESSFULL.CREATE_USER }))
        .catch((error) => dispatchData({ text: ERROR.UPDATE_ERROR, error }))
        .finally(() => setDisabled(false));
      }

      return userPartners.editCollaborator(emailUser, nameEnterprise, body)
        .then((dataResponse) => dispatchData({ text: SUCCESSFULL.CREATE_USER }))
        .catch((error) => dispatchData({ text: ERROR.UPDATE_ERROR, error }))
        .finally(() => setDisabled(false));
    };

    const notification = () => {
      const { nameEnterprise } = currentUser;
      const body = {
        notificationEmail: sendEmails,
        notificationTelegram: sendMsgTelegram,
      };

      if (rol === 'naturalPerson' || rol === 'admin') {
        return DataRegister.editUser(emailUser, body)
          .then(() => dispatchData({ text: SUCCESSFULL.EDIT_NOTIFICATION }))
          .catch((error) => dispatchData({ text: ERROR.UPDATE_ERROR, error }));
      }

      return userPartners.editCollaborator(emailUser, nameEnterprise, body)
        .then(() => dispatchData({ text: SUCCESSFULL.EDIT_NOTIFICATION }))
        .catch((error) => dispatchData({ text: ERROR.UPDATE_ERROR, error }));
    };

  return (
    <div className={style.contenedor}>
      {navigationHistory.length > 1 && (
        <button className={style.buttonPop} type={'button'} onClick={goBack}>Regresar</button>
      )}
      <img src={picture || userImage} alt={'user'} className={style.imgCenter}/>
      <form action={''} className={style.formulario}>
        <div className= {style.formulario__grupo}>
          {rol === 'enterprise' && (<label className={style.formulario__label}>Nit</label>)}
          <div className={style.formulario__grupo_input}>
            {rol === 'enterprise' && (
              <TextField
              onChange={(e) => setEntrepriseNit(e.target.value)}
              value={entrepriseNit}
              type={'number'}
              className={style.formulario__input}
            />
            )}
          </div>
        </div>
        <div className= {style.formulario__grupo}>
          <label className={style.formulario__label}>Nombre</label>
          <div className={style.formulario__grupo_input}>
            <TextField
              onChange={(e) => setNameUSer(e.target.value)}
              value={nameUser}
              type={'text'}
              className={style.formulario__input}
            />
          </div>
        </div>
        <div className= {style.formulario__grupo}>
          {rol === 'enterprise' && (<label className={style.formulario__label}>
            Ciudad Empresa</label>)}
          <div className={style.formulario__grupo_input}>
            {rol === 'enterprise' && (<TextField
              onChange={(e) => setCityEnterprise(e.target.value)}
              value={cityEnterprise}
              type={'text'}
              className={style.formulario__input}
              />
            )}
          </div>
        </div>
        <div className= {style.formulario__grupo}>
          {rol === 'enterprise' && (<label className={style.formulario__label}>
            Telefono de la empresa</label>)}
          <div className={style.formulario__grupo_input}>
            {rol === 'enterprise' && (<TextField
              onChange={(e) => setTelEnterprise(e.target.value)}
              value={telEnterprise}
              type={'number'}
              className={style.formulario__input}
              />
            )}
          </div>
        </div>
        <div className= {style.formulario__grupo}>
          <label className={style.formulario__label}>Cedula</label>
          <div className={style.formulario__grupo_input}>
            {rol === 'enterprise' && (<TextField
              onChange={(e) => setCedUser(e.target.value)}
              value={cedUser}
              type={'number'}
              className={style.formulario__input}
              />
            )}
          </div>
        </div>
        <div className= {style.formulario__grupo}>
          <label className={style.formulario__label}>Telefono del usuario</label>
          <div className={style.formulario__grupo_input}>
            {rol === 'enterprise' && (<TextField
                onChange={(e) => setTelUser(e.target.value)}
                value={telUser}
                type={'number'}
                className={style.formulario__input}
              />
            )}
          </div>
        </div>
        <div className= {style.formulario__grupo}>
          <Switch
                  checked={sendEmails}
                  onClick={(e) => setSendEmails(!!e.currentTarget.checked)}
                  >Autoriza el envio de correos?
          </Switch>
        </div>
        <div className= {style.formulario__grupo}>
          <Switch
                checked={sendMsgTelegram}
                onClick={(e) => setSendMsgTelegram(!!e.currentTarget.checked)
                } >Autoriza el envio de mensajes por telegram?
          </Switch>
        </div>
        <div className={style.formulario__grupo}>
          <div className={style.formulario__grupo_btn_enviar}>
            <button className= {style.formulario__btn} onClick={insertUser}>
              Guardar Cambios</button>
          </div>
        </div>
        <div className={style.formulario__grupo}>
          <div className={style.formulario__grupo_btn_enviar}>
            <button className= {style.formulario__btn} onClick={notification}>
              Guardar Notificaciones</button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditUserForm;
