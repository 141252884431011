import React, { useState, useContext } from 'react';
import style from './CreatedUser.module.css';
import { Card } from '@rmwc/card';
import { TextField } from '@rmwc/textfield';
import { Switch } from '@material-ui/core';
import user from '../../api/admin';
import { useHistory } from 'react-router-dom';
import firebase from '../../firebase';
import { ERROR, SUCCESSFULL } from '../../utils/Messages';
import { UserContext } from '../../contexts/user-context';
import NotificationContext from '../../contexts/notification-context';

const CreatedPartnerAdmin = () => {
    const { currentUser } = useContext(UserContext);
    const { dispatchData } = useContext(NotificationContext);
    const [dataPartner, setDataPartner] = useState({});
    const [dataPassword, setDataPassword] = useState({});
    const [notificationEmail, setNotificationEmail] = useState(false);
    const [notificationTelegram, setNotificationTelegram] = useState(false);
    const history = useHistory();
    const auth = firebase.auth();
    const validateLenPasww = async () => {
        const { emailUser: lastEditor } = currentUser;
        if (!dataPassword) return dispatchData({ text: ERROR.ERROR_PASSWORD });
        const { password } = dataPassword;
        if (password.length < 6) {
          return dispatchData({ text: ERROR.WRONG_PASSWORD });
        }
        user.createUser(dataPartner, lastEditor, notificationEmail, notificationTelegram)
            .then(responseDta => {
                const { data } = responseDta;
                auth.createUserWithEmailAndPassword(dataPartner.emailUser, password)
                    .then(responseData => {
                        const { additionalUserInfo } = responseData;
                        const { isNewUser } = additionalUserInfo;
                        if (isNewUser === true) {
                            dispatchData({ text: data.data });
                            history.push('/admin');
                            dispatchData({ text: SUCCESSFULL.CREATE_USER_BY_ADMIN });
                            return;
                        }
                        dispatchData({ text: ERROR.USER_EXISTS });
                    })
                    .catch(e => dispatchData({ text: ERROR.GENERAL_ERROR }));
            })
            .catch(error => dispatchData({ text: error, error }));
        return history.push('/admin');
    };
    return (
      <>
        <div className={style.container}>
          <Card className={style.card}>
            <div className={'row'} >
              <div className={style.centerGroup}>
                <p className={style.parrafs}>Nombre</p>
                <TextField required name={'nameUser'} onChange={(e) =>
                                  setDataPartner({
                                      ...dataPartner,
                                      [e.target.name]: e.target.value,
                                  })}
                           type={'text'} className={style.textFieldd} />
              </div>
              <div className={style.centerGroup}>
                <p className={style.parrafs}>Correo</p>
                <TextField required name={'emailUser'} onChange={(e) =>
                                  setDataPartner({
                                      ...dataPartner,
                                      [e.target.name]: e.target.value,
                                  })}
                           type={'email'} className={style.textFieldd} />
              </div>
              <div className={style.centerGroup}>
                <p className={style.parrafs}>Contraseña</p>
                <TextField required name={'password'} onChange={(e) =>
                                  setDataPassword({
                                      ...dataPassword,
                                      [e.target.name]: e.target.value,
                                  })}
                           type={'password'} className={style.textFieldd} />
              </div>
              <div className={style.centerGroup}>
                <p className={style.parrafs}>Notificaciones</p>
                <div className={style.containerCheckbox}>
                  <div>Envio mensajes por correo<Switch
                            onClick={(e) => setNotificationEmail(!notificationEmail)}
                        />
                  </div>
                  <div>Envio mensajes por telegram <Switch
                            onClick={(e) => setNotificationTelegram(!notificationTelegram)}
                        />
                  </div>
                </div>
              </div>
              <div className={'row'}>
                <div className={style.centerGroup}>
                  <button className= {style.centerBoton}
                          onClick={() => validateLenPasww()}>Guardar Cambios</button>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </>
    );
};
export default CreatedPartnerAdmin;
