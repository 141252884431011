import React, { createContext, useState } from 'react';
import { PropTypes } from 'prop-types';

export const LocateContext = createContext();

const LocatedContextProvider = ({ children }) => {
    const [municipality, setMunicipality] = useState([]);
    const [allDataMunDep, setAllDataMunDep] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [regiones, setRegiones] = useState([]);
    return (
      <LocateContext.Provider value = {{
        municipality,
        setMunicipality,
        allDataMunDep,
        setAllDataMunDep,
        departments,
        setDepartments,
        regiones,
        setRegiones,
      }}>
        {children}
      </LocateContext.Provider>
    );
};
LocatedContextProvider.propTypes = {
    children: PropTypes.oneOfType(
        [PropTypes.object, PropTypes.arrayOf(PropTypes.element)]).isRequired,
};
export default LocatedContextProvider;
