import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const ProfileContext = createContext();

const ProfileContextProvider = ({ children }) => {
    const [dataProfile, setDataUserProfile] = useState({});

    return (
      <ProfileContext.Provider value={{ dataProfile, setDataUserProfile }}>
        {children}
      </ProfileContext.Provider>
    );
};

ProfileContextProvider.propTypes = {
    children: PropTypes.oneOfType(
        [PropTypes.object, PropTypes.arrayOf(PropTypes.element)]).isRequired,
};
export default ProfileContextProvider;
