import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@rmwc/button';

const ButtonMdc = ({
                     className,
                     label,
                     danger,
                     dense,
                     outlined,
                     raised,
                     unelevated,
                     disabled,
                     icon,
                     trailingIcon,
                     children,
                     onClick,
                     tag = 'button',
                     href = '',
                     style,
                     target,
                   }) => {
  return (<Button
    className={className}
    label={label}
    danger={danger}
    dense={dense}
    outlined={outlined}
    raised={raised}
    unelevated={unelevated}
    disabled={disabled}
    tag={tag}
    href={href}
    icon={{
      icon,
      strategy: 'className',
      basename: 'mdi',
      prefix: 'mdi-',
    }}
    trailingIcon={{
      icon: trailingIcon,
      strategy: 'className',
      basename: 'mdi',
      prefix: 'mdi-',
    }}
    onClick={onClick}
    style={style}
    target={target}>
    {children}
  </Button>);
};

ButtonMdc.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  danger: PropTypes.bool,
  dense: PropTypes.bool,
  outlined: PropTypes.bool,
  raised: PropTypes.bool,
  unelevated: PropTypes.bool,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  trailingIcon: PropTypes.string,
  target: PropTypes.string,
  onClick: PropTypes.func,
  tag: PropTypes.string,
  href: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.element)]),
  style: PropTypes.oneOfType(
    [PropTypes.object, PropTypes.arrayOf(PropTypes.element)]),
};

export default ButtonMdc;
