/* eslint-disable consistent-return */
import React, { useEffect, useState, useContext } from 'react';
import style from './perfilamientoUsers.module.css';
import { Typography } from '@rmwc/typography';
import { TextField } from '@rmwc/textfield';
import PropTypes from 'prop-types';
import Utils from '../../utils/Utils';
import { Tooltip } from '@rmwc/tooltip';
import { ProfileContext } from '../../contexts/profile-context';

const SelecCuantia = ({ onChange }) => {
  const [dataCuan, setDataCuan] = useState({ minimo: 0, maximo: 0 });
  const { dataProfile } = useContext(ProfileContext);
  const [preventReload, setPreventReload] = useState(false);

  const formaterPeso = new Intl.NumberFormat(
    'es-CO',
    {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 0,
    });

    useEffect(() => {
    if ((dataProfile && preventReload) || !dataProfile) {
      if (dataCuan.maximo === 0 && dataCuan.minimo === 0) {
        return onChange({});
      } else {
        const dataCuantia = { cuantia: dataCuan };
        setPreventReload(false);
        return onChange(dataCuantia);
      }
    } else if (dataProfile && !preventReload) {
      const { cuantia } = dataProfile;
      if (cuantia) {
        const { minimo, maximo } = cuantia;
        setPreventReload(true);
        return setDataCuan({ minimo, maximo });
      }
    }
  }, [dataCuan]);

  const validateDataNumber = (e) => {
    const param = e.target.value;
    const formatParam = Number(param.replace('$', '').split('.')
      .join(''));
    return !/^[0-9]* $/.test(formatParam);
  };
  return (
    <>
      <div className={style.containerCon}>
        <Typography use={'headline6'} style={{ color: '#9747FF' }}>
          Seleccion Cuantia</Typography>
        <Typography use={'body6'} style={{ color: '#ADADAD' }}>
          Selecciona aquí los flitros de búsqueda de oportunidades
          según un rango de valores de cuantía
        </Typography>
        <div className={style.Selectcuantia}>
          <Tooltip content={`Recuerde que: El valor maximo debe ser mayor al minimo y
                      que el valor maximo no puede ser cero`}>
            <TextField label={'valor minimo'} name={'minimo'}
                       onKeyPress={Utils.validateNotLeter}
                       disabled={false}
                       onChange={(e) => {
                        const validateData = validateDataNumber(e);
                        const numberSave = Number((e.target.value).replace('$', '').split('.')
                          .join(''));
                          if (validateData) {
                            setPreventReload(true);
                            setDataCuan({
                              ...dataCuan,
                              minimo: numberSave,
                              maximo: numberSave === 0 ? 0 : numberSave + 1,
                            });
                          };
                        }} type={'string'} value={formaterPeso.format(dataCuan.minimo)} />
          </Tooltip>
          <Tooltip content={'El valor maximo debe ser mayor al valor minimo'}>
            <TextField label={'valor maximo'} onKeyPress={Utils.validateNotLeter}
                       invalid={ dataCuan.minimo > dataCuan.maximo }
                       name={'maximo'} onChange={(e) => {
                          const validateData = validateDataNumber(e);
                          const numberSave = Number((e.target.value).replace('$', '').split('.')
                            .join(''));
                          if (validateData) {
                            setPreventReload(true);
                            setDataCuan({
                              ...dataCuan,
                              [e.target.name]: numberSave,
                            });
                          };
                      }} disabled={!dataCuan.minimo}
                       type={'string'}
                       value={formaterPeso.format(dataCuan.maximo)} />
          </Tooltip>
        </div>
      </div>
    </>
  );
};
SelecCuantia.propTypes = {
    onChange: PropTypes.func.isRequired,
};

export default SelecCuantia;
