import React, { useState, useEffect, useContext } from 'react';
import style from './perfilamientoUsers.module.css';
import { Typography } from '@rmwc/typography';
import { Chip, ChipSet } from '@rmwc/chip';
import MDIcon from '../mdc/MDIcon';
import { Button } from '@rmwc/button';
import keysSelect from '../../api/keys';
import { TextField } from '@rmwc/textfield';
import { Card } from '@rmwc/card';
import { List, ListItem } from '@rmwc/list';
import PropTypes from 'prop-types';
import { ProfileContext } from '../../contexts/profile-context';
import { UserContext } from '../../contexts/user-context';
import NotificationContext from '../../contexts/notification-context';
import { ERROR } from '../../utils/Messages';

const KeyWords = ({ onChange }) => {
    const { currentUser } = useContext(UserContext);
    const { dispatchData } = useContext(NotificationContext);
    const [dataKeysSend, setDataKeysSend] = useState([]);
    const [dataChips, setDataChips] = useState('');
    const [optionsKeys, setOptionsKeys] = useState([]);
    const [filterOptions, setFilterOptions] = useState([]);
    const [preventReload, setPreventReload] = useState(false);
    const { dataProfile } = useContext(ProfileContext);

    useEffect(() => {
    if (dataProfile) {
      if (dataProfile.keyWord) {
        setDataKeysSend(dataProfile.keyWord);
      }
    }
    keysSelect.showKeys()
      .then(responseData => {
        const { data } = responseData;
        setOptionsKeys(data);
      })
      .catch((e) => dispatchData({ text: ERROR.GENERAL_ERROR, error: e }));
  }, []);

  useEffect(() => {
    const filterOptions = optionsKeys.filter((option) =>
      option.includes(dataChips.toLocaleLowerCase()));
    setFilterOptions(filterOptions);
  }, [dataChips]);

  useEffect(() => {
    if (dataKeysSend.length > 0 && preventReload) onChange(dataKeysSend);
    else onChange(undefined);
  }, [dataKeysSend]);

  const showInfoKeys = () => {
    if (!optionsKeys) return <></>;
    return filterOptions.map((responseName, index) => (
      <Card key={`keyword-${index}`}>
        <List twoLine={true}>
          <ListItem onClick={() => {
            setPreventReload(true);
            setDataKeysSend([...dataKeysSend, responseName]);
            setDataChips('');
          }}>{responseName.toLowerCase()}</ListItem>
        </List>
      </Card >));
  };
  const createChips = () => {
    if (dataKeysSend.length <= 0) return <></>;

    const arrayKeys = [...new Set(dataKeysSend)];
    return (
      <div className={style.componenteChips}>
        {
          arrayKeys.map((responseData, index) => (
            <ChipSet key={`${responseData}-${index}`}>
              <Chip
                onRemove={() => {
                  setPreventReload(true);
                  setDataKeysSend(dataKeysSend.filter((option) => option !== responseData));
                }}
                key={index} label={responseData} trailingIcon={<MDIcon icon={'close'} />} />
            </ChipSet>
          ))}
      </div>);
  };

  const validateChip = () => {
    if (optionsKeys.includes(dataChips)) {
      setDataKeysSend([...dataKeysSend, dataChips]);
      return setDataChips('');
    };
    const { emailUser } = currentUser;

    return keysSelect.saveKeys(dataChips.toLowerCase(), emailUser)
      .then(() => {
        setDataKeysSend([...dataKeysSend, dataChips]);
        setOptionsKeys([...optionsKeys, dataChips]);
      })
      .catch(() => dispatchData({ text: ERROR.GENERAL_ERROR }))
      .finally(() => setDataChips(''));
  };

  return (
    <>
      <div className={style.containerCon}>
        <Typography use={'headline6'} style={{ color: '#9747FF' }}>
          Busqueda por palabras clave</Typography>
        <Typography use={'body6'} style={{ color: '#ADADAD' }}>
          Selecciona aqui los filtros de búsqueda de oportunidades según
          palabras clave para la búsqueda
        </Typography>
        <div className={style.containerDataChips}>
          <ChipSet>
            <Chip>
              <TextField onChange={(e) => setDataChips(e.target.value)}
                         value={dataChips}
                         onKeyPress={(e) => {
                          if ((e.keyCode || e.which) === 13) {
                            validateChip();
                            setPreventReload(true);
                          };
                         }}
                         onKeyDown={(e) => {
                           if ((e.keyCode || e.which) === 9) {
                            setDataChips(filterOptions[0]);
                            setPreventReload(true);
                          }
                         }}/>
            </Chip>
          </ChipSet>
          <Button className={style.buttonStyle}
                  onClick={() => validateChip()}> <MDIcon icon={'send'}
                  /> </Button>
        </div>
        {dataChips && showInfoKeys()}
        {dataKeysSend && createChips()}
      </div>
    </>);
};
KeyWords.propTypes = {
  onChange: PropTypes.func.isRequired,
};
export default KeyWords;

