/* eslint-disable no-unused-expressions */
import React, { useState, useContext } from 'react';
import { TextField } from '@rmwc/textfield';
import { Checkbox } from '@rmwc/checkbox';
import style from './register.module.css';
import firebase from '../../firebase';
import DataRegister from '../../api/Register';
import { useHistory } from 'react-router-dom';
import ButtonMdc from '../mdc/button/ButtonMdc';
import NotificationContext from '../../contexts/notification-context';
import { ERROR, SUCCESSFULL } from '../../utils/Messages';
import DataPolicy from './dataPolicy';
import { Button } from '@rmwc/button';

const RegisterForm = () => {
    const history = useHistory();
    const { dispatchData: dispatchNotify } = useContext(NotificationContext);
    const [captureDataUser, setCaptureDataUser] = useState({});
    const [limitRetry, setLimitRetry] = useState(0);
    const [sendEmail, setSendEmails] = useState(false);
    const [sendMsgTelegram, setSendMsgTelegram] = useState(false);
    const [isActivate, setIsActivate] = useState(false);
    const [authData, setAuthData] = useState(false);
    const [openDialogText, setOpenDialogText] = useState(false);
    const auth = firebase.auth();

    const captura = (e) => {
        setCaptureDataUser({ ...captureDataUser, [e.target.name]: e.target.value });
    };

    const validatiosnReg = () => {
        if (captureDataUser.paswwordUser.length < 6) {
          dispatchNotify({ text: ERROR.WRONG_PASSWORD });
          return false;
        }
        if (captureDataUser.paswwordUser !== captureDataUser.confirmPassword) {
          dispatchNotify({ text: ERROR.NOT_MATCH_PSWD });
          return false;
        }
        return true;
    };

    const enviar = async () => {
        const response = validatiosnReg();
        if (response === true) {
            if (!authData) {
              dispatchNotify({ text: ERROR.NO_DATA_POLICY });
              return;
            }
            const {
                confirmPassword,
                paswwordUser,
                emailUser,
                nameEnterprise,
                nameUser,
                ...all
            } = captureDataUser;
            auth.createUserWithEmailAndPassword(emailUser, paswwordUser)
              .then(async (responsedata) => {
                const { additionalUserInfo } = responsedata;
                const { isNewUser } = additionalUserInfo;
                if (isNewUser === true) {
                  DataRegister.postRegis({
                    ...all,
                    sendEmail,
                    notificationTelegram: sendMsgTelegram,
                    notificationEmail: sendEmail,
                    emailUser,
                    nameEnterprise,
                    nameUser,
                  }).then(async (responseDataBack) => {
                    dispatchNotify({ text: SUCCESSFULL.CREATE_USER });
                    window.location.reload();
                    history.push('/');
                    window.location.reload();
                  })
                    .catch(e => dispatchNotify({ text: ERROR.GENERAL_ERROR, error: e }));
                }
                dispatchNotify({ text: ERROR.USER_EXISTS });
            });
        }
    };

    const checkUserExists = async (email) => {
      try {
        const response = await DataRegister.getUserByEmail(email);
        return response; 
      } catch (error) {
        if (error.message === `Usuario ${email} no existe`) {
          return null;
        } else {
          throw error;
        }
      }
    };

    const register = () => {
      if (limitRetry <= 3) {
        if (!authData) {
          dispatchNotify({ text: ERROR.NO_DATA_POLICY });
          return;
        }
        setLimitRetry(limitRetry + 1);
        const provider = new firebase.auth.GoogleAuthProvider();
        firebase.auth().signInWithPopup(provider)
          .then(async (result) => {
            const userInfo = result.additionalUserInfo.profile;
            const { isNewUser } = result.additionalUserInfo;
            const { email, hd, name, picture, ...all } = userInfo;
            checkUserExists(email)
              .then((userExist) => {
                if (!isNewUser && userExist !== null) {
                  dispatchNotify({ text: ERROR.USER_EXISTS });
                  history.push('/');
                  return;
                }
                if (hd !== 'gmail.com') {
                  DataRegister.postRegis({
                    ...all,
                    picture,
                    emailUser: email,
                    nameEnterprise: hd,
                    nameUser: name,
                    notificationTelegram: sendMsgTelegram,
                    notificationEmail: sendEmail,
                  }).then((responseData) => {
                    const { data } = responseData;
                    const { mensaje = '', enterpriseExist } = data;
                    if (enterpriseExist) return dispatchNotify({ text: mensaje });
                    dispatchNotify({ text: SUCCESSFULL.CREATE_USER });
                    history.push('/');
                    return window.location.reload();
                  });
                  return;
                }
                DataRegister.postRegis({
                  emailUser: email,
                  nameUser: name,
                  ...all,
                  notificationTelegram: sendMsgTelegram,
                  notificationEmail: sendEmail,
                }).then((responseData) => {
                  dispatchNotify({ text: SUCCESSFULL.CREATE_USER });
                  history.push('/');
                  return window.location.reload();
                });
              })
              .catch((error) => {
                dispatchNotify({ text: ERROR.GENERAL_ERROR, error });
              });
          })
          .catch((error) => {
            dispatchNotify({ text: ERROR.GENERAL_ERROR, error });
          });
      }
    };

    return (
      <div className={style.containerComponente}>
        <div className={style.containerButtonsSelect}>
          <button className={style.buttonSelect}
                  onClick={() => setIsActivate(false)} >Usuarios</button>
          <button className={style.buttonSelect}
                  onClick={() => setIsActivate(true)} >Empresas</button>
        </div>
        <div className={style.containerRegisterInputs}>
          <div>
            <TextField onChange={(e) => setCaptureDataUser(
                        { ...captureDataUser, [e.target.name]: e.target.value })
                       } name={'nameUser'} required
                       type={'text'} className={style.text} label={'Nombre Completo'} />
          </div>
          <div>
            <TextField onChange={(e) => {
                         /^\d+$/.test(e.target.value)
                         ? {
                           ...captureDataUser,
                           [e.target.name]: e.target.value,
                         }
                         : dispatchNotify({ text: 'El valor ingresado no es un número' });
                       }} name={'cedUser'} required
                       type={'number'} className={style.text} label={'Cedula'}/>
          </div>
          <div>
            <TextField onChange={(e) => {
                         /^\d+$/.test(e.target.value)
                         ? {
                           ...captureDataUser,
                           [e.target.name]: e.target.value,
                         }
                         : dispatchNotify({ text: 'El valor ingresado no es un número' });
                       }} name={'telUser'} required
                       type={'number'} className={style.text} label={'Celular'}/>
          </div>
          <div>
            <TextField onChange={(e) => captura(e)} name={'emailUser'} required
                       type={'email'} className={style.text} label={'Correo Electronico'} />
          </div>
          <div>
            <TextField onChange={(e) => captura(e)} name={'paswwordUser'} required
                       type={'password'} className={style.text} label={'Contraseña'} />
          </div>
          <div>
            <TextField onChange={(e) => captura(e)} name={'confirmPassword'} required
                       type={'password'} className={style.text} label={'Confirmar Contraseña'} />
          </div>
          {isActivate
              && <div>
                <TextField onChange={(e) => captura(e)} name={'nameEnterprise'}
                           label={'Nombre Empresa'}
                           type={'text'} className={style.text} required={isActivate} />
              </div>
            }
          <Checkbox label={'Autoriza el envio de correos' }
                    onClick={(e) => setSendEmails(!sendEmail)}
                    checked={sendEmail} />
          <Checkbox label={'Autoriza el envio de mensajes por telegram'}
                    onClick={(e) => setSendMsgTelegram(!sendMsgTelegram)}
                    checked={sendMsgTelegram} />
          <Checkbox checked={authData || false } label={'Politica de tratamiento de datos'}
                    onClick={() => setOpenDialogText(!openDialogText)} />
        </div>
        <div>
          <Button style={{
                           width: '100%',
                           height: '30px',
                           background: '#5F1B94',
                           color: 'white',
                           borderRadius: '50px',
                          }}
                  onClick={() => enviar()} label={'Registrarse'} />
          <p>
            ó registrarse con:
          </p>
          <ButtonMdc label={'Registrar con Google'} danger raised
                     icon={'account-circle'} className={'button-full'} onClick={() => register()} />
          {
            openDialogText
              && <DataPolicy openDialog={openDialogText} onChange={(evt) => setAuthData(evt)}/>
          }
        </div>
      </div>
    );
};
export default RegisterForm;
