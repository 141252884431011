import { api } from './index';
export default class DataProcess {
    constructor() {
        this.datosBack = null;
        this.answwer = null;
    }

    static async getDataInfo(idProcess) {
        this.datosBack = await api.get(`/api/InfOport?docId=${idProcess}`);
        this.answwer = await this.datosBack.data;
        return this.answwer;
    }
}
