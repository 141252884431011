import React, { useContext, useMemo, useState } from 'react';
import { Snackbar, SnackbarAction } from '@rmwc/snackbar';
import NotificationContext from '../../contexts/notification-context';

const Notification = () => {
  const { data, dispatchData } = useContext(NotificationContext);
  const [notificationTimeout, setNotificationTimeout] = useState(null);

  useMemo(() => {
    if (data.show) {
      clearTimeout(notificationTimeout);
      setNotificationTimeout(setTimeout(() => {
        data.text = '';
        dispatchData();
      }, 3000));
    }
    // eslint-disable-next-line
  }, [data]);

  return (
    <Snackbar
      open={data.show}
      message={data.text}
      dismissesOnAction
      onClose={(e) => dispatchData()}
      action={
        <SnackbarAction
          label={'Hide'}
        />
      }
    />
  );
};

export default Notification;
