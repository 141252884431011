import { api, escalateError, getResponseData } from './index';
export default class selecLocat {
    static async getLoc() {
        return api.get('/api/seleLocat')
            .then(getResponseData)
            .catch(escalateError);
    }

    static async seleDepartment({ department, region }) {
        return api.post('/api/seleLocat/department', {
            department,
            region,
        });
    }

    static async seleRegion({ region }) {
        return api.post('/api/seleLocat/region', {
            region,
        });
    }
}
