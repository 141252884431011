import { api, escalateError, getResponseData } from './index';

export default class userPartners {
    static async getUserPartners(body) {
        return api.post('/api/showUsers/showCollaborators', body)
            .then(getResponseData)
            .catch(escalateError);
    }

    static async editCollaborator(emailUser, nameEnterprise, body) {
        const paramsOne = `emailUser=${emailUser}&nameEnterprise=${nameEnterprise}`;
        return api.post(`api/regi/updateCollaborator?${paramsOne}`, body)
            .then(getResponseData)
            .catch(escalateError);
    }

    static async deleteCollaborator(emailUser, nameEnterprise) {
        const paramsOne = `emailUser=${emailUser}&nameEnterprise=${nameEnterprise}`;
        return api.post(`api/regi/deleteCollaborator?${paramsOne}`)
            .then(getResponseData)
            .catch(escalateError);
    }

    static async createUserParners({
            dataPartner,
            nameEnterprise,
            lastEditor,
            permissions,
            notificationEmail,
            notificationTelegram,
    }) {
        return api.post('/api/showUsers', {
            dataPartner,
            nameEnterprise,
            lastEditor,
            permissions,
            notificationEmail,
            notificationTelegram,
        });
    }
}
