import React, { useEffect, useContext, useState } from 'react';
import DataApi from '../../api/Result';
import { UserContext } from '../../contexts/user-context';
import NotificationContext from '../../contexts/notification-context';
import { ERROR } from '../../utils/Messages';
import { Select } from '@rmwc/select';

const Dashboard = () => {
  const { currentUser } = useContext(UserContext);
  const { dispatchData } = useContext(NotificationContext);
  const [namesProfile, setNamesProfile] = useState([]);
  const [profile, setProfile] = useState('');
  const [encodeUrl, setEncodeUrl] = useState('');
  const [saveIdProfile, setSaveIdProfile] = useState({});
  const linksDashboard = !process.env.REACT_APP_ENV || process.env.REACT_APP_ENV === 'dev'
    ? process.env.REACT_APP_LINK_DASHBOARD_PROFILEUSER_DEV
    : process.env.REACT_APP_LINK_DASHBOARD_PROFILEUSER_PROD;
  const varDashboard = !process.env.REACT_APP_ENV || process.env.REACT_APP_ENV === 'dev'
    ? process.env.REACT_APP_VAR_DASHBOARD_DEV
    : process.env.REACT_APP_VAR_DASHBOARD_PROD;
  useEffect(() => {
    const { nameEnterprise = '', userId, rol } = currentUser;
    DataApi.getPerfilUser(userId, nameEnterprise, rol)
      .then(({ data }) => {
        const idsProfile = {};
          const namesProfiles = data.map(({ nameProfile, profileId }) => {
            idsProfile[nameProfile] = profileId;
            return nameProfile;
          });
          setSaveIdProfile(idsProfile);
        setNamesProfile(namesProfiles);
      })
      .catch(e => dispatchData({ text: ERROR.SHOW_PREFERENCE, error: e }));
  }, []);

  useEffect(() => {
        const encodingUrl = {};
        encodingUrl[varDashboard] = JSON.stringify(profile);
        const encodeURL = encodeURIComponent(JSON.stringify(encodingUrl));
        setEncodeUrl(encodeURL);
  }, [profile]);

  return (
    <>
      <Select label={'Perfiles'} options={['-- Seleccionar --', ...namesProfile]}
              onChange={(evt) => setProfile(evt.target.index === 1
              ? ''
              : saveIdProfile[evt.target.value])}/>
      {encodeUrl
              ? <iframe style={{ width: '80vw', height: '80vh', border: 0 }}
                        src={`${linksDashboard}?params=${encodeUrl}`}
                        frameBorder={'0'} allowFullScreen></iframe>
              : <iframe style={{ width: '80vw', height: '80vh', border: 0 }}
                        src={linksDashboard}
                        frameBorder={'0'} allowFullScreen></iframe>
            }
    </>
  );
};
export default Dashboard;
