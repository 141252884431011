import React, { useState, useEffect, useContext } from 'react';
import selecMod from '../../api/selecMod';
import { Typography } from '@rmwc/typography';
import Loader from '../loader/Loader';
import PropTypes from 'prop-types';
import style from './perfilamientoUsers.module.css';
import { Checkbox } from '@rmwc/checkbox';
import { ProfileContext } from '../../contexts/profile-context';
import NotificationContext from '../../contexts/notification-context';
import { ERROR } from '../../utils/Messages';

const SeleccionModalidad = ({ onChange }) => {
  const { dispatchData } = useContext(NotificationContext);
    const [dataMod, setDataMod] = useState([]);
    const [sendDataMod, setSendDataMod] = useState([]);
    const { dataProfile } = useContext(ProfileContext);

    useEffect(() => {
        dataModAnswe();
        if (dataProfile) {
          const { Modalidad } = dataProfile;
          if (Modalidad) setSendDataMod([...Modalidad]);
        }
    }, []);
    const dataModAnswe = async () => {
        selecMod.getModality().then(responsedata => {
            const { data } = responsedata || {};
            const { Modalidades } = data;
            setDataMod([...Modalidades]);
        })
        .catch((e) => dispatchData({ text: ERROR.GENERAL_ERROR, error: e }));
    };

    const sendData = () => {
        if (sendDataMod.length > 0) {
            onChange(sendDataMod);
        }
    };

    useEffect(() => sendData(), [sendDataMod]);

    return (
      <>
        <div className={style.containerCon}>
          <Typography use={'headline6'} style={{ color: '#9747FF' }}>
            Seleccion Modalidad</Typography>
          <Typography use={'body6'} style={{ color: '#ADADAD' }}>
            Selecciona aqui los filtros de búsqueda de oportunidade según su modalidad
          </Typography>
          {dataMod
            ? dataMod.map((responseData, index) => (
              <div className={style.containerModality} key={`${index}-${responseData}`}>
                <Checkbox key={index} className={style.checkBoxStyleModality}
                          label={responseData}
                          checked={sendDataMod.includes(responseData) || false}
                          onClick={(e) => {
                    let auxData = sendDataMod;
                    auxData = e.currentTarget.checked
                      ? auxData.concat(responseData)
                      : auxData.filter((modality) => modality !== responseData);
                    setSendDataMod(auxData);
                  }}
                />
                <Checkbox indeterminate disabled label={'Privado'}
                          className={style.checkBoxStyleModality}/>
              </div>
            ))
            : <Loader />
          }
        </div>
      </>
    );
};

SeleccionModalidad.propTypes = {
    onChange: PropTypes.func.isRequired,
};

export default SeleccionModalidad;
