import React, { useState, useContext } from 'react';
import MDIcon from '../mdc/MDIcon';
import { TextField } from '@rmwc/textfield';
import ChangePasswords from '../../api/changePassword';
import firebase from '../../firebase';
import style from './ChangePassword.module.css';
import NotificationContext from '../../contexts/notification-context';
import { ERROR, SUCCESSFULL } from '../../utils/Messages';
import { Button } from '@rmwc/button';

const ChangePassword = () => {
  const auth = firebase.auth();
  const [email, setEmail] = useState('');
  const { dispatchData } = useContext(NotificationContext);
  const handleEmailChange = (e) => {
    setEmail(e.target.value.toLowerCase());
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const dataResponse = await ChangePasswords.getUserByEmailPassword(email);
      const { emailUser } = dataResponse.data;
      await auth.sendPasswordResetEmail(emailUser).then(() => {
        dispatchData({ text: SUCCESSFULL.PASSWORD_EMAIL });
      });
    } catch (e) {
      e.status === 401
        ? dispatchData({ text: ERROR.USER_DELETE })
        : dispatchData({ text: ERROR.EMAIL_NOT_REGISTER });
    }
  };

  return (
    <div>
      <h1 className={style.title}>Recupera tu cuenta</h1>
      <p>Ingresa tu correo electrónico para buscar tu cuenta</p>
      <form onSubmit={handleFormSubmit}>
        <div className={style.component}>
          <TextField label={'Correo electrónico'}
                     icon={<MDIcon icon={'account'} />}
                     style={{ background: '#FFFFFF' }}
                     value={email}
                     onChange={handleEmailChange} />
          <Button label={'Enviar'}
                  className={style.buttonSaveSend}
                  />
        </div>
      </form>

    </div>
  );
};
export default ChangePassword;
