/* eslint-disable max-lines */
/* eslint-disable prefer-destructuring */
/* eslint-disable max-lines */
/* eslint-disable consistent-return */
/* eslint-disable max-len */
/* eslint-disable max-lines */
import React, {
  useState,
  useContext,
  useEffect
} from 'react';
import { Button } from '@rmwc/button';
import style from './perfilamientoUsers.module.css';
import dataUnspsc from './../../api/PerfilUser';
import { Typography } from '@rmwc/typography';
import { TextField } from '@rmwc/textfield';
import PropTypes from 'prop-types';
import { Tooltip } from '@rmwc/tooltip';
import { ProfileContext } from '../../contexts/profile-context';
import CodesUN from './CodesUN';
import { useHistory, useParams } from 'react-router-dom';
import MDIcon from '../mdc/MDIcon';
import { Chip, ChipSet } from '@rmwc/chip';
import { List, SimpleListItem } from '@rmwc/list';
import Loader from '../loader/Loader';

const PerfilamientoUsers = ({ onChange }) => {
  const [valueSearch, setValueSearch] = useState('');
  const [filterData, setFilterData] = useState([]);
  const { dataProfile } = useContext(ProfileContext);
  const [blockSelect, setBlockSelect] = useState(false);
  const history = useHistory();
  const { profileId } = useParams();
  const [dataCodes, setDataCodes] = useState({});
  const [showChips, setShowChips] = useState(false);
  const [chipsData, setChipsData] = useState([]);
  const [showListKeys, setShowListKeys] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [newDataSendComponent, setNewDataSendComponent] = useState({});
  useEffect(() => validateTypeOfData(), [chipsData]);

  useEffect(() => {
    profileId
    && Object.keys(dataProfile).length > 0
      ? setBlockSelect(true)
      : history.push('/showComponents');
  }, []);

  const validateTypeOfSearch = () => {
    if (valueSearch.length > 1) {
      const character = /^\d+$/.test(valueSearch);
      const dataSearch = !character
        ? valueSearch.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
        : parseInt(valueSearch);

      const keyObject = character
        ? JSON.stringify(dataSearch).length === 4
          ? ['familyId', 1]
          : JSON.stringify(dataSearch).length === 2
          ? ['segmentId', 1]
          : JSON.stringify(dataSearch).length === 6
          ? ['classId', 1]
          : ['productsId', 1]
        : ['keySearch', 10];
      const objectData = {
        [keyObject[0]]: [dataSearch],
      };
      character && setShowChips(true);
      dataUnspsc.getInfoProducts(JSON.stringify(objectData), keyObject[1]).then(({ data }) => {
        setFilterData(data);
        const [dataProc] = data;
        if (profileId && Object.keys(dataProfile).length > 0) {
          const keysDataCodes
            = JSON.stringify(dataSearch).length === 4
              ? ['Código Familia', 'Nombre Familia']
              : JSON.stringify(dataSearch).length === 2
              ? ['Código Segmento', 'Nombre Segmento']
              : JSON.stringify(dataSearch).length === 6
              ? ['Código Clase', 'Nombre Clase']
              : ['Código Producto', 'Nombre Producto'];
          setValueSearch('');
          return setNewDataSendComponent({
            [dataProc[keysDataCodes[0]]]: dataProc[keysDataCodes[1]],
          });
        }
        const lengthString = JSON.stringify(dataSearch).length;
        character ? (dataProc.lengSearch = lengthString) : setShowListKeys(true);
        character && setChipsData([...chipsData, dataProc]);
        !character && setDataList(data);
        return setValueSearch('');
      });
    }
  };

  const validateNewData = (objectNewData) => {
    if (chipsData.length > 0) {
      const firstLeng = chipsData.length;
      let auxData = chipsData;
      const lengthData = auxData
      .filter((data) => data['Código Producto'] !== objectNewData['Código Producto']);
      auxData = firstLeng === lengthData.length
      ? auxData.concat([objectNewData])
      : lengthData;
      setChipsData(auxData);
      return setShowChips(true);
    }
    const auxData = chipsData || [];
    auxData.push(objectNewData);
    setChipsData(auxData);
    return setShowChips(true);
  };

  const createdList = () => {
    return dataList.map((all, index) => {
      return (
        <>
          <List key={index}>
            <SimpleListItem
            className={style.listStyle}
            onClick={() => validateNewData(all)}
            label={`${all['Nombre Segmento']} - ${all['Nombre Familia']}`}
            secondaryText={`${all['Nombre Clase']} - ${all['Nombre Producto']}`}
            title={`${all['Nombre Clase']} - ${all['Nombre Producto']} ${all['Nombre Segmento']} - ${all['Nombre Familia']}`}
          />
          </List>
        </>
      );
    });
  };

  const getTypeFromKey = (key) => {
    const keyLength = key.length;
    if (keyLength === 4) return 'Familia';
    if (keyLength === 2) return 'Segmento';
    if (keyLength === 6) return 'Clase';
    return 'Producto';
  };

  const validateTypeOfData = () => {
    const objectDataClasificationsCodes = dataCodes || {};
    if (chipsData.length === 0 && dataProfile.length === 1) {
      onChange(chipsData);
      setDataCodes({});
      return;
    }
    if (chipsData.length > 0) {
      if (Object.keys(chipsData[0]).length === 1) {
        if (chipsData === 'nada') {
          setDataCodes({});
          onChange('nada');
          return;
        } else {
          const newFormattedData = {};
          chipsData.forEach((item) => {
            const key = Object.keys(item)[0];
            const type = getTypeFromKey(key);
            if (!newFormattedData[type]) {
              newFormattedData[type] = [];
            }
            newFormattedData[type].push({
              [key]: item[key],
            });
          });
          onChange(newFormattedData);
          setDataCodes({});
          return;
        }
      }
      chipsData.map(({ lengSearch, ...all }, index) => {
        const getIdCodes = lengSearch === 4
          ? ['Código Familia', 'Nombre Familia']
          : lengSearch === 2
            ? ['Código Segmento', 'Nombre Segmento']
            : lengSearch === 6
              ? ['Código Clase', 'Nombre Clase']
              : ['Código Producto', 'Nombre Producto'];
        const keyNameCodes = lengSearch === 4
          ? 'Familia'
          : lengSearch === 2
            ? 'Segmento'
            : lengSearch === 6
              ? 'Clase'
              : 'Producto';
        if (!objectDataClasificationsCodes[keyNameCodes]) {
          objectDataClasificationsCodes[keyNameCodes] = [];
        }
        objectDataClasificationsCodes[keyNameCodes].push({
          [all[getIdCodes[0]]]: all[getIdCodes[1]],
        });
      });
      Object.keys(objectDataClasificationsCodes).map((keyClasification) => {
        const newData = new Set(objectDataClasificationsCodes[keyClasification]);
        objectDataClasificationsCodes[keyClasification] = [...newData];
      });
      setDataCodes(objectDataClasificationsCodes);
      Object.keys(objectDataClasificationsCodes).length > 0
      ? onChange(objectDataClasificationsCodes)
      : onChange(undefined);
    }
  };

  const createdChips = () => {
    if (filterData.length > 0) {
      if (chipsData !== 'nada') {
        return chipsData.map(({ lengSearch, ...all }, index) => {
          const getIdCodes
            = lengSearch === 4
              ? ['Código Familia', 'Nombre Familia']
              : lengSearch === 2
              ? ['Código Segmento', 'Nombre Segmento']
              : lengSearch === 6
              ? ['Código Clase', 'Nombre Clase']
              : ['Código Producto', 'Nombre Producto'];
          if (all[getIdCodes[0]] !== undefined && all[getIdCodes[1]] !== undefined) {
            const chipKey = `${all[getIdCodes[0]]}-${all[getIdCodes[1]]}`;
            return (
              <ChipSet key={chipKey}>
                <Chip
                  label={`${all[getIdCodes[0]]} - ${all[getIdCodes[1]]}`}
                  title={`${all['Nombre Segmento']} - ${all['Nombre Familia']}`}
                  trailingIcon={<MDIcon icon={'close'} />}
                  onRemove={() => handleChipRemove2(all[getIdCodes[0]])}
                />
              </ChipSet>
            );
          }
        });
      } else {
        setDataCodes({});
        setChipsData([]);
      }
    }
    return <Loader />;
  };

  const handleChipRemove = (codId) => {
    if (!codId || codId.length === 0) {
      setChipsData('nada');
    } else {
      setChipsData(codId);
    }
  };

  const handleChipRemove2 = (codId) => {
    if (!codId || codId.length === 0 || chipsData.length === 0) {
      setChipsData([]);
    } else {
      setChipsData((prevChipsData) => {
        const updatedChipsData = prevChipsData.filter(
          (chip) => chip['Código Producto'] !== codId && chip['Código Familia'] !== codId
          && chip['Código Segmento'] !== codId && chip['Código Clase'] !== codId
        );
        const objectDataClasificationsCodes = {};
        updatedChipsData.forEach(({ lengSearch, ...all }) => {
          const getIdCodes
            = lengSearch === 4
              ? ['Código Familia', 'Nombre Familia']
              : lengSearch === 2
              ? ['Código Segmento', 'Nombre Segmento']
              : lengSearch === 6
              ? ['Código Clase', 'Nombre Clase']
              : ['Código Producto', 'Nombre Producto'];
          const keyNameCodes
            = lengSearch === 4
              ? 'Familia'
              : lengSearch === 2
              ? 'Segmento'
              : lengSearch === 6
              ? 'Clase'
              : 'Producto';

          if (!objectDataClasificationsCodes[keyNameCodes]) {
            objectDataClasificationsCodes[keyNameCodes] = [];
          }

          const codeExists = objectDataClasificationsCodes[keyNameCodes].some(
            (code) => code[getIdCodes[0]] === all[getIdCodes[0]]
          );

          if (!codeExists) {
            objectDataClasificationsCodes[keyNameCodes].push({
              [all[getIdCodes[0]]]: all[getIdCodes[1]],
            });
          }
        });

        Object.keys(objectDataClasificationsCodes).forEach((keyClasification) => {
          const newData = new Set(objectDataClasificationsCodes[keyClasification]);
          objectDataClasificationsCodes[keyClasification] = [...newData];
        });
        Object.keys(objectDataClasificationsCodes).length > 0
          ? setDataCodes(objectDataClasificationsCodes)
          : setDataCodes({});

        return updatedChipsData;
      });
    }
  };

  return (
    <>
      <div className={style.containerCon}>
        <div
          className={style.containerTitles}
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1.5em',
          }}
        >
          <Typography use={'headline6'} style={{ color: '#9747FF' }}>
            Seleccion por clasificacion de bienes y servicios
          </Typography>
          <Typography use={'body6'} style={{ color: '#ADADAD' }}>
            Selecciona aquí los filtros de búsqueda de oportunidades según la clasificación de bienes y servicios del
            catálogo de la ONU
            <div>
              <Tooltip content={'Para mayor informacion del catalogo de la ONU, dar click'} align={'right'}>
                <a
                  href={
                    'https://www.colombiacompra.gov.co/sites/cce_public/files/cce_clasificador/manualclasificador.pdf'
                  }
                  target={'_blank'}
                  rel={'noreferrer'}
                >
                  Click Aqui
                </a>
              </Tooltip>
            </div>
          </Typography>
        </div>
        <div className={style.containerSelct}>
          <TextField
            label={'Buscar...'}
            value={valueSearch}
            onChange={(e) => {
              setValueSearch(e.target.value);
            }}
            onKeyPress={(e) => {
              if ((e.keyCode || e.which) === 13) validateTypeOfSearch();
            }}
          />
          <Button className={style.buttonStyle} onClick={() => validateTypeOfSearch()} label={<MDIcon icon={'arrow-right-circle'} />} />
        </div>
        {showChips && createdChips()}
        {showListKeys && (
          <div className={style.containerSelct}>
            <div className={style.containerRowsLoc}>
              {showListKeys && <div className={style.containerSecondLevel}>{createdList()}</div>}
            </div>
          </div>
        )}
        {blockSelect && <CodesUN newSearch={newDataSendComponent} onChipRemove={handleChipRemove} />}
      </div>
    </>
  );
};

PerfilamientoUsers.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default PerfilamientoUsers;
