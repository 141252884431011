/* eslint-disable max-lines */
import React,
  {
    useMemo,
    useState,
    useContext
} from 'react';
import { useHistory } from 'react-router-dom';
import { TextField } from '@rmwc/textfield';
import ButtonMdc from '../mdc/button/ButtonMdc';
import Recaptcha from 'react-recaptcha';
import Utils from '../../utils/Utils';
import './login.css';
import style from './styleLoginReg.module.css';
import MDIcon from '../mdc/MDIcon';
import Register from '../register/Register';
import ChangePassword from '../changePassword/ChangePassword';
import logEnterprise from '../../imgs/Group 3.png';
import { UserContext } from '../../contexts/user-context';
import NotificationContext from '../../contexts/notification-context';
import { ERROR, INFO } from '../../utils/Messages';
import firstImgsCarrusel from '../../imgs/Property 1=Default.png';
import facebok from '../../imgs/socialMediaFacebook.png';
import insta from '../../imgs/socialMediaInstapng.png';
import twitter from '../../imgs/socialMediaTwitterpng.png';
import linkedInd from '../../imgs/socialMediaLinkedIn.png';
import { Dialog, DialogActions, DialogButton, DialogContent } from '@rmwc/dialog';
import secondImgCarrusel from '../../imgs/Property 1=Variant2.png';
import thirdImgCarrusel from '../../imgs/Property 1=Variant3.png';
import fourtImgCarrusel from '../../imgs/Property 1=Variant4.png';
import fiveImgCarrusel from '../../imgs/Property 1=Variant5.png';
import sixImgCarrusel from '../../imgs/Property 1=Variant6.png';

const LoginForm = () => {
  let recaptchaInstance = '';
  const { signIn, singInWithGoogle } = useContext(UserContext);
  const { dispatchData: dispatchNotify } = useContext(NotificationContext);
  const [disabled, setDisabled] = useState(false);
  const [limitRetry, setLimitRetry] = useState(0);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [openPopUp, setOpenPopUp] = useState(false);
  const history = useHistory();
  const [activate, setActivate] = useState(true);
  const [positionImg, setPositionImg] = useState(firstImgsCarrusel);
  const arrayImgs = [
    firstImgsCarrusel,
    secondImgCarrusel,
    thirdImgCarrusel,
    fourtImgCarrusel,
    fiveImgCarrusel,
    sixImgCarrusel,
  ];
  useMemo(() => {
    setEmailError('');
  }, [email]);

  useMemo(() => {
    setPasswordError('');
  }, [password]);

  setTimeout(() => {
    const indexElemenstArray = arrayImgs.indexOf(positionImg);
    indexElemenstArray + 1 === arrayImgs.length
    ? setPositionImg(arrayImgs[0])
    : setPositionImg(arrayImgs[indexElemenstArray + 1]);
  }, [10000]);

  const validateFields = () => {
    if (email.length <= 0) {
      setEmailError(INFO.REQUIRED_EMAIL);
      return false;
    }
    if (password.length <= 0) {
      setPasswordError(INFO.REQUIRED_PSWD);
      return false;
    }
    if (!Utils.validateEmail(email)) {
      setEmailError(ERROR.INVALID_EMAIL);
      return false;
    }
    return true;
  };

  const login = async () => {
    if (validateFields()) {
      if (limitRetry <= 3) {
        setLimitRetry(limitRetry + 1);
        Promise.all([signIn(email, password)])
          .then(([response]) => {
            const { message, href } = response;
            dispatchNotify({ text: message });
            window.location.reload();
            history.push(href);
          })
          .catch((e) => {
            dispatchNotify({ text: e.message });
            setDisabled(false);
          });
      }
    }
  };

  const loginWithGoogle = async () => {
    if (limitRetry <= 3) {
      setLimitRetry(limitRetry + 1);
      Promise.all([singInWithGoogle()])
        .then(([response]) => {
          const { message, href } = response;
          dispatchNotify({ text: message });
          window.location.reload();
          history.push(href);
        })
        .catch((error) => dispatchNotify({ text: error.message }));
    }
  };

  const resetRecaptcha = () => {
    recaptchaInstance.reset();
  };

  const captchaLoaded = () => {
    setLimitRetry(0);
    resetRecaptcha();
  };

  const recaptcha = (props) => {
    if (props) {
      recaptchaInstance = props;
    }
  };

  return (
    <div className={style.containerAlModule}>
      <div>
        <div className={style.firstPart}>
          <div className={style.containerStyles}/>
          <div className={style.text__containerStyles}>
            <p className={style.text_containerStyles}>Bienvenido</p>
            <p className={style.text_containerStyles2}>de nuevo</p>
          </div>
          <img src={positionImg} alt={'img1'} className={style.img}/>
        </div>
      </div>
      <div className={style.secondPart}>
        <div className={style.containerImgLog}>
          <img src={logEnterprise} alt={'logoEnterprise'} className={style.logoApp} />
        </div>
        <div className={style.containerDataSelectRegLog}>
          <div className={style.containerSelect}>
            <div className={activate ? style.selectBar : style.checkedBar }
                 onClick={() =>
                 setActivate(!activate)}
                />
          </div>
          <div className={style.containerTitles}>
            <div className={activate ? style.textChecked : style.textUnchecked}
                 onClick={() => setActivate(!activate)} >Iniciar Sesion</div>
            <div className={activate ? style.textUnchecked : style.textChecked}
                 onClick={() => setActivate(!activate)} >Registrarse</div>
          </div>
          <div>
            {activate
            ? <div>
              <div className={style.containertextfieldLogin}>
                <TextField label={'Usuario'}
                           icon={<MDIcon icon={'account'} />}
                           value={email}
                           onChange={(e) => setEmail(e.target.value)}
                           invalid={emailError.length > 0}
                           className={style.textLoginInput}
                           helpText={{ validationMsg: true, children: emailError }} />
                <TextField type={'password'}
                           label={'Password'}
                           className={style.textLoginInput}
                           value={password}
                           icon={<MDIcon icon={'lock'} />}
                           onChange={(e) => setPassword(e.target.value)}
                           invalid={passwordError.length > 0}
                           helpText={{ validationMsg: true, children: passwordError }} />
                <div className={style.containerLogin}>
                  <div className={`${(limitRetry) >= 3 ? 'show' : 'hide'} captcha`}>
                    <Recaptcha ref={(e) => recaptcha(e)}
                               render={'explicit'} verifyCallback={captchaLoaded}
                               sitekey={'6Le7X8gfAAAAAKv7LstBEltkgLjg5iT_mdJpeahf'} />
                  </div>
                  <button className={style.loginButton} onClick={() => login()}>
                    Ingresar
                  </button>
                  <div className={style.aligTextButton}>
                    <button className={style.buttonPassword}
                            onClick={() => setOpenPopUp(true)}>
                      ¿Olvidaste tu contraseña?
                    </button>
                  </div>
                  <p style={{
                    color: '#535353',
                    display: 'flex',
                    justifyContent: 'center',
                    fontSize: '0.8em',
                    }}>ó ingresa con:</p>
                  <ButtonMdc label={'Ingreso Google'}
                             danger raised icon={'account-circle'}
                             onClick={() => loginWithGoogle()}
                             className={'button-full'}
                             disabled={disabled} />
                </div>
              </div>
            </div>
            : <div>
              <Register />
            </div>
            }
          </div>
        </div>
        <div className={style.containerotherInfo}>
          <div className={style.iconsSocialMedia}>
            <img src={facebok} alt={'facebook'} />
            <img src={insta} alt={'instagram'} />
            <img src={linkedInd} alt={'linkedIn'} />
            <img src={twitter} alt={'Twitter'} />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <p className={style.textCopyRigth}>Copyright © 2023 BDG. All rights reserved</p>
          </div>
        </div>
        <Dialog
        open={openPopUp}
        onClose={() => setOpenPopUp(false)}
        >
          <DialogContent><ChangePassword /></DialogContent>
          <DialogActions>
            <DialogButton action={'accept'} className={style.buttonSaveInfo}>Aceptar</DialogButton>
          </DialogActions>
        </Dialog>
      </div>
    </div>);
};

export default LoginForm;
