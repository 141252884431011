import React from 'react';
import { SimpleDialog } from '@rmwc/dialog';
import PropTypes from 'prop-types';

class VentanaDialogo extends React.Component {
  render() {
    return (
      <>
        <SimpleDialog
                title={this.props.title}
                body={this.props.body}
                open={this.props.open}
                acceptLabel={'enviar'}
                name={this.props.name}
                onClose={this.props.onClose}
                onChange={this.props.onChange}
            />
      </>
    );
  }
};
VentanaDialogo.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.bool.isRequired,
  open: PropTypes.bool,
  name: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func,
};
export default VentanaDialogo;
