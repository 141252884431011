import React, { useContext } from 'react';
import { Drawer, DrawerContent, DrawerHeader } from '@rmwc/drawer';
import { List, ListItem, ListItemGraphic, ListItemText } from '@rmwc/list';
import { IconButton } from '@rmwc/icon-button';
import DrawerContext from './DrawerContext';
import MDIcon from '../MDIcon';
import Divider from '../Divider';
import { Link } from 'react-router-dom';

const DrawerWrapper = () => {
  const { drawerOpen, setDrawerOpen } = useContext(DrawerContext);

  return (
    <Drawer modal open={drawerOpen} onClose={() => setDrawerOpen(false)}>
      <DrawerHeader style={{ paddingLeft: '.4rem' }}>
        <IconButton onClick={() => setDrawerOpen(false)} style={{ marginTop: '.4rem' }}>
          <MDIcon icon={'close'}/>
        </IconButton>
      </DrawerHeader>
      <Divider/>
      <DrawerContent>
        <List>
          <Link to={'/'}>
            <ListItem>
              <ListItemGraphic>
                <MDIcon icon={'home-outline'}/>
              </ListItemGraphic>
              <ListItemText>Home</ListItemText>
            </ListItem>
          </Link>
          <ListItem>
            <ListItemGraphic>
              <MDIcon icon={'clipboard-text-outline'}/>
            </ListItemGraphic>
            <ListItemText>Campaign</ListItemText>
          </ListItem>
          <ListItem>
            <ListItemGraphic>
              <MDIcon icon={'message-text-outline'}/>
            </ListItemGraphic>
            <ListItemText>Dialog Provider</ListItemText>
          </ListItem>
          <ListItem>
            <ListItemGraphic>
              <MDIcon icon={'account-supervisor-outline'}/>
            </ListItemGraphic>
            <ListItemText>Ally</ListItemText>
          </ListItem>
          <ListItem>
            <ListItemGraphic>
              <MDIcon icon={'chart-line'}/>
            </ListItemGraphic>
            <ListItemText>Charts</ListItemText>
          </ListItem>
          <Divider/>
          <ListItem>Item without icon</ListItem>
        </List>
      </DrawerContent>
    </Drawer>
  );
};

export default DrawerWrapper;
