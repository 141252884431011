import React, { useEffect, useContext } from 'react';
import { NavigationContext } from '../../contexts/navigation-context';
import style from './statistics.module.css';
import Dashboard from '../dashboardGen/Dashboard';

const Statistics = () => {
  const { navigationHistory, goBack, addToHistory } = useContext(NavigationContext);

  useEffect(() => {
    addToHistory('statistics');
  }, []);

  const linkDashBoard = !process.env.REACT_APP_ENV || process.env.REACT_APP_ENV === 'dev'
    ? process.env.REACT_APP_LINK_DASHBOARD_GEN_DEV
    : process.env.REACT_APP_LINK_DASHBOARD_GEN_PROD;
  return (
    <>
      <div className={'generalContainer'}>
        {navigationHistory.length >= 1 && (
          <button className={style.buttonPop} type={'button'} onClick={goBack}>Regresar</button>
          )}
        <div className={'containerModule flex-column'}>
          <div className={style.containerNavbar}>
            <iframe style = {{ width: '80vw', height: '80vh', border: '0' }}
                    src={linkDashBoard}
                    frameBorder={'0'}
                    allowFullScreen>
            </iframe>
            <div>
              <h1>Visualizacion Oportunidades por Perfil</h1>
              < Dashboard />
            </div>
          </div>
        </div>

      </div>
    </>
  );
};
export default Statistics;
