import React, { useState, useContext } from 'react';
import style from './showFolder.module.css';
import { Card } from '@rmwc/card';
import { TextField } from '@rmwc/textfield';
import { Typography } from '@rmwc/typography';
import { Button } from '@rmwc/button';
import { useHistory } from 'react-router-dom';
import FoldersActions from '../../api/Folder';
import { UserContext } from '../../contexts/user-context';
import NotificationContext from '../../contexts/notification-context';
import { ERROR, SUCCESSFULL } from '../../utils/Messages';

const CreatedFolder = () => {
    const { currentUser } = useContext(UserContext);
    const { dispatchData } = useContext(NotificationContext);
    const [nameFolder, setNameFolder] = useState('');
    const history = useHistory();
    const validateForm = () => {
      if (nameFolder.length <= 0) {
        return dispatchData({ text: ERROR.EMPTY_NAME_PROFILE });
      }
      return validateInfo();
    };
    const validateInfo = async () => {
        const { userId, nameEnterprise, emailUser } = currentUser;
        FoldersActions.postNameFolders(userId, nameFolder, nameEnterprise, emailUser)
            .then(() => dispatchData({ text: `${SUCCESSFULL.CREATE_FOLDER}${nameFolder}` }))
            .catch(error => dispatchData({ text: ERROR.CREATE_FOLDER, error }))
            .finally(() => history.push('/showFolder'));
    };
    return (
      <>
        <div className={'generalContainer'}>
          <div className={'containerModule flex-column'}>
            <Card style={{ width: '100%', padding: '1em', margin: 'auto' }}>
              <Typography use={'headline2'}>Crear Carpeta</Typography>
              <form>
                <TextField required name={'nameFolder'} onChange={(e) =>
                                  setNameFolder(e.target.value)}
                           label={'Nombre de la carpeta: '}
                           type={'text'}
                           className={style.text} />
              </form>
            </Card>
            <Button onClick={() => validateForm()}>Guardar</Button>
          </div>
        </div>
      </>
    );
};
export default CreatedFolder;
