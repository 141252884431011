import React, { useContext } from 'react';
import { Dialog, DialogTitle, DialogContent } from '@rmwc/dialog';
import LoginPopUpContext from '../../contexts/popup-context';
import { Button } from '@rmwc/button';

const PopUp = () => {
  const { data } = useContext(LoginPopUpContext);

  return (
    <Dialog open={data.show}
            onClosed={data.onclosed}>
      <DialogTitle>{data.title}</DialogTitle>
      <DialogContent>
        {data.message}
        <br />
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Button label={data.btnCancel} onClick={data.oncancel}/>
          <Button label={data.buttonLabel} onClick={data.onclick}/>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default PopUp;
