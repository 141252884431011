import axios from 'axios';

const apiURL = process.env.REACT_APP_ENV
? process.env.REACT_APP_ENV === 'dev'
? process.env.REACT_APP_API_URL_DEV
: process.env.REACT_APP_API_URL_PROD
: process.env.REACT_APP_LOCAL_ENV;

  export const api = axios.create({
  baseURL: apiURL,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('token')}` || '',
  },
});

export const getApiURL = () => apiURL;
export const getResponseData = (resp) => resp.data;

export const escalateError = (err) => {
  let errorFromResponse;
  try {
    errorFromResponse = err.response.data.error.toString();
  } catch (e) {
    errorFromResponse = undefined;
  }
  const newErr = new Error(errorFromResponse
    || (err instanceof Error
      ? err.message || err.toString()
      : typeof err === 'string'
        ? err
        : err.toString() || 'Error Inesperado'));
  try {
    newErr.data = err.response.data;
    newErr.status = err.response.status;
  } catch (e) {
  }

  throw newErr;
};

const apiOpenaiUrl = process.env.REACT_APP_API_URL_OPENAI_CONSULT;

export const apiOpenai = axios.create({
  baseURL: apiOpenaiUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const getApiOpenaiURL = () => apiOpenaiUrl;

export default api;
