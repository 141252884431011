import { api, escalateError, getResponseData } from './index';

export default class keysSelect {
    static async showKeys() {
        return api.get('/api/chips')
            .then(getResponseData)
            .catch(escalateError);
    }

    static async saveKeys(keys, email) {
        return api.post('/api/chips', {
            keys,
            email,
        })
            .then(getResponseData)
            .catch(escalateError);
    }
}
