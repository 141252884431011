import React, { createContext, useEffect, useState } from 'react';
import firebase from '../firebase';
import PropTypes from 'prop-types';
import DataRegister from '../api/Register';
import { ERROR, SUCCESSFULL } from '../utils/Messages';

export const UserContext = createContext('user-data');

const UserData = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(firebase.auth().currentUser || null);

  const validateUserInfo = async (emailUser) => {
    return firebase.auth().currentUser.getIdToken(true)
      .then(function (idToken) {
        localStorage.setItem('token', idToken);
        firebase.analytics().logEvent('login Success');
        return DataRegister.getUserByEmail(emailUser)
          .then(({ data }) => {
            const userInfo = {
              ...data,
              token: idToken,
              email: emailUser,
              origen: 2,
            };
            localStorage.setItem('u', JSON.stringify(userInfo));
            return { href: '/', userInfo };
        })
        .catch((e) => {
          firebase.analytics().logEvent(ERROR.LOGIN_FAIL);
        });
      })
      .catch((e) => {
        firebase.analytics().logEvent(ERROR.LOGIN_FAIL);
      });
  };

  useEffect(() => {
    let shouldUpdateCurrentUser = true;
    if (localStorage.getItem('u')) {
      const user = JSON.parse(localStorage.getItem('u'));
      setCurrentUser(user);
    } else {
      firebase.auth().onAuthStateChanged(async (user) => {
        if (user && shouldUpdateCurrentUser) {
          const { email } = user;
          firebase.analytics().logEvent('login Process');
          await validateUserInfo(email).then(async (dataRecep) => {
            if(dataRecep){
              const { userInfo, isNewUser} = dataRecep;
              if (!isNewUser) {
                shouldUpdateCurrentUser = false;
                window.location.reload();
              } else {
                setCurrentUser({ ...userInfo });
              }
            }
          });
        }
      });
    }
  }, [setCurrentUser]);
  
  const signIn = async (email, password) => new Promise((resolve, reject) => {
      Promise.all([
        firebase.auth().signInWithEmailAndPassword(email, password),
      ])
      .then(async () => validateUserInfo(email)
        .then(({ href, userInfo }) => {
          resolve({ href, userInfo, message: SUCCESSFULL.LOGIN });
        })
        .catch((e) => {
          reject(new Error(ERROR.USER_NOT_REGISTER));
        }))
      .catch((e) => {
        reject(new Error(ERROR.LOGIN_FAIL));
      });
  });

  const singInWithGoogle = () => new Promise((resolve, reject) => {
    const provider = new firebase.auth.GoogleAuthProvider();
    firebase.auth().signInWithPopup(provider)
      .then(async (result) => {
        const userGoogle = result.additionalUserInfo.profile;
        const { email: emailUser } = userGoogle;
        return validateUserInfo(emailUser)
          .then(({ href, userInfo }) => {
            resolve({
              href,
              userInfo: { ...userInfo, ...userGoogle },
              message: SUCCESSFULL.LOGIN,
            });
          })
          .catch((e) => {
            reject(new Error(ERROR.USER_NOT_REGISTER));
          });
      })
      .catch((e) => {
        reject(new Error(ERROR.GENERAL_ERROR));
      });
  });

    const reauthenticate = () => {
    if (firebase.auth().currentUser) {
        const { email } = firebase.auth().currentUser;
        firebase.analytics().logEvent('login Process');
        validateUserInfo(email)
          .then(({ userInfo }) => setCurrentUser({ ...userInfo }));
    }
  };

  const signOut = async () => {
      localStorage.clear();
      await firebase.auth().signOut();
      window.location.href = '/';
  };

  const share = {
    signIn,
    singInWithGoogle,
    reauthenticate,
    signOut,
    currentUser,
  };

  return <UserContext.Provider value={share}>{children}</UserContext.Provider>;
};

UserData.propTypes = {
  children: PropTypes.oneOfType(
    [PropTypes.object, PropTypes.arrayOf(PropTypes.element)]).isRequired,
};

export default UserData;
