const evenstUser = () => {
    const documentsHTML = document.querySelector('html');
    eventUserKey();
    return documentsHTML.addEventListener('click', (e) => {
        window.localStorage.setItem('activate', true);
        return true;
    });
};
const eventUserKey = () => {
    const documentsHTML = document.querySelector('html');
    return documentsHTML.addEventListener('keypress', (e) => {
        window.localStorage.setItem('activate', true);
        return true;
    });
};
// eslint-disable-next-line func-style
export function listenignUser() {
    return evenstUser();
};
