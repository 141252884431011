/* eslint-disable max-lines */
import React, { useState, useEffect, useContext } from 'react';
import style from './showFolder.module.css';
import { Typography } from '@rmwc/typography';
import { Button } from '@rmwc/button';
import MDIcon from '../mdc/MDIcon';
import Loader from '../loader/Loader';
import { useHistory } from 'react-router-dom';
import FoldersActions from '../../api/Folder';
import { TextField } from '@rmwc/textfield';
import { UserContext } from '../../contexts/user-context';
import NotificationContext from '../../contexts/notification-context';
import { Dialog, DialogTitle, DialogContent, DialogActions, DialogButton } from '@rmwc/dialog';
import { NavigationContext } from '../../contexts/navigation-context';
import { ERROR, SUCCESSFULL } from '../../utils/Messages';
import { Tooltip } from '@rmwc/tooltip';

const ShowFolder = () => {
    const { currentUser } = useContext(UserContext);
    const { dispatchData } = useContext(NotificationContext);
    const history = useHistory();
    const [dataPartners, setDataPartners] = useState([]);
    const [permissions, setPermissions] = useState([]);
    const [folderSelected, setFolderSelected] = useState('');
    const [folderDeleted, setFolderDeleted] = useState('');
    const [idUserForPags, setIdUserForPags] = useState([]);
    const [haveUpdated, setHaveUpdated] = useState(false);
    const [nameFolder, setNameFolder] = useState('');
    const [folderId, setFolderId] = useState('');
    const [pag, setPag] = useState(1);
    const [rolPerson, setRolPerson] = useState('');
    const [lastIndex, setLastIndex] = useState('');
    const [firstIndex, setFirstIndex] = useState('');
    const [haveNextPage, setHaveNextPage] = useState(false);
    const [previous, setPrevious] = useState(false);
    const { navigationHistory, goBack, addToHistory } = useContext(NavigationContext);

    useEffect(() => {
      addToHistory('showFolder');
    }, []);

    useEffect(() => {
      const { userId, nameEnterprise, permissions, rol } = currentUser;
      const dataUser = {
        userId, nameEnterprise, rol, lastIndex, firstIndex, previous,
      };
      setRolPerson(rol);
      if (!permissions.includes('read')) {
        dispatchData({ text: ERROR.UNAUTHORIZED });
      } else {
        setHaveUpdated(false);
        setPermissions((prev) => prev.concat(permissions));
        FoldersActions.getNamesFolder(dataUser)
              .then(({ data: dataUsers }) => {
                const { firstIndex, lastIndex, data, nextPage } = dataUsers;
                setFirstIndex(firstIndex);
                setLastIndex(lastIndex);
                setHaveNextPage(nextPage);
                setIdUserForPags(idUserForPags);
                setDataPartners(data);
              })
              .catch(e => dispatchData({ text: ERROR.SHOW_FOLDER, error: e }));
      }
    }, [pag, haveUpdated]);

    const deleteFolder = (selectfolder, status) => {
      const { userId, nameEnterprise, emailUser, rol } = currentUser;
          FoldersActions.postDeleteFolder({
            userId,
            folderId: selectfolder,
            nameEnterprise,
            emailUser,
            rol,
            deleted: status,
          })
              .then(() => {
              status === true
              ? dispatchData({ text: SUCCESSFULL.DELETE_FOLDER })
              : dispatchData({ text: SUCCESSFULL.ACTIVATE_FOLDER });
            })
              .catch(e => dispatchData({ text: e.message }))
              .finally(() => {
                setHaveUpdated(true);
                setLastIndex('');
                setFirstIndex('');
              });
    };
    const editFolderSend = () => {
      const { userId, nameEnterprise, emailUser } = currentUser;
          FoldersActions.postEditFolder({ userId, folderId, nameEnterprise, emailUser, nameFolder })
              .then(() => dispatchData({ text: `${SUCCESSFULL.EDIT_FOLDER}${nameFolder}` }))
              .catch(e => dispatchData({ text: ERROR.UPDATE_ERROR }))
              .finally(() => {
                setHaveUpdated(true);
                setLastIndex('');
                setFirstIndex('');
              });
    };
    const showDataTable = () => {
        return dataPartners.length === 0
        ? <div style={{ margin: 'auto' }}>
          <h2>No hay resultados para mostrar</h2>
        </div>
        : dataPartners.map((responseNames, index) => (
          <table className={style.tableStyle} key={index}>
            <tr>
              <td colSpan={'4'} className={style.style_td} style={{ textAlign: 'right' }}>
                {
                 permissions.includes('read')
                 && <Tooltip content={
                   <div style={{ display: 'flex' }}>
                     <div style={{ marginLeft: '0.5rem' }}>
                       <b>Ver Contenido</b>
                       <div>Podrás ver todas las oportunidades guardadas en está carpeta</div>
                     </div>
                   </div>}
                             align={'right'}
                  >
                   <Button
                      className={style.button}
                      style={{ color: '#FFC400' }}
                      id={style.update}
                      onClick={() => history.push(`/folders/${responseNames.id}`)}>
                     <MDIcon icon={'clipboard-file'} />
                   </Button>
                 </Tooltip>
              }
                {
                 permissions.includes('delete')
                 && <Tooltip content={
                   <div style={{ display: 'flex' }}>
                     <div style={{ marginLeft: '0.5rem' }}>
                       <b>Eliminar Carpeta</b>
                       <div>Podras eliminar está carpeta</div>
                     </div>
                   </div>}
                             align={'right'}
                 >
                   <Button
                 disabled={responseNames.delete}
                 className={style.btn_add}
                 style={{ color: '#FFC400' }}
                 onClick={() => setFolderDeleted(responseNames.id)} >
                     <MDIcon icon={'delete'} />
                   </Button>
                 </Tooltip>
              }
                <Dialog open={folderDeleted === responseNames.id}
                        onClose={(evt) => setFolderDeleted('')}>
                  <DialogTitle>¿Desea elminiar la carpeta {responseNames.nameFolder}?</DialogTitle>
                  <DialogActions>
                    <DialogButton action={'exit'} isDefaultAction>
                      Cancelar
                    </DialogButton>
                    <DialogButton onClick={() =>
                          deleteFolder(responseNames.id, true)}> Eliminar
                    </DialogButton>
                  </DialogActions>
                </Dialog>
                {
              permissions.includes('update')
                && <Tooltip content={
                  <div style={{ display: 'flex' }}>
                    <div style={{ marginLeft: '0.5rem' }}>
                      <b>Editar Carpeta</b>
                      <div>Podras cambiar el nombre de está carpeta</div>
                    </div>
                  </div>}
                            align={'right'}
                 >
                  <Button style={{ color: '#FFC400' }}
                          className={style.btn_add}
                          onClick={() => setFolderSelected(responseNames.id)}>
                    <MDIcon icon={'folder-edit'} />
                  </Button>
                </Tooltip>
              }
                <Dialog open={folderSelected === responseNames.id}
                        onClose={(evt) => setFolderSelected('')}>
                  <DialogTitle>Editar la carpeta {responseNames.nameFolder}</DialogTitle>
                  <DialogContent> Nuevo nombre <br />
                    <TextField
                    name={'saveSearch'} onChange={(e) => {
                      setNameFolder(e.target.value);
                      setFolderId(responseNames.id);
                    }}
                   />
                  </DialogContent>
                  <DialogActions>
                    <DialogButton action={'exit'} isDefaultAction>
                      Cancelar
                    </DialogButton>
                    <DialogButton
                  onClick={() => editFolderSend()} > Enviar
                    </DialogButton>
                  </DialogActions>
                </Dialog>
              </td>
            </tr>
            <tr>
              <td colSpan={'4'} className={style.style_td}>
                <h2 className={style.text_name}>Nombre: </h2>
                <h2 className={style.text_email}>{responseNames.nameFolder || null}</h2>
              </td>
            </tr>
            <tr>
              <td colSpan={'4'} className={style.style_td}>
                <h2 className={style.text_name}>Oportunidades Guardadas: </h2>
                <h2 className={style.text_email}>{responseNames.oportunities.length}</h2>
              </td>
            </tr>
            <tr>
              <td colSpan={'4'} className={style.style_td}>
                <h2 className={style.text_name}>Creador: </h2>
                <h2 className={style.text_email}>{responseNames.registerBy || null}</h2>
              </td>
            </tr>
            <tr>
              {
              (rolPerson !== 'partners' && permissions.includes('delete'))
              && <td colSpan={'4'} className={style.style_td}>
                <h2 className={style.text_name}>Estado de la Carpeta</h2>
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '10px',
                  }}>
                  <p className={style.label_inactivo}
                     style={{ color: responseNames.delete ? '#9747FF' : '#D9D9D9' }}>
                    Inactiva
                  </p>
                  <label className={style.switch}>
                    <input type={'checkbox'}
                           checked={!responseNames.delete}
                           onChange={(evt) =>
                              deleteFolder(
                                responseNames.id, !evt.currentTarget.checked
                              )}
                    />
                    <span className={style.slider}></span>
                  </label>
                  <p className={style.label_activo}
                     style={{ color: responseNames.delete ? '#D9D9D9' : '#9747FF' }}>
                    Activa
                  </p>
                </div>
              </td>
              }
            </tr>
          </table>
        ));
    };

    return (<>
      <div className={'generalContainer'}>
        {navigationHistory.length >= 1 && (
          <button className={style.buttonPop} type={'button'} onClick={goBack}>Regresar</button>
          )}
        <div className={'containerModule flex-column'}>
          <Typography use={'headline2'} style={{ padding: '35px', color: '#9747FF' }}>
            Carpetas</Typography>
          <div className={style.container_navSearch}>
            {
              permissions.includes('created') && <button
              className={style.btn_permisos}
              onClick={() => history.push('createdFolder')}
              >Crear Carpeta
              </button>
            }
          </div>

          <div className={style.table_container}>  {
              permissions.includes('read') && (dataPartners
                    ? <> {showDataTable()} </>
                    : <Loader />)
            }</div>
          {
              permissions.includes('read')
              && <div className={style.container__butonsPag}>
                { pag > 1 && <button className={style.butonsPag}
                                     onClick={() => {
                                setPrevious(true);
                                setPag((pag - 1));
                             }}>
                  <MDIcon icon={'arrow-left'} />Anterior
                </button> }
                <div className={style.centro}>
                  <Button key={pag} className={style.butonsPagNumber} label={pag}/>
                </div>
                { haveNextPage && <button className={style.butonsPag} onClick={() => {
                    setPrevious(false);
                    setPag((pag + 1));
                  }}>Siguiente<MDIcon icon={'arrow-right'} /></button>
              }
              </div>
            }
        </div>
      </div>
    </>
    );
};
export default ShowFolder;

