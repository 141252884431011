/* eslint-disable max-lines */
/* eslint-disable max-len */
/* eslint-disable prefer-destructuring */
import React, { useState, useContext, useEffect } from 'react';
import KeyWords from './PalabrasClave';
import PerfilamientoUsers from './PerfilamientoUsers';
import SelecLocat from './SeleccionLugar';
import SeleccionModalidad from './SeleccionModalidad';
import SelecCuantia from './selecCuantia';
import SelecType from './TipoProceso';
import { Button } from '@rmwc/button';
import VentanaDialogo from '../resultSearch/VentanaDialogo';
import { TextField } from '@rmwc/textfield';
import dataUnspsc from './../../api/PerfilUser';
import { UserContext } from '../../contexts/user-context';
import NotificationContext from '../../contexts/notification-context';
import { ERROR, INFO, SUCCESSFULL } from '../../utils/Messages';
import { Dialog, DialogTitle, DialogContent } from '@rmwc/dialog';
import { useParams } from 'react-router-dom';
import { NavigationContext } from '../../contexts/navigation-context';
import { ProfileContext } from '../../contexts/profile-context';
import { LocateContext } from '../../contexts/located-context';
import style from './perfilamientoUsers.module.css';

const ShowAllComponents = () => {
    const { currentUser } = useContext(UserContext);
    const { dispatchData } = useContext(NotificationContext);
    const [dataSend, setDataSend] = useState({});
    const [activateShowSelect, setActivateShowSelect] = useState();
    const [nameProfile, setNameProfile] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const { profileId } = useParams();
    const { dataProfile } = useContext(ProfileContext);
    const [isSaving, setIsSaving] = useState(false);
    const [idUserChange, setIdUserChange] = useState('');
    const {
      municipality,
      departments,
      regiones,
    } = useContext(LocateContext);
    const { navigationHistory, goBack, addToHistory } = useContext(NavigationContext);

    useEffect(() => {
      addToHistory('showComponents');
    }, []);

    const sendDataBack = (updatedDataSend) => {
      const { nameEnterprise, userId } = currentUser;
      updatedDataSend.nameProfield = nameProfile;
      setIdUserChange(userId);
      dataUnspsc.postPerfil(updatedDataSend, nameEnterprise, userId)
          .then(({ data }) => dispatchData({ text: data }))
          .catch((e) => dispatchData({ text: ERROR.GENERAL_ERROR, error: e }))
          .finally(() => setOpenDialog(false));
    };

    const updateAllData = () => {
      if (!isSaving) {
        setIsSaving(true);
        const { nameProfile, userId, nameEnterprise, profileId, Clasificacion } = dataProfile;
        const sendDataProfiles = { ...dataSend };
        const ClasificacionFinal = !sendDataProfiles.Clasificacion ? Clasificacion : sendDataProfiles.Clasificacion;
        if (!ClasificacionFinal || ClasificacionFinal === 'nada') {
          if (municipality.length > 0) {
            sendDataProfiles.Lugar = {
              Municipios: municipality,
              Departamentos: departments,
              Regiones: regiones,
            };
          }
          const updatedDataProfile = { ...sendDataProfiles };
          delete updatedDataProfile.Clasificacion;
          dataUnspsc.updateProfiles({
            userId,
            nameEnterprise,
            profileId,
            dataProfile: {
              ...updatedDataProfile,
              Clasificacion: {},
            },
            nameProfile,
            updateBy: idUserChange,
          }).then(() => dispatchData({ text: SUCCESSFULL.UPDATE_PROFILE })
          )
          .catch(() => dispatchData({ text: ERROR.DELETED_ERROR }));
        } else {
          const ClasificacionSin = removeDuplicatesFromClasificacion(ClasificacionFinal);
          if (municipality.length > 0) {
            sendDataProfiles.Lugar = {
              Municipios: municipality,
              Departamentos: departments,
              Regiones: regiones,
            };
          }
          dataUnspsc.updateProfiles({
            userId,
            nameEnterprise,
            profileId,
            dataProfile: {
              ...sendDataProfiles,
              Clasificacion: ClasificacionSin,
            },
            nameProfile,
            updateBy: idUserChange,
          }).then(() => dispatchData({ text: SUCCESSFULL.UPDATE_PROFILE })
          )
          .catch(() => dispatchData({ text: ERROR.DELETED_ERROR }));
        }
      }
    };

    const validateForm = () => {
      if (Object.keys(dataSend).length <= 0) {
        setIsSaving(false);
        return dispatchData({ text: ERROR.INCOMPLETE_FORM });
      }
      const { cuantia, keyWord, Modalidad, typeProfile, Clasificacion, Lugar } = dataSend;

      if (!cuantia && !keyWord && !Modalidad && !typeProfile && !Clasificacion && !Lugar) {
        setIsSaving(false);
        return dispatchData({ text: ERROR.INCOMPLETE_FORM });
      }

      if (cuantia && cuantia.maximo < cuantia.minimo) {
        return dispatchData({ text: `${INFO.CHECK_VALUES} Selección Cuantia` });
      }

      if (nameProfile.length <= 0) {
        return dispatchData({ text: ERROR.EMPTY_NAME_PROFILE });
      }
      const updatedClasificacion = removeDuplicatesFromClasificacion(Clasificacion);
      const updatedDataSend = { ...dataSend, Clasificacion: updatedClasificacion };

      return sendDataBack(updatedDataSend);
    };

    const removeDuplicatesFromClasificacion = (clasificacion) => {
      if (Array.isArray(clasificacion)) {
        const uniqueClasificacion = clasificacion.reduce((acc, obj) => {
          const key = Object.keys(obj)[0];
          const value = obj[key];
          if (!acc[key]) {
            acc[key] = value;
          }
          return acc;
        }, {});

        return Object.entries(uniqueClasificacion).map(([key, value]) => ({
          [key]: value,
        }));
      } else if (typeof clasificacion === 'object') {
        const uniqueClasificacion = {};
        Object.entries(clasificacion).forEach(([key, value]) => {
          if (Array.isArray(value)) {
            uniqueClasificacion[key] = value.reduce((acc, item) => {
              if (!acc.some((el) => Object.values(el)[0] === Object.values(item)[0])) {
                acc.push(item);
              }
              return acc;
            }, []);
          }
        });

        return uniqueClasificacion;
      }

      return clasificacion;
    };

    const handleSaveClick = () => {
      if (!isSaving) { // Verificar si no se está guardando actualmente
        setIsSaving(true); // Actualizar el estado para indicar que se está guardando
        validateForm();
        setOpenDialog(false);
      }
    };
    return (
      <>
        <div className={'generalContainer'}>
          {navigationHistory.length >= 1 && (
            <button className={style.buttonPop} type={'button'} onClick={goBack}>Regresar</button>
          )}
          <div className={style.container__modules}>
            <div>
              <SelecType onChange={(data) => setDataSend({
                ...dataSend,
                typeProfile: data,
              })} postData= {profileId ? dataProfile.typeProfile : null}/>
            </div>
            <div>
              <SelecLocat onChange={(dataRe) => setDataSend({ ...dataSend, Lugar: dataRe })}/>
            </div>
            <div>
              <SeleccionModalidad onChange={(data) => {
                setDataSend({ ...dataSend, Modalidad: data });
              }} />
            </div>
            <div>
              <SelecCuantia onChange={(cuantia) => setDataSend({ ...dataSend, ...cuantia })} />
            </div>
            <div>
              <KeyWords onChange={(keyWord) => setDataSend({ ...dataSend, keyWord })} />
            </div>
            <div>
              <PerfilamientoUsers
                onChange={(dataRecp) => setDataSend({
                  ...dataSend,
                  Clasificacion: dataRecp,
                })}
                 />
            </div>
            <VentanaDialogo
                        title={'Nombre Perfil'}
                        body={<TextField name={'nameProfield'} onChange={(e) => setDataSend({
                            ...dataSend,
                            [e.target.name]: e.target.value,
                        })} />}
                        open={activateShowSelect}
                        onClose={(e) => {
                            if (e.detail.action === 'accept') sendDataBack();
                            setActivateShowSelect(false);
                        }} />
          </div>
          {!profileId
            ? <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
              style={{ maxHeight: '15%' }}
              className={style.buttonStyle}
              onClick={() => setOpenDialog(true)}
              disabled={isSaving}
            >
                Guardar
              </Button>
              <Dialog open={openDialog} onClosed={() => setOpenDialog(false)}>
                <DialogTitle>Nombre Perfil</DialogTitle>
                <DialogContent>
                  <TextField onChange={(e) => setNameProfile(e.target.value)} />
                  <br />
                  <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                    <Button
                    className={style.buttonStyle}
                    label={'Cancelar'}
                    onClick={() => setOpenDialog(false)}
                  />
                    <Button
                    className={style.buttonStyle}
                    label={'Guardar'}
                    onClick={handleSaveClick}
                  />
                  </div>
                </DialogContent>
              </Dialog>
            </div>
            : <Button onClick={() => updateAllData()} className={style.buttonStyle} >Actualizar
            </Button>}
        </div>
      </>
    );
};

export default ShowAllComponents;
