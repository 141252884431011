import React from 'react';
import style from './statistics.module.css';
const StaticsAdmin = () => {
  const urlLinkDashBoard = process.env.REACT_APP_ENV !== 'prod'
    ? process.env.REACT_APP_LINK_DASHBOARD_ADMIN_DEV
    : process.env.REACT_APP_LINK_DASHBOARD_ADMIN_PROD;
    return (
      <>
        <div className={'generalContainer'}>
          <div className={style.container__dashboard}>
            <div style={{ padding: '8% 0' }}>
              <iframe style={{ width: '80vw', height: '80vh' }}
                      src={urlLinkDashBoard}>
              </iframe>
            </div>
          </div>
        </div>
      </>
    );
};

export default StaticsAdmin;
