import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const ResultContext = createContext();

const ResultContextProvider = ({ children }) => {
      const [profileContext, setProfileContext] = useState({});
      const [stateContextProfile, setStateContextProfile] = useState(false);
    return (
      <ResultContext.Provider value={{
        profileContext,
        setProfileContext,
        stateContextProfile,
        setStateContextProfile,
        }}>
        {children}
      </ResultContext.Provider>
    );
};

ResultContextProvider.propTypes = {
    children: PropTypes.oneOfType(
        [PropTypes.object, PropTypes.arrayOf(PropTypes.element)]).isRequired,
};
export default ResultContextProvider;

