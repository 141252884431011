/* eslint-disable max-lines */
/* eslint-disable prefer-destructuring */
/* eslint-disable max-len */
/* eslint-disable consistent-return */
/* eslint-disable max-nested-callbacks */
import React, { useState, useEffect, useContext } from 'react';
import style from './perfilamientoUsers.module.css';
import { Typography } from '@rmwc/typography';
import selecLocat from '../../api/selecLugar';
import { Checkbox } from '@rmwc/checkbox';
import { Button } from '@rmwc/button';
import { TextField } from '@rmwc/textfield';
import { Select } from '@rmwc/select';
import { Tooltip } from '@rmwc/tooltip';
import PropTypes from 'prop-types';
import NotificationContext from '../../contexts/notification-context';
import { ERROR } from '../../utils/Messages';
import { ProfileContext } from '../../contexts/profile-context';
import ShowDeptMun from './ShowDeptMun';
import { LocateContext } from '../../contexts/located-context';

const SelecLocat = ({ onChange, existMun, dataFilterMun }) => {
  const { dispatchData } = useContext(NotificationContext);
  const [dataLoc, setDataLoc] = useState();
  const [dataReg, setDataReg] = useState();
  const [dataDept, setDataDept] = useState();
  const [dataMun, setDataMun] = useState();
  const [nameSearch, setNameSearch] = useState('');
  const [searchData, setSearchData] = useState();
  const [checkedAll, setCheckedAll] = useState(false);
  const [code, setCode] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [preventReload, setPreventReload] = useState(true);
  const { dataProfile } = useContext(ProfileContext);
  const [controllerDeptMun, setControllerDeptMun] = useState(false);
  const [disableSelect, setDisableSelect] = useState(false);
  const [checkedMun, setCheckedMun] = useState([]);
  const {
    setMunicipality: setMunicipio,
    municipality: municipio,
    setAllDataMunDep,
    setDepartments: setDepartamentos,
    departments: departamentos,
    regiones,
    setRegiones,
  } = useContext(LocateContext);

  const getKeys = (object) => {
    object.forEach((it) => {
      setCode(oldArray => [...oldArray, Object.keys(it)[0]]);
    });
  };
  useEffect(() => {
    selecLocat.getLoc()
      .then((responseData) => {
        const { data } = responseData || {};
        const { AllData, Departamento, Region } = data;
        setDataReg(Object.keys(AllData));
        setDataLoc(AllData);
        setDataDept(Region);
        setDataMun(Departamento);
        setAllDataMunDep(Departamento);
        existMun && setNameSearch('Municipio');
      })
      .catch((e) => dispatchData({ text: ERROR.GENERAL_ERROR, error: e }));
    if (dataProfile.Lugar) {
      const { Regiones, Departamentos, Municipios } = dataProfile.Lugar;
      if (Municipios) {
        getKeys(Municipios);
        setMunicipio([...Municipios]);
      }
      if (Municipios && !Departamentos && !Regiones) {
        setNameSearch('Municipio');
        setDisableSelect(true);
      }
      Regiones && setRegiones(Regiones);
      Departamentos && setDepartamentos(Departamentos);
      Departamentos && setCheckedAll(true);
      if (!Regiones) setControllerDeptMun(true);
    }
  }, []);

  useEffect(() => {
    if (preventReload) {
      if (regiones.length > 0 || departamentos.length > 0) {
        const dataSend = {
          Regiones: regiones.length > 0 ? regiones : undefined,
          Municipios: dataProfile
            ? municipio
            : municipio.length > 0
              ? Object.values(municipio).map((valueData) => Object.values(valueData))
                .flat(Infinity)
              : undefined,
          Departamentos: departamentos.length > 0 ? departamentos : undefined,
        };
        if (regiones.length > 0
          || municipio.length > 0
          || departamentos.length > 0) return onChange(dataSend);
      }
      const dataSend = dataProfile
        ? { Municipios: municipio }
        : {
          Municipios: municipio.length > 0
            ? Object.values(municipio).map((valueData) => Object.values(valueData))
              .flat(Infinity)
            : undefined,
        };
      if (municipio.length > 0) return onChange(dataSend);
    }
  }, [regiones, municipio, departamentos]);

  useEffect(() => {
    if (dataFilterMun && dataFilterMun.Municipios) {
      const dataMun = dataFilterMun.Municipios;
      const objectMun = dataMun.map((nameMunicipality) => {
        return Object.values(municipio).filter((data) => {
          const [dataCompare] = Object.values(data);
          return dataCompare === nameMunicipality;
        })
          .flat(Infinity);
      });
      setPreventReload(false);
      return setMunicipio(objectMun.flat(Infinity));
    }
    if (dataFilterMun === undefined && !dataProfile) return setMunicipio([]);
  }, [dataFilterMun]);

  useEffect(() => {
    if (nameSearch && searchData) {
      const dataValues = nameSearch === 'Municipio'
        ? dataMun
        : dataDept;
      if (dataValues) {
        const arrayAux = Object.values(dataValues).flat(Infinity);
        const filterDta = arrayAux.filter(dataResult => {
          const nameData = nameSearch === 'Municipio'
            ? clearDataMun(dataResult)
            : dataResult;
          if (nameData) {
            const dataInclude = nameData.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
              .toLowerCase()
              .includes(searchData.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
                .toLowerCase());
            if (dataInclude) return nameData;
          }
        });
        setFilterData(filterDta);
      }
    };
  }, [searchData, nameSearch]);

  const createMunicipalityToDepartmentMap = (dataMun) => {
    const municipalityToDepartmentMap = {};
    for (const departmentName in dataMun) {
      const departmentData = dataMun[departmentName];
      const realIdDepartment = findRealIdDepartment(departmentName, dataMun);
      municipalityToDepartmentMap[realIdDepartment] = departmentName;
      for (const municipalityData of departmentData) {
        const [municipality] = municipalityData;
        municipalityToDepartmentMap[municipality.codMun] = departmentName;
      }
    }
    return municipalityToDepartmentMap;
  };

  const findDepartmentByMunicipalityCode = (municipalityCode, dataMun) => {
    const municipalityToDepartmentMap = createMunicipalityToDepartmentMap(dataMun);
    const departmentCode = municipalityCode.toString().slice(0, 2);
    return municipalityToDepartmentMap[departmentCode] || null;
  };
 /**
  * The function `findRegionByDepartment` takes a department name and a data object, and returns the
  * region that contains the department.
  * @param {departmentName}  - hace referencia al nombre del departamento a buscar.
  * @param {dataDept}  - hace referencia a la data de regiones.
  * @returns The function `findRegionByDepartment` returns the region name if the department name is
  * found in the `dataDept` object. If the department name is not found, it returns `null`.
  */
  const findRegionByDepartment = (departmentName, dataDept) => {
    for (const region in dataDept) {
      const regionData = dataDept[region];
        if (regionData.includes(departmentName)) {
          return region;
        }
    }
    return null;
  };

  const checkMunicipality = (stateValue, valueMunicipality, codMunicipality, isSearching) => {
    if (!isSearching) {
      if (stateValue) {
        const { codMun, nameMun } = valueMunicipality;
        setCheckedMun([...checkedMun, codMunicipality]);
        setMunicipio([...municipio, { [codMun]: nameMun }]);
        setCode(oldArray => [...oldArray, codMunicipality]);
      } else {
        const valueData = checkedMun.filter(data => data !== codMunicipality);
        setCheckedMun(valueData);
        setCode(code.filter((it) => it !== codMunicipality));
        const municipalityNewData = municipio.filter(data => {
          const [cod] = Object.keys(data);
          return parseInt(cod) !== parseInt(codMunicipality);
        });
        setMunicipio(municipalityNewData);
      }
    } else {
      if (stateValue && dataMun) {
        const { codMun, nameMun } = valueMunicipality;
        setCheckedMun([...checkedMun, codMunicipality]);
        setMunicipio([...municipio, { [codMun]: nameMun }]);
        setCode(oldArray => [...oldArray, codMunicipality]);
        const departmentName = findDepartmentByMunicipalityCode(codMun, dataMun);
        const region = findRegionByDepartment(departmentName, dataDept);
        if (departmentName != null && !departamentos.includes(departmentName)) {
          setDepartamentos(oldArray => [...oldArray, departmentName]);
        }
        if (region != null && !regiones.includes(region)) {
          setRegiones(oldArray => [...oldArray, region]);
        }
      } else {
        const valueData = checkedMun.filter(data => data !== codMunicipality);
        setCheckedMun(valueData);
        setCode(code.filter((it) => it !== codMunicipality));
        const municipalityNewData = municipio.filter(data => {
          const [cod] = Object.keys(data);
          return parseInt(cod) !== parseInt(codMunicipality);
        });
        setMunicipio(municipalityNewData);
      }
    }
  };
  const clearDataMun = (dataClean) => {
    if (dataClean) {
      const { nameMun } = dataClean;
      return nameMun && nameMun;
    }
    return null;
  };

  const showMun = () => {
    if (regiones.length > 0 && departamentos.length > 0) {
      return regiones.map((responseDatares) => {
        return departamentos.map((namesDepartments) => (
          <>
            {dataLoc[responseDatares][namesDepartments]
              && <>
                <h4 style={{ margin: '2px' }}>{namesDepartments}</h4>
                {dataLoc[responseDatares][namesDepartments].map((responseMun, index) => {
                  const { codMun, nameMun } = responseMun;
                  return (<Checkbox key={codMun}
                                    style={{ background: '#EBEBEB', borderRadius: '5px' }}
                                    label={nameMun}
                                    checked={code.includes(codMun?.toString()) || code.includes(parseInt(codMun))}
                                    onChange={(e) => {
                      checkMunicipality(e.currentTarget.checked, responseMun, codMun?.toString() || codMun);
                    }} />);
                })}
              </>
            }
          </>
        ));
      });
    }
  };

  const findRealIdDepartment = (departmentName, dataMun) => {
    const departmentData = dataMun[departmentName];
    if (departmentData && Array.isArray(departmentData) && departmentData.length > 0) {
      const firstMunicipality = departmentData[0][0];
      const idDepartamento = firstMunicipality?.codMun;
      return idDepartamento.toString().length === 4
      ? idDepartamento.toString()[0]
      : idDepartamento.toString().slice(0, 2);
    }
    return null;
  };

  const municipalityBelongsToRemovedDept = (municipio, departmentName, dataM) => {
    const [codMun] = Object.keys(municipio);
    const codMunReal = codMun.toString().length === 4
      ? codMun.toString()[0]
      : codMun.toString().slice(0, 2);
    const realIdDepartment = findRealIdDepartment(departmentName.toString(), dataM);
    return realIdDepartment === codMunReal;
  };

  const showDep = () => {
    const selectedDepartments = new Set(departamentos);
    if (dataMun) {
      return regiones.map((responseDataReg) => (
        <>
          <h4 style={{ margin: '2px' }}>{responseDataReg}</h4>
          {Object.keys(dataLoc[responseDataReg]).map((responseDep, index) => {
            if (index <= 5) {
              const isChecked = selectedDepartments.has(responseDep);
              return (
                <Checkbox
                  key={index}
                  style={{ background: '#EBEBEB', borderRadius: '5px' }}
                  checked={isChecked}
                  label={responseDep}
                  onChange={(e) => {
                    const departmentName = responseDep;
                    const auxAgrup = new Set(departamentos);
                    if (e.currentTarget.checked) {
                      auxAgrup.add(departmentName);
                    } else {
                      auxAgrup.delete(departmentName);
                      setMunicipio((prevMunicipio) => {
                        return prevMunicipio.filter((municipio) =>
                          !municipalityBelongsToRemovedDept(municipio, departmentName, dataMun)
                        );
                      });
                    }
                    setDepartamentos(Array.from(auxAgrup));
                  }}
                />
              );
            }
            return null;
          })}
        </>
      ));
    }
  };

  const getDataKeys = (dataGetKeys) => {
    if (dataGetKeys) {
      const { codMun } = dataGetKeys;
      return codMun && codMun;
    }
    return null;
  };

  const showFilters = () => {
    let contDat = 0;
    return filterData.map((valuesMunicipality, index) => {
      const nameValues = nameSearch === 'Municipio'
        ? clearDataMun(valuesMunicipality)
        : valuesMunicipality;
      contDat += 1;
      const valuesKeys = nameSearch === 'Municipio'
        ? getDataKeys(valuesMunicipality)
        : valuesMunicipality;
      const compareDataCheck = nameSearch === 'Municipio'
        ? checkedMun
        : departamentos;
      if (contDat < 16) {
        return (<Checkbox key={index + contDat + nameValues}
                          style={{ background: '#EBEBEB', borderRadius: '5px' }}
                          checked={compareDataCheck ? compareDataCheck.includes(valuesKeys) : false}
                          label={nameValues}
                          onChange={(e) => {
            if (nameSearch === 'Municipio') {
              return checkMunicipality(e.currentTarget.checked,
                valuesMunicipality,
                valuesKeys, true);
            }
            let auxAgrup = departamentos;
            auxAgrup = e.currentTarget.checked
              ? auxAgrup.concat(valuesKeys)
              : auxAgrup.filter((name) =>
                name !== valuesKeys);
            setDepartamentos(auxAgrup);
            const region = findRegionByDepartment(valuesKeys, dataDept);
            if (region !== null && !regiones.includes(region)) {
               setRegiones(oldArray => [...oldArray, region]);
            }
          }}
        />);
      }
      return null;
    });
  };

  const allConutry = () => {
    if (regiones.length <= 0) {
      const dataRegAll = Object.keys(dataDept).map((responseCountry) => responseCountry);
      setRegiones(dataRegAll);
      return setCheckedAll(false);
    }
    setRegiones([]);
    return setCheckedAll(false);
  };

  const isChecked = (nameReg) => {
    return regiones.flat(Infinity).includes(nameReg);
  };

  return (
    <>
      <div className={style.containerCon}>
        <div className={style.containerTitles}>
          <Typography use={'headline6'} style={{ color: '#9747FF' }}>Seleccion Lugar</Typography>
          <Typography use={'body6'} style={{ color: '#ADADAD' }}>
            Selecciona aqui los filtros de búsqueda de oportunidades
            según su localización de origen
          </Typography>
        </div>
        <div className={style.containerSelct}>
          {!existMun
            && <Tooltip content={'Busqueda por Departamento o Municipio'}>
              <Select label={'Busqueda por:'}
                      options={['-- Seleccionar --', 'Departamento', 'Municipio']}
                      onChange={(e) => {
                  e.target.value !== '-- Seleccionar --'
                    ? setNameSearch(e.target.value)
                    : setNameSearch('');
                  setSearchData('');
                  setFilterData([]);
                }
                } value={(nameSearch)}
                      disabled={disableSelect} />
            </Tooltip>
          }
          <TextField disabled={nameSearch.length <= 0} placeholder={'Buscar... '}
                     onChange={(e) => setSearchData(e.target.value)}
                     value={searchData || ''} />
          {!existMun
            && <Button className={style.buttonStyle}
                       onClick={() => allConutry()}
                       label={'Seleccionar todo'}
                       disabled={nameSearch !== ''} />
          }
        </div>
        <div>
          {!controllerDeptMun
            ? <>
              {!existMun
                && <>
                  <div className={style.containerRowsLoc}>
                    <div className={style.containerSecondLevel}>
                      {
                        dataLoc && !nameSearch
                        && (dataReg.map((responseData, index) => {
                          return (
                            <Checkbox key={index} style={{ background: '#EBEBEB', borderRadius: '5px' }}
                                      label={responseData}
                                      checked={isChecked(responseData) || false}
                                      onChange={(e) => {
                                let auxAgrup = regiones;
                                auxAgrup = e.currentTarget.checked
                                  ? auxAgrup.concat(responseData)
                                  : auxAgrup.filter((name) =>
                                    name !== responseData);
                                setRegiones(auxAgrup);
                                setCheckedAll(true);
                              }} />
                          );
                        }))
                      }
                    </div>
                    <div className={style.containerSecondLevel}>
                      {regiones && dataLoc && checkedAll && showDep()}
                    </div>
                    <div>
                      {departamentos && (
                        <div className={style.containerSecondLevel}>
                          {dataLoc && showMun()}
                        </div>)
                      }
                    </div>
                  </div>
                </>
              }
              <div className={style.containerRowsLoc}>
                {nameSearch && searchData && showFilters()}
              </div>
            </>
            : <ShowDeptMun valuesLoc={{ departamentos: dataMun }} search={searchData || ''} />
          }
        </div>
      </div>
    </>
  );
};
SelecLocat.propTypes = {
  onChange: PropTypes.func.isRequired,
  existMun: PropTypes.bool,
  dataFilterMun: PropTypes.func,
};
export default SelecLocat;
