/* eslint-disable max-lines */
/* eslint-disable max-len */
/* eslint-disable max-lines */
import React, { useState, useContext, useEffect } from 'react';
import style from './interpretationDocument.module.css';
import { Typography } from '@rmwc/typography';
import OpenaiConsultAPI from '../../api/OpenaiConsultAPI';
import OpenaiStorage from '../../api/openaiStorage';
import Loader from '../loader/Loader';
import NotificationContext from '../../contexts/notification-context';
import { ERROR } from '../../utils/Messages';

const InterpretationDocument = () => {
  const { dispatchData } = useContext(NotificationContext);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileScan, setSelectedFileScan] = useState(null);
  const [responseText, setResponseText] = useState(null);
  const [freeQuestionResponse, setFreeQuestionResponse] = useState(null);
  const [stateButton, setStateButton] = useState(false);
  const [stateLoadPdf, setStateLoadPdf] = useState(false);
  const [texfieldText, setTextfieldText] = useState('');
  const [loadedPdfs, setLoadedPdfs] = useState([]);
  const titulos = [
    'r1. Códigos de la ONU (UNSPSC)',
    'r2. Tipo de organizaciones habilitadas para participación',
    'r3. Objeto de la licitación',
    'r4. Tipo de convocatoria',
    'r5. Forma de pago',
    'r6. Cronograma del proceso',
    'r7. Nombre de la entidad solicitante',
    'r8. Adjudicación',
    'r9. Plazo de ejecución',
    'r10. Causales de rechazo',
    'r11. Requisitos habilitantes jurídicos',
    'r12. Documentos habilitantes financieros y organizacionales',
    'r13. Documentos habilitantes requisitos técnicos',
    'r14. Requisitos experiencia general y específica',
    'r15. Capacidad financiera',
    'r16. Capacidad organizacional',
    'r17. Requerimientos de certificaciones',
    'r18. Criterios de evaluación',
  ];

  useEffect(() => {
    fetchLoadedPdfs();
  }, []);

  const handleFileChange = async (e, typeFile) => {
    resetState();
    if (typeFile === 'scan') {
      setSelectedFileScan(e.target.files[0]);
    } else {
      setSelectedFile(e.target.files[0]);
    }
    setResponseText(null);
    setFreeQuestionResponse(null);
  };
  const resetState = () => {
    setSelectedFile(null);
    setResponseText(null);
    setFreeQuestionResponse(null);
    setStateButton(false);
    setTextfieldText('');
    setLoadedPdfs([]);
  };

  const fetchLoadedPdfs = async () => {
    try {
      const response = await OpenaiConsultAPI.getLoadedPdfs();
      setLoadedPdfs(response.listado);
    } catch (error) {
      dispatchData({ text: ERROR.GENERAL_ERROR, error });
    }
  };

  const onChangeFile = async () => {
    return await readFile();
  };

  const readFile = async () => {
    const buffer = await readBuffer();
    const uint8View = new Uint8Array(buffer);
    return { fileName: selectedFile.name, fileBuffer: Buffer.from(uint8View) };
  };

  const readBuffer = async () => new Promise(
    (resolve, reject) => {
      const readerBuffer = new FileReader();
      readerBuffer.onload = () => {
        resolve(readerBuffer.result);
      };
      readerBuffer.onerror = reject;
      readerBuffer.readAsArrayBuffer(selectedFile);
    }
  );

  const sendData = async () => {
    try {
      setStateLoadPdf(false);
      const data = await onChangeFile();
      await OpenaiStorage.createItemInDb(data);
    } catch (error) {
      setStateLoadPdf(false);
      dispatchData({ text: ERROR.INSERT_CSTORAGE_FAIL, error });
    }
  };

  const handleConsultResponses = async () => {
    if (selectedFile || selectedFileScan) {
      if (typeof (selectedFile) !== 'string' || typeof (selectedFileScan) !== 'string') {
        setResponseText(null);
        setFreeQuestionResponse(null);
        const formData = new FormData();
        formData.append('file', selectedFile || selectedFileScan);
        try {
          let responseOne;
          if (selectedFile) {
            setStateLoadPdf(true);
            responseOne = await OpenaiConsultAPI.responsesUploadedFile(formData);
            setResponseText(JSON.stringify(responseOne, null, 2));
            fetchLoadedPdfs();
          }
          if (selectedFileScan) {
            setStateLoadPdf(true);
            responseOne = await OpenaiConsultAPI.responsesUploadedFileScanned(formData);
           setResponseText(JSON.stringify(responseOne, null, 2));
           fetchLoadedPdfs();
          }

          if ((selectedFile || selectedFileScan) && responseOne && !responseOne.error) {
            await sendData();
          }
          if (responseOne.error) {
            setSelectedFile(null);
            setStateLoadPdf(false);
          }
        } catch (error) {
          dispatchData({ text: ERROR.GENERAL_ERROR, error });
        }
      } else {
        try {
          setResponseText(null);
          setFreeQuestionResponse(null);
          const pdfName = { pdf_name: selectedFile };
          const response = await OpenaiConsultAPI.getResponsesExistingFile(pdfName);
          setResponseText(JSON.stringify(response, null, 2));
        } catch (error) {
          dispatchData({ text: ERROR.GENERAL_ERROR, error });
        }
      }
    }
  };

  const handleFreeQuestion = async () => {
    setStateButton(true);
      if (selectedFile && texfieldText) {
      try {
        const pdfName = typeof selectedFile !== 'string'
        ? selectedFile.name
        : selectedFile;
        const questionData = {
          pdf_request: { pdf_name: pdfName },
          queryu: texfieldText,
        };
        const response = await OpenaiConsultAPI.getResponseFreeQuestion(questionData);
        setFreeQuestionResponse(response.respuesta);
        if (response.respuesta !== null) {
          setStateButton(false);
        }
      } catch (error) {
        dispatchData({ text: ERROR.GENERAL_ERROR, error });
      }
    }
  };

  return (
    <div className={style.containerGeneral}>
      {stateLoadPdf === false
      ? <div className={style.containerSecond}>
        <div className={style.containerModule}>
          <div className={style.contentSection}>
            <Typography use={'headline2'} className={style.title}>
              Extracción de información de licitaciones en PDF
            </Typography>
            <div className={style.selectContainer}>
              <Typography use={'headline2'} className={style.title}>
                Consulta de PDFs anteriores
              </Typography>
              <label htmlFor={'interpretationType'} className={style.selectLabel}>
                Desea ver la respuestas de un archivo previamente cargado?
              </label>
              <select
              id={'interpretationType'}
              className={style.select}
              value={selectedFile && selectedFileScan}
              onChange={(e) => {
                  setSelectedFile(e.target.value);
                setSelectedFileScan(e.target.value);
}}
            >
                <option value={'default'} className={style.selectOption}>
                  Seleccionar documento...
                </option>
                {loadedPdfs.map((pdfName, index) => (
                  <option key={index} value={pdfName} className={style.selectOption}>
                    {pdfName}
                  </option>
              ))}
              </select>
              <button
                className={'btn-primary'}
                style={{ width: '100%', marginTop: '20px' }}
                onClick={handleConsultResponses}
              >
                Consultar Preguntas
              </button>
            </div>
            {(responseText) && (
              <div className={style.responseSection}>
                <Typography use={'headline5'} className={style.responseTitle}>
                  Respuestas de la API:
                </Typography>
                {responseText.length > 0
               ? <div className={style.responseList}>
                 {Object.entries(JSON.parse(responseText)).map(([key, value], index) => (
                   <div key={key} className={style.responseItem}>
                     <Typography use={'headline6'} className={style.question}>
                       {titulos[index]}
                     </Typography>
                     <pre className={style.responsePre}>{value}</pre>
                   </div>
               ))}
               </div>
              : <Loader/>}
              </div>
          )}
          </div>
          <div className={style.textfieldContainer}>
            <div className={style.message}>Pregunta del texto consultado:</div>
            <input
            type={'text'}
            className={style.textfield}
            onChange={(e) => setTextfieldText(e.target.value)}
          />
          </div>
          <button
          className={'btn-primary'}
          style={{ width: '100%', margin: '25px' }}
          onClick={ handleFreeQuestion }
        >
            Resolver Preguntas
          </button>

          {(!stateButton)
        ? freeQuestionResponse && <div className={style.responseItem}>
          <Typography use={'headline6'} className={style.question}>
            Respuesta a la pregunta libre:
          </Typography>
          <pre className={style.responsePre}>{freeQuestionResponse}</pre>
        </div>
        : <Loader/>}
          <Typography use={'headline2'} className={style.title}>
            Cargar PDF a ser leido
          </Typography>
          <div className={style.fileInputContainer}>
            <label htmlFor={'fileInput'} className={style.label}>
              Seleccione el archivo PDF a leer:
            </label>
            <div className={style.inputContainer}>
              <input
              type={'file'}
              id={'fileInput'}
              accept={'.pdf'}
              className={style.fileInput}
              onChange={(e) => handleFileChange(e, '')}
            />
            </div>
          </div>
          {selectedFile && (
            <p className={style.fileSelectedText}>
              Archivo seleccionado: {selectedFile.name ? selectedFile.name : selectedFile}
            </p>
        )}
          <button
                className={'btn-primary'}
                style={{ width: '100%', marginTop: '20px' }}
                onClick={handleConsultResponses}
              >
            Consultar Preguntas
          </button>
          {/* apartado  lectura de documentos scaneados */}
          <Typography use={'headline2'} className={style.title}>
            Cargar pdf scaneado a ser leido
          </Typography>
          <div className={style.fileInputContainer}>
            <label htmlFor={'fileInputScan'} className={style.label}>
              Seleccione el archivo PDF Scaneado a leer :
            </label>
            <div className={style.inputContainer}>
              <input
              type={'file'}
              id={'fileInputScan'}
              accept={'.pdf'}
              className={style.fileInput}
              onChange={(e) => handleFileChange(e, 'scan')}
            />
            </div>
          </div>
          {selectedFileScan && (
            <p className={style.fileSelectedText}>
              Archivo seleccionado: {selectedFileScan.name ? selectedFileScan.name : selectedFileScan}
            </p>
        )}
          <button
                className={'btn-primary'}
                style={{ width: '100%', marginTop: '20px' }}
                onClick={handleConsultResponses}
              >
            Consultar Preguntas
          </button>

        </div>
      </div>
      : <Loader/>}
    </div>
  );
};

export default InterpretationDocument;
