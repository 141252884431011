import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import style from './toolbar.module.css';
import { UserContext } from '../../contexts/user-context';

import userImage from '../../imgs/user avatar.png';
const Toolbar = () => {
  const { currentUser, signOut } = useContext(UserContext);
  const { emailUser, nameUser, picture } = currentUser || {};

  return (
    <>
      {
      emailUser && (
        <header className={style.headerToolbar}>
          <div className={style.headerContainer}>
            <div className={style.profile}>
              <div className={style.imageProfile}>
                <Link to={'/editUser'}>
                  <img src={picture || userImage} alt={'user'} />
                </Link>
              </div>
              <div className={style.profileDescription}>
                <h4 style= {{ margin: '0px' }}>Bienvenido</h4>
                <p style= {{ margin: '0px' }}>
                  {nameUser}
                </p>
              </div>
            </div>
            <button className= {'btn-primary'} onClick={async () => await signOut()}>Salir</button>

          </div>
        </header>
      )
      }
    </>
  );
};

export default Toolbar;
