import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import LoginPopUpContext from '../contexts/popup-context';

const reducer = (data, action) => {
  if (action) {
    return ({
      ...data,
      show: true,
      onclick: action.onclick,
      message: action.message,
      title: action.title,
      oncancel: action.oncancel,
      btnCancel: action.btnCancel,
      buttonLabel: action.buttonLabel,
      onclosed: action.onclosed,
    });
  }
  return { ...data, show: false };
};

const LoginPopUpProvider = ({ children }) => {
  const [data, dispatchData] = useReducer(reducer, {
    show: false,
    onclick: undefined,
    oncancel: undefined,
    onclosed: undefined,
    message: '',
    title: '',
    btnCancel: '',
    buttonLabel: '',
  });
  return (
    <LoginPopUpContext.Provider value={{ data, dispatchData }}>
      {children}
    </LoginPopUpContext.Provider>
  );
};
LoginPopUpProvider.propTypes = { children: PropTypes.node };
export default LoginPopUpProvider;
