import { api, getResponseData, escalateError } from './index';

export default class insertDataBigQuery {
    static async insertBigQuery({
        idOportunity,
        userId,
        nameUser,
    }) {
        return api.post('/api/dataBigquery', { idOportunity, userId, nameUser })
            .then(getResponseData)
            .catch(escalateError);
    }
}
