export const SUCCESSFULL = {
  CREATE_USER: 'Usuario correctamente editado',
  CREATE_FOLDER: 'Carpeta creada exitosamente: ',
  EDIT_FOLDER: 'Carpeta editada exitosamente: ',
  EDIT_NOTIFICATION: 'Notificaciones del usuario correctamente editado',
  DELETE_FOLDER: 'Carpeta inactiva exitosamente',
  ACTIVATE_FOLDER: 'Carpeta activa exitosamente',
  DELETE_OPORTUNITY: 'Oportunidad Eliminada',
  SAVE_OPORTUNITY: 'Oportunidad guardada',
  CLEAR_FILTER: 'Filtros Limpiados',
  LOGIN: '¡Bienvenido!',
  UPDATE_PROFILE: 'Perfil editado exitosamente',
  DELETED_PROFILE: 'Perfil Eliminado Exitosamente',
  UPDATE_DELETED_PROFILE: 'Perfil Restaurado exitosamente',
  DELETE_PARTNET: 'Colaborador eliminado correctamente',
  ACTIVE_PARTNET: 'Colaborador activado correctamente',
  PARTNER_SEARCH: 'Colabores encontrados',
  PASSWORD_EMAIL: 'Se ha enviado un enlace a tu correo electrónico',
  CREATE_USER_BY_ADMIN: 'Usuario Creado Correctamente',
};

export const ERROR = {
  GENERAL_ERROR: 'Error inesperado, intenta mas tarde.',
  UPDATE_ERROR: 'No se pudo realizar la edición',
  UNAUTHORIZED: 'No cuentas con los permisos suficientes',
  CREATE_FOLDER: 'Error creando carpeta, intenta mas tarde',
  CREATE_PARTNER: 'Error creando colaborador, intenta mas tarde',
  SHOW_FOLDER: 'No se pudieron obtener las carpetas',
  OPORTUNITY_EXISTS: 'La oportunidad ya se encuentra almacenada en la carpeta',
  SHOW_PREFERENCE: 'No se pudieron obtener los perfiles',
  SHOW_PARTNER: 'No se pudieron obtener los colaboradores',
  SHOW_OPORTUNITY: 'No se pudo obtener información de la oportunidad',
  WRONG_PASSWORD: 'La contraseña debe tener mas de 6 caracteres',
  ERROR_PASSWORD: 'La contraseña no existe',
  NOT_MATCH_PSWD: 'Las contraseñas no coinciden',
  USER_EXISTS: 'El usuario ya existe',
  USER_NOT_REGISTER: 'Usuario no registrado',
  EMAIL_NOT_REGISTER: 'El correo ingresado no existe',
  INCOMPLETE_FORM: 'Por favor complete el formulario',
  EMPTY_NAME_PROFILE: 'Porfavor ingerese un nombre',
  INVALID_EMAIL: 'El correo electrónico no es válido',
  NO_DATA_POLICY: 'Necesita aceptar la politica de tratamiento de datos',
  GET_ORIGINS: 'No se pudieron obtener lo origenes',
  LOGIN_FAIL: 'Usuario o contraseña equivocada',
  DELETED_ERROR: 'No se pudo realizar la eliminacion',
  USER_DELETE: 'El usuario está bloqueado, contacte al administrador',
  INSERT_CSTORAGE_FAIL: 'Error al almacenar el archivo en cloud storage',
};

export const INFO = {
  REQUIRED_EMAIL: 'Correo electronico requerido',
  REQUIRED_PSWD: 'La contraseña es requerida',
  SESSION_EXPIRED: 'Tu sesión ha expirado, por favor inicia sesión nuevamente',
  SESSION_EXPIRED_TITLE: 'Tu sesión ha expirado',
  CHECK_VALUES: 'Revisa los siguientes campos: ',
  CLEAR_FILTER_EXITS: 'No tiene filtros seleccionados',
  CODID_EMPTY: 'El código ingresado no existe',
  CREATE_COLABORATOR: 'Creando Colaborador',
  SEARCH_PARTNER: 'Buscando colaboradores',
  EMPTY_PARTNER: 'Aun no tienes colaboradores registrados',
};
