import React, { useState, useContext } from 'react';
import style from './CreatedPartner.module.css';
import { Card } from '@rmwc/card';
import { TextField } from '@rmwc/textfield';
import { Checkbox } from '@rmwc/checkbox';
import userPartners from '../../api/showAndCreatedPartners';
import { useHistory } from 'react-router-dom';
import firebase from '../../firebase';
import { Switch } from '@rmwc/switch';
import { UserContext } from '../../contexts/user-context';
import NotificationContext from '../../contexts/notification-context';
import { ERROR, INFO } from '../../utils/Messages';

const CreatedPartnerEnterprise = () => {
    const { currentUser } = useContext(UserContext);
    const { dispatchData } = useContext(NotificationContext);
    const [dataPartner, setDataPartner] = useState({});
    const [dataPassword, setDataPassword] = useState({});
    const [notificationEmail, setNotificationEmail] = useState(false);
    const [notificationTelegram, setNotificationTelegram] = useState(false);
    const [validateCheck, setValidateCheck] = useState({});
    const history = useHistory();
    const auth = firebase.auth();
    const validateData = () => {
        const data = [];
        Object.keys(validateCheck).forEach(responseData =>
            validateCheck[responseData]
                ? data.push(responseData)
                : null
        );
        return data;
    };
    const { password } = dataPassword;
    const validateLenPasww = async () => {
      dispatchData({ text: INFO.CREATE_COLABORATOR });
        const { nameEnterprise, emailUser: lastEditor } = currentUser;
        if (!dataPassword) return dispatchData({ text: ERROR.ERROR_PASSWORD });
        if (password.length < 6) {
            return dispatchData({ text: ERROR.WRONG_PASSWORD });
        }

        const permissions = validateData();
        return userPartners.createUserParners({
          dataPartner,
          nameEnterprise,
          lastEditor,
          permissions,
          notificationEmail,
          notificationTelegram,
        })
            .then(responseDta => {
                const { data } = responseDta;
                auth.createUserWithEmailAndPassword(dataPartner.emailUser, password)
                    .then(responseData => {
                        const { additionalUserInfo } = responseData;
                        const { isNewUser } = additionalUserInfo;
                        if (isNewUser === true) {
                            dispatchData({ text: data.data });
                            history.push('/showpartners');
                            return;
                        }
                        dispatchData({ text: ERROR.USER_EXISTS });
                    })
                    .catch(e => {
                        dispatchData({ text: ERROR.GENERAL_ERROR });
                    });
            })
            .catch(error => dispatchData({ text: ERROR.CREATE_PARTNER, error }));
    };
    const validateCheckPermission = (e) => {
        validateCheck[e.target.name]
            ? setValidateCheck({
                ...validateCheck,
                [e.target.name]: false,
            })
            : setValidateCheck({
                ...validateCheck,
                [e.target.name]: true,
            });
    };
    return (
      <>
        <div className={style.contenedor}>
          <form action={''} className={style.formulario}>
            <div className= {style.formulario__grupo}>
              <label className={style.formulario__label}>Nombre</label>
              <div className={style.formulario__grupo_input}>
                <TextField required name={'nameUser'}
                           onChange={(e) =>
                                    setDataPartner({
                                        ...dataPartner,
                                        [e.target.name]: e.target.value,
                                    })}
                           type={'text'}
                           className={style.formulario__input}
                />
              </div>
            </div>
            <div className= {style.formulario__grupo}>
              <label className={style.formulario__label}>Correo</label>
              <div className={style.formulario__grupo_input}>
                <TextField required name={'emailUser'}
                           onChange={(e) =>
                                  setDataPartner({
                                      ...dataPartner,
                                      [e.target.name]: e.target.value,
                                  })}
                           type={'email'}
                           className={style.formulario__input}
                />
              </div>
            </div>
            <div className= {style.formulario__grupo}>
              <label className={style.formulario__label}>Contraseña</label>
              <div className={style.formulario__grupo_input}>
                <TextField required name={'password'}
                           onChange={(e) =>
                                  setDataPassword({
                                      ...dataPassword,
                                      [e.target.name]: e.target.value,
                                  })}
                           type={'password'}
                           className={style.formulario__input}
                />
              </div>
            </div>
            <div className= {style.formulario__grupo}>
              <label className={style.formulario__label}>Permisos</label>
              <div className={style.formulario__grupo_input}>
                <Checkbox name={'read'}
                          onClick={(e) => validateCheckPermission(e)}
                          className={style.checboxCricle}>Leer</Checkbox>
                <Checkbox name={'update'}
                          onClick={(e) => validateCheckPermission(e)}
                          className={style.checboxCricle}>Actualizar</Checkbox>
                <Checkbox name={'delete'}
                          onClick={(e) => validateCheckPermission(e)}
                          className={style.checboxCricle}>Eliminar</Checkbox>
                <Checkbox name={'created'}
                          onClick={(e) => validateCheckPermission(e)}
                          className={style.checboxCricle}>Crear</Checkbox>
              </div>
            </div>
            <div className= {style.formulario__grupo} style={{ padding: '10px' }}>
              <Switch
                  onClick={(e) => setNotificationEmail(!notificationEmail)}
                          >Envio mensajes por Correo
              </Switch>
            </div>
            <div className= {style.formulario__grupo} style={{ padding: '10px' }}>
              <Switch
                  onClick={(e) => setNotificationTelegram(!notificationTelegram)}
                          >Envio mensajes por telegram
              </Switch>
            </div>
            <div className={style.formulario__grupo}>
              <div className={style.formulario__grupo_btn_enviar}>
                <button className= {style.formulario__btn}
                        onClick={() => validateLenPasww()}>Guardar Cambios</button>
              </div>
            </div>
          </form>
        </div>
      </>
    );
};
export default CreatedPartnerEnterprise;
