/* eslint-disable no-extra-boolean-cast */
/* eslint-disable consistent-return */
import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ProfileContext } from '../../contexts/profile-context';
import { Checkbox } from '@rmwc/checkbox';
import { LocateContext } from '../../contexts/located-context';

const ShowDeptMun = ({ search }) => {
    const [checkMun, setCheckMun] = useState([]);
    const [checkDept, setCheckDept] = useState([]);
    const { dataProfile } = useContext(ProfileContext);
    const [dataDept, setDataDept] = useState([]);
    const [existMunDept, setExistMunDept] = useState(false);
    const [nameSearch, setNameSearch] = useState('');
    const {
        setMunicipality,
        allDataMunDep,
    } = useContext(LocateContext); // conexto get values LOCAT
    useEffect(() => {
        if (Object.keys(allDataMunDep).length > 0 && dataDept.length === 0) {
            setDataDept(allDataMunDep);
            if (dataProfile) {
                const { Departamentos, Municipios } = dataProfile.Lugar;
                Departamentos && Municipios && Departamentos.length > 0
                && Municipios.length > 0 && setExistMunDept(true);
                Departamentos && setCheckDept(Departamentos);
                if (Municipios) {
                    setCheckMun([...Municipios]);
                  }
            }
        }
    }, [allDataMunDep, dataDept]);
    useEffect(() => {
        setNameSearch(search);
    }, [search]);

    useEffect(() => {
        const { Municipios } = dataProfile.Lugar;
        if (Municipios) setMunicipality(checkMun);
    }, [checkMun]);

    const checkMunicipality = (dataObject) => {
        const dataFilter = checkMun
            .filter((data) => {
                const [idFirts] = Object.keys(data);
                const [idSecond] = Object.keys(dataObject);
                return parseInt(idFirts) === parseInt(idSecond);
            });
        const dataReturn = dataFilter.length > 0 || false;
        return dataReturn;
    };

    const showCheckedValues = () => {
        let arrayDataShow;
        if (!existMunDept) {
            if (checkDept.length > 0) {
                arrayDataShow = checkDept.flat(Infinity);
            } else {
                const arrayNewData = [];
                Object.values(dataDept).flat(Infinity)
                    .map(({ codMun }) => {
                    return checkMun.map((data) => {
                        const [codId] = Object.keys(data);
                        if (parseInt(codMun) === parseInt((codId))) {
                            arrayNewData.push(data);
                        };
                        return null;
                    });
                });
                arrayDataShow = arrayNewData.flat(Infinity);
            }
        }
        return arrayDataShow.map((responseName, index) => {
            const [idMuncipality] = Object.keys(responseName);
            const [nameMunicipality] = Object.values(responseName);
            const checkedValue = checkMun.length > 0
                ? checkMunicipality(responseName)
                : checkDept.includes(responseName);
            if (checkedValue) {
                return <Checkbox key={index}
                                 style={{ background: '#EBEBEB', borderRadius: '5px' }}
                                 label={[Object.values(responseName)]}
                                 checked={checkedValue || false}
                                 onChange={ (e) => {
                                    let auxAgrup = checkMun;
                                        auxAgrup = !!e.currentTarget.checked
                                          ? auxAgrup.concat({ [idMuncipality]: nameMunicipality })
                                          : auxAgrup.map((data, index) => {
                                            const [codId] = Object.keys(data);
                                            const [valueName] = Object.values(data);
                                            const validateData = codId !== idMuncipality;
                                            if (validateData) {
                                                return { [codId]: valueName };
                                            };
                                        });
                                        auxAgrup = auxAgrup.filter((data) => data);
                                        setCheckMun(auxAgrup);
                                 }
                                 } />;
            }
        });
    };

    const showOtherData = () => {
        let arrayDataShow;
        if (!existMunDept) {
            arrayDataShow = checkDept.length > 0
                ? Object.keys(dataDept).flat(Infinity)
                : Object.values(dataDept).flat(Infinity);
            arrayDataShow = arrayDataShow.filter(data => data);
        }

        if (nameSearch && nameSearch.length > 0) {
            arrayDataShow = arrayDataShow.filter(({ nameMun }) => {
                if (nameMun && nameSearch) {
                    const validate = nameMun.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
                        .toLowerCase()
                        .search(nameSearch.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
                        .toLowerCase());
                return validate !== -1;
                }
            });
        }
        return arrayDataShow.map((responseName, index) => {
            const validateData = checkDept.length > 0
                ? checkDept.includes(responseName)
                : checkMunicipality(responseName);
          if (!validateData && (((index + (checkMun || checkDept).length) - 25) <= 0)) {
            const { nameMun, codMun: codIdM } = responseName;
            const existData = checkMunicipality(responseName);
            if (!existData) {
                return <Checkbox key={index + codIdM}
                                 style={{ background: '#EBEBEB', borderRadius: '5px' }}
                                 label={responseName.nameMun || responseName}
                                 checked={checkMun.length > 0
                                    ? checkMunicipality(responseName)
                                    : checkDept.includes(responseName)}
                                 onChange={ (e) => {
                                    let auxAgrup = checkMun;
                                        auxAgrup = e.currentTarget.checked
                                          ? auxAgrup.concat({ [codIdM]: nameMun })
                                          : auxAgrup.map((data) => {
                                            const [codId] = Object.keys(data);
                                            if (codId !== codIdM) return { [codId]: nameMun };
                                        });
                                        auxAgrup = auxAgrup.filter((data) => data);
                                        setCheckMun(auxAgrup);
                                 }
                                 }/>;
            }
          }
        });
    };

    return (
      <>
        {(checkMun.length > 0 || checkDept.length > 0) && showCheckedValues()}
        {checkMun.length < 15 && showOtherData()}
      </>
    );
};
ShowDeptMun.propTypes = {
    valuesLoc: PropTypes.object.isRequired,
    onChange: PropTypes.func,
    search: PropTypes.string,
};
export default ShowDeptMun;
