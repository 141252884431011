import { api, escalateError, getResponseData } from './index';
// Comentarios
export default class FoldersActions {
    static async postNameFolders(userId, nameFolder, nameEnterprise, emailUser) {
        return api.post('/api/folders', {
            userId,
            nameFolder,
            nameEnterprise,
            emailUser,
        })
            .then(getResponseData)
            .catch(escalateError);
    }

    static async getNamesFolder(body) {
        return api.post('/api/folders/showFolders', body)
            .then(getResponseData)
            .catch(escalateError);
    }

    static async getDatabyFolder(Folder, nameEnt, userId) {
        const complementQuery = `folderId=${Folder}&useremail=${userId}`;
        const complementQueryTwo = `nameEnterprise=${nameEnt}`;
        return api.get(`/api/folders/process?${complementQuery}&${complementQueryTwo}`)
            .then(getResponseData)
            .catch(escalateError);
    }

    static async postAddProcessInFolder(idProcess, nameFolder, userId, nameEnterprise) {
        return api.post('/api/folders/add', {
            idProcess,
            folderId: nameFolder,
            userId,
            nameEnterprise,
        })
            .then(getResponseData)
            .catch(escalateError);
    };

    static async postEditFolder({ userId, folderId, nameEnterprise, emailUser, nameFolder }) {
        return api.post('/api/folders/editFolder', {
            userId,
            folderId,
            nameEnterprise,
            emailUser,
            nameFolder,
        })
            .then(getResponseData)
            .catch(escalateError);
    };

    static async postDeleteFolder({
        userId,
        folderId,
        nameEnterprise,
        emailUser,
        rol,
        deleted,
    }) {
        return api.post('/api/folders/deleteFolder', {
            userId,
            folderId,
            nameEnterprise,
            emailUser,
            rol,
            deleted,
        })
            .then(getResponseData)
            .catch(escalateError);
    };

    static async postDeleteOportunityOfFolder({
        idProcess, folderId, userId, nameEnterprise,
    }) {
        return api.post('/api/folders/deleteOportunitiFolder', {
            idProcess,
            folderId,
            userId,
            nameEnterprise,
        })
            .then(getResponseData)
            .catch(escalateError);
    };
}
