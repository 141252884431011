/* eslint-disable max-lines */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import style from './dataPolicy.module.css';
import { Checkbox } from '@rmwc/checkbox';
import { Dialog, DialogTitle, DialogContent } from '@rmwc/dialog';

const DataPolicy = ({ onChange, openDialog }) => {
  const [authData, setAuthData] = useState(false);
  const [getOpenDialog, setGetOpenDialog] = useState(openDialog);
  useEffect(() => {
    onChange(authData);
  }, [authData]);

  return (
    <>
      <Dialog open={getOpenDialog} onClose={() => setGetOpenDialog(false)}
            >
        <DialogTitle>
          <h1 className={style.titlePolicy}>POLÍTICA DE TRATAMIENTO DE DATOS Y MANUAL INTERNO
            PARA GARANTIZAR EL CUMPLIMIENTO DE LA LEY ESTATUTARIA SOBRE LA PROTECCIÓN DE
            DATOS PERSONALES.
          </h1>
        </DialogTitle>
        <DialogContent>
          <h2 className={style.subtitlePolicy}>1.Identificación </h2>
          <p className={style.textPolicy}>BD GUIDANCE S.A.S.(en adelante BD GUIDANCE),
            es una sociedad debidamente constituida,
            bajo los parámetros legales de Colombia, identificada con NIT 900.657.897-4,
            sociedad cuyo objeto social es el desarrollo de sistemas informáticos
            (planificación, análisis, diseño, programación y pruebas) y otras actividades de
            tecnologías de información y actividades de servicios informáticos, entrenamiento,
            capacitación empresarial y tecnológico, y otros tipos de educación.
          </p>
          <p className={style.textPolicy}>
            BD GUIDANCE se encuentra ubicada en la calle 72 BIS # 6
            – 44 oficina 501 Edificio APA de Bogotá y cuyo correo
            electrónico para todos los efectos emanados de la presente
            política de tratamiento de datos es manuel.saavedra@bdguidance.com
            y la línea de atención 3005979907.
          </p>
          <h2 className={style.subtitlePolicy}>2.Disposiciones generales</h2>
          <p className={style.textPolicy}>De conformidad con lo dispuesto en el artículo 15 de
            la Constitución Política Colombiana, el cual consagra el derecho de cualquier
            persona de conocer, actualizar y rectificar sus datos personales obrantes en bases
            de datos o archivos de entidades de carácter público y privado. Así como el deber
            de respetar los derechos y garantías de los terceros, de los cuales se tenga
            información personal recolectada, tratada y circulada.
          </p>
          <p className={style.textPolicy}>
            Acorde a lo establecido en la Ley estatutaria 1581 del 17 de octubre de 2012, “Por
            la cual se dictan disposiciones generales para la protección de datos personales”,
            mediante la cual se establecieron los parámetros mínimos para el tratamiento
            legítimo de los datos personales de empleados, clientes y toda persona natural,
            teniendo en cuenta que el artículo 17 en su literal k y el 18 literal f establecen
            de quienes están encargados del tratamiento de los datos personales, “adoptar un
            manual que es deber interno de políticas y procedimientos para garantizar el
            adecuado cumplimiento de la presente ley y en especial, para la atención de
            consultas y reclamos”.
          </p>
          <p className={style.textPolicy}> De igual manera, el artículo 25 de la misma Ley
            Estatutaria indica que las políticas y manuales de tratamientos de datos son de
            obligatorio cumplimiento, que su desconocimiento acarreará sanciones y que dichas
            políticas deben garantizar el tratamiento de conformidad a la Ley 1581 de 2012.
          </p>
          <p className={style.textPolicy}>Por otro lado, es preciso resaltar que el capítulo
            III del Decreto 1377 del 27 de junio de 2013 reglamenta algunos aspectos
            relacionados con el contenido y requisitos de las Políticas de Tratamiento de
            Información y los Avisos de Privacidad.
          </p>
          <p className={style.textPolicy}>BD GUIDANCE se encuentra comprometida con la
            protección de la información de carácter privada y confidencial de sus clientes,
            empleados, contratantes, contratistas y terceros en general, que es obtenida dentro
            del giro ordinario de sus negocios en desarrollo de su respectivo objeto social.
            Por eso, adopta la siguiente política de tratamiento de datos y manual interno para
            garantizar el cumplimiento de la ley estatutaria sobre la protección de datos
            personales de obligatoria aplicación en todas las actividades que involucre el
            tratamiento de estos.
          </p>
          <h2 className={style.subtitlePolicy}>3.Obligatoriedad</h2>
          <p className={style.textPolicy}> Esta política será de obligatorio cumplimiento para
            BD GUIDANCE, sus empleados, colaboradores, contratistas y terceros en función de BD
            GUIDANCE.
          </p>
          <p className={style.textPolicy}>Todos los colaboradores y empleados deben observar y
            conocer estas políticas en desarrollo de sus funciones. En los casos en que la
            vinculación no sea directamente laboral con BD GUIDANCE, se incluirá una cláusula
            contractual para obligando al cumplimiento de las políticas.
          </p>
          <p className={style.textPolicy}>El incumplimiento de las políticas acarreará
            sanciones de tipo laboral para los empleados, de responsabilidad contractual
            para los contratistas; sin perjuicio del deber de responder patrimonialmente
            por los daños y perjuicios que se causen a los titulares de los datos o a BD
            GUIDANCE por dicho incumplimiento o por el indebido tratamiento de los datos
            personales.
          </p>
          <p className={style.textPolicy}>Para todos los efectos pertinentes, este documento
            hará las veces del manual interno de políticas y manual de procedimientos.
          </p>
          <p className={style.textPolicy}>Se debe señalar que, al suministrar, entregar o
            enviar cualquier tipo de información personal a la empresa, el titular de los datos
            personales  acepta que dicha información va a ser utilizada de acuerdo con la
            presente política para el Tratamiento de los Datos Personales.
          </p>
          <h2 className={style.subtitlePolicy}>4.Marco normativo</h2>
          <p className={style.textPolicy}>Con el propósito de dar cumplimiento a las
            disposiciones legales y jurisprudenciales, BD GUIDANCE adopta la normatividad
            vigente que articula y regula las disposiciones básicas de obligatorio cumplimiento
            en termino de tratamiento de datos personales:
          </p>
          <ul>
            <li className={style.textPolicy}> Artículo 15 de la Constitución Política Colombiana de
              1991 que pregona: “(…) Todas las personas tienen derecho a su intimidad personal y
              familiar y a su buen nombre, y el Estado debe respetarlos y hacerlos respetar.
              De igual modo, tienen derecho a conocer, actualizar y rectificar las informaciones
              que se hayan recogido sobre ellas en bancos de datos y en archivos de entidades
              públicas y privadas. En la recolección, tratamiento y circulación de datos se
              respetarán la libertad y demás garantías consagradas en la Constitución (…)”.
            </li>
            <li className={style.textPolicy}> Ley 1266 de 2008: “Por la cual se dictan las
              disposiciones generales del hábeas data y se regula el manejo de la información
              contenida en bases de datos personales, en especial la financiera, crediticia,
              comercial, de servicios y la proveniente de terceros países y se dictan otras
              disposiciones”.
            </li>
            <li className={style.textPolicy}> Decreto 1727 de 2009: “por el cual se determina la
              forma en la cual los operadores de los bancos de datos de información financiera,
              crediticia, comercial, de servicios y la proveniente de terceros países, deben
              presentar la información de los titulares de la información”.
            </li>
            <li className={style.textPolicy}> Decreto 2952 de 2010: “Por el cual se reglamentan los
              artículos 12 y 13 de la Ley 1266 de 2008”.
            </li>
            <li className={style.textPolicy}> Ley 1581 de 2012: “Por la cual se dictan
              disposiciones generales para la protección de datos personales”.
            </li>
            <li className={style.textPolicy}> Decreto 1377 de 2013: “»Por el cual se reglamenta
              parcialmente la Ley 1581 de 2012″.
            </li>
            <li className={style.textPolicy}> Decreto 886 de 2014. “»Por el cual se reglamenta el
              artículo 25 de la Ley 1581 de 2012, relativo al Registro Nacional de Bases de Datos”.
            </li>
            <li className={style.textPolicy}> Decreto 1074 de 2015. “Por medio del cual se expide
              el Decreto Único Reglamentario del Sector Comercio, Industria y Turismo”.
            </li>
          </ul>
          <h2 className={style.subtitlePolicy}>5.Definiciones</h2>
          <p className={style.textPolicy}>Para todos los efectos pertinentes y la correcta
            interpretación de la presente política se entenderá conforme a lo dispuesto en Ley
            Estatutaria 1266 de 2008, Ley Estatutaria 1581 de 2012, por:
          </p>
          <ul>
            <p className={style.textPolicy}> <strong>1.Titular de la información.</strong> Es la
              persona natural o jurídica a quien se refiere la información que reposa en un banco de
              datos y sujeto del derecho de hábeas data y demás derechos y garantías a que se
              refiere la presente política y la ley;
            </p>
            <p className={style.textPolicy}> <strong>2.Fuente de información..</strong> Es la
              persona, entidad u organización que recibe o conoce datos personales de los titulares
              de la información, en virtud de una relación comercial o de servicio o de cualquier
              otra índole y que, en razón de autorización legal o del titular, suministra esos datos
              a un operador de información, el que a su vez los entregará al usuario final.
              Si la fuente entrega la información directamente a los usuarios y no, a través de un
              operador, aquella tendrá la doble condición de fuente y operador y asumirá los deberes
              y responsabilidades de ambos. La fuente de la información responde por la calidad de
              los datos suministrados al operador la cual, en cuanto tiene acceso y suministra
              información personal de terceros, se sujeta al cumplimiento de los deberes y
              responsabilidades previstas para garantizar la protección de los derechos del titular
              de los datos;
            </p>
            <p className={style.textPolicy}> <strong>3.Operador de información.</strong> Se
              denomina operador de información a la persona, entidad u organización que recibe de la
              fuente datos personales sobre varios titulares de la información, los administra y los
              pone en conocimiento de los usuarios bajo los parámetros de la presente política y la
              ley. Por tanto, el operador, en cuanto tiene acceso a información personal de
              terceros, se sujeta al cumplimiento de los deberes y responsabilidades previstos
              para garantizar la protección de los derechos del titular de los datos. Salvo que
              el operador sea la misma fuente de la información, este no tiene relación comercial o
              de servicio con el titular y por ende no es responsable por la calidad de los datos
              que le sean suministrados por la fuente.
            </p>
            <p className={style.textPolicy}>4.El usuario es la persona natural o jurídica que, en
              los términos y circunstancias previstos en la presente política y la ley, puede
              acceder a información personal de uno o varios titulares de la información
              suministrada por el operador o por la fuente, o directamente por el titular de la
              información. El usuario, en cuanto tiene acceso a información personal de terceros,
              se sujeta al cumplimiento de los deberes y responsabilidades previstos para
              garantizar la protección de los derechos del titular de los datos. En el caso en que
              el usuario a su vez entregue la información directamente a un operador, aquella tendrá
              la doble condición de usuario y fuente, y asumirá los deberes y responsabilidades de
              ambos;
            </p>
            <p className={style.textPolicy}> <strong>5. Dato personal.</strong> Es cualquier pieza
              de información vinculada a una o varias personas determinadas o determinables o que
              puedan asociarse con una persona natural o jurídica. Los datos impersonales no se
              sujetan al régimen de protección de datos personales. Cuando en la ley se haga
              referencia a un dato, se presume que se trata de uso personal. Los datos personales
              pueden ser públicos, semiprivados o privados;
            </p>
            <p className={style.textPolicy}> <strong>6.Dato público.</strong> Es el dato calificado
              como tal según los mandatos de la ley o de la Constitución Política y todos aquellos
              que no sean semiprivados o privados, de conformidad con la presente política y la
              ley. Son públicos, entre otros, los datos contenidos en documentos públicos,
              sentencias judiciales debidamente ejecutoriadas que no estén sometidos a reserva y
              los relativos al estado civil de las personas;
            </p>
            <p className={style.textPolicy}> <strong>7.Dato semiprivado.</strong> Es semiprivado el
              dato que no tiene naturaleza íntima, reservada, ni pública y cuyo conocimiento o
              divulgación puede interesar no sólo a su titular sino a cierto sector o grupo de
              personas o a la sociedad en general, como el dato financiero y crediticio de actividad
              comercial o de servicios a que se refiere el Título IV de la ley 1581 de 2012;
            </p>
            <p className={style.textPolicy}> <strong>8.Dato privado.</strong> Es el dato que por su
              naturaleza íntima o reservada sólo es relevante para el titular;
            </p>
            <p className={style.textPolicy}> <strong>9.Agencia de Información Comercial.</strong>
              Es toda empresa legalmente constituida que tenga como actividad principal la
              recolección, validación y procesamiento de información comercial sobre las empresas
              y comerciantes específicamente solicitadas por sus clientes, entendiéndose por
              información comercial aquella información histórica y actual relativa a la situación
              financiera, patrimonial, de mercado, administrativa, operativa, sobre el
              cumplimiento de obligaciones y demás información relevante para analizar la
              situación integral de una empresa. Para los efectos de la presente política, las
              agencias de información comercial son operadores de información y fuentes de
              información;
            </p>
            <p className={style.textPolicy}> <strong>10.Información financiera, crediticia,
              comercial, de servicios y la proveniente de terceros países.</strong> Para todos los
              efectos de la política, se entenderá por información financiera, crediticia,
              comercial, de servicios y la proveniente de terceros países, aquella referida al
              nacimiento, ejecución y extinción de obligaciones dinerarias, independientemente
              de la naturaleza del contrato que les dé origen;
            </p>
            <p className={style.textPolicy}> <strong>11.Autorización.</strong> Consentimiento
              previo, expreso e informado del Titular para llevar a cabo el Tratamiento de datos
              personales;
            </p>
            <p className={style.textPolicy}> <strong>12.Base de Datos.</strong> Conjunto organizado
              de datos personales que sea objeto de Tratamiento;
            </p>
            <p className={style.textPolicy}> <strong>13.Dato personal.</strong> Cualquier
              información vinculada o que pueda asociarse a una o varias personas naturales
              determinadas o determinables;
            </p>
            <p className={style.textPolicy}> <strong>14.Encargado del Tratamiento.</strong> Persona
              natural o jurídica, pública o privada, que por sí misma o en asocio con otros, realice
              el Tratamiento de datos personales por cuenta del responsable del Tratamiento;
            </p>
            <p className={style.textPolicy}> <strong>15.Responsable del Tratamiento.</strong>
              Persona natural o jurídica, pública o privada, que por sí misma o en asocio con otros,
              decida sobre la base de datos y/o el Tratamiento de los datos;
            </p>
            <p className={style.textPolicy}> <strong>16.Titular.</strong> Persona natural cuyos
              datos personales sean objeto de Tratamiento;
            </p>
            <p className={style.textPolicy}> <strong>17.Tratamiento.</strong> Cualquier operación
              o conjunto de operaciones sobre datos personales, tales como la recolección,
              almacenamiento, uso, circulación o supresión.
            </p>
            <p className={style.textPolicy}> <strong>18.Aviso de privacidad.</strong> Comunicación
              verbal o escrita generada por el responsable, dirigida al Titular para el Tratamiento
              de sus datos personales, mediante la cual se le informa acerca de la existencia de las
              políticas de Tratamiento de información que le serán aplicables, la forma de acceder a
              las mismas y las finalidades del Tratamiento que se pretende dar a los datos
              personales.
            </p>
            <p className={style.textPolicy}> <strong>19.Dato público.</strong> Es el dato que no
              sea semiprivado, privado o sensible. Son considerados datos públicos, entre otros, los
              datos relativos al estado civil de las personas, a su profesión u oficio ya su calidad
              de comerciante o de servidor público. Por su naturaleza, los datos públicos pueden
              estar contenidos, entre otros, en registros públicos, documentos públicos, gacetas y
              boletines oficiales y sentencias judiciales debidamente ejecutoriadas que no estén
              sometidas a reserva.
            </p>
            <p className={style.textPolicy}> <strong>20.Datos sensibles.</strong> Se entiende por
              datos sensibles aquellos que afectan la intimidad del Titular o cuyo uso indebido
              puede generar su discriminación, tales como aquellos que revelen el origen racial o
              étnico, la orientación política, las convicciones religiosas o filosóficas, la
              pertenencia a sindicatos, organizaciones sociales, de derechos humanos o que promueva
              intereses de cualquier partido político o que garanticen los derechos y garantías de
              partidos políticos de oposición, así como los datos relativos a la salud, a la vida
              sexual, y los datos biométricos.
            </p>
            <p className={style.textPolicy}> <strong>21.Transferencia.</strong> La transferencia de
              datos tiene lugar cuando el Responsable y/o Encargado del Tratamiento de datos
              personales, ubicado en Colombia, envía la información o los datos personales a un
              receptor, que a su vez es Responsable del Tratamiento y se encuentra dentro o fuera
              del país.
            </p>
            <p className={style.textPolicy}> <strong>22.Transmisión.</strong> Tratamiento de datos
              personales que implica la comunicación de los mismos dentro o fuera del territorio de
              la República de Colombia cuando tenga por objeto la realización de un Tratamiento por
              el Encargado por cuenta del responsable.
            </p>
          </ul>
          <h2 className={style.subtitlePolicy}>6.Principios aplicables</h2>
          <p className={style.textPolicy}>Para el tratamiento de datos personales, BD GUIDANCE,
            apoyado en la normatividad vigente y respetando las directrices mínimas que requiere el
            manejo de la información, garantizará la aplicación armónica e integral de los
            siguientes principios:
          </p>
          <ul>
            <p className={style.textPolicy}> <strong>1.Principio de veracidad o calidad.</strong>
              La información que BD GUIDANCE recolecte y trate debe ser veraz, completa, exacta,
              actualizada, comprobable y comprensible. Está prohibido el registro y divulgación
              de datos parciales, incompletos, fraccionados o que induzcan a error; se adoptaran
              las medidas necesarias y razonables para la actualización, rectificación o supresión
              de los datos, cuando sea procedente.
            </p>
            <p className={style.textPolicy}> <strong>2.Principio de finalidad.</strong>
              El tratamiento de los datos personales debe obedecer a una finalidad legítima de
              acuerdo con la Constitución y la ley, la finalidad debe ser informada al titular de
              la información de manera clara, suficiente, previa o concomitantemente con el
              otorgamiento de la autorización, cuando ella sea necesaria o en general siempre
              que el titular solicite información al respecto; no podrán recopilarse datos sin
              una finalidad específica.
            </p>
            <p className={style.textPolicy}> <strong>3.Principio de circulación restringida.
            </strong>
              La administración de los datos personales estará sujeta a los límites que derivan de
              su naturaleza y a los demás principios en torno al tratamiento de datos personales,
              estos, salvo la información pública, no podrán estar disponibles en Internet u
              otros medios de divulgación o comunicación masiva, salvo que el acceso sea
              técnicamente controlable para brindar un conocimiento restringido sólo a los
              Titulares o terceros autorizados.
            </p>
            <p className={style.textPolicy}>4.BD GUIDANCE solo podrá enviar o suministrar los datos
              personales a las siguientes personas:</p>
            <ul>
              <p className={style.textPolicy}>1. Al titular del dato.</p>
              <p className={style.textPolicy}>2. A las personas autorizadas por el titular del
                dato.</p>
              <p className={style.textPolicy}>3.A las demás personas legitimadas según lo indica
                el artículo 20 del decreto 1377 de 2013.</p>
              <p className={style.textPolicy}>4. A las entidades públicas o administrativas en
                ejercicio de sus funciones legales o por orden judicial. En este último caso, de
                conformidad con la Corte Constitucional, se procederá de la siguiente manera:</p>
              <ul>
                <p className={style.textPolicy}>En primer lugar, la entidad pública o
                  administrativa debe justificar su solicitud indicando el vínculo
                  entre la necesidad de obtener el dato y el cumplimiento de sus
                  funciones constitucionales o legales.</p>
                <p className={style.textPolicy}>En segundo lugar, con la entrega de la
                  información se le informará a la entidad pública o administrativa que debe
                  cumplir los deberes y obligaciones que le impone la ley 1581 de 2012 y sus
                  normas reglamentarias como responsable del tratamiento. La entidad
                  administrativa receptora de los datos personales debe cumplir con las
                  obligaciones de protección y las garantías que se derivan de la citada ley,
                  en especial la observancia de los principios de finalidad, uso legítimo,
                  circulación restringida, confidencialidad y seguridad.</p>
              </ul>
            </ul>
            <p className={style.textPolicy}> <strong>5.Principio de temporalidad de la
              información.</strong>
              La información del titular se conservará únicamente durante un periodo de tiempo
              razonable, el cual dependerá de la finalidad para la cual es recolectada y las
              exigencias legales de las autoridades de vigilancia y control o demás entidades
              competentes. Los datos serán conservados cuando ello sea necesario para el
              cumplimiento de una obligación legal o contractual. El término del tratamiento
              se considerará por las normas aplicables a cada finalidad y los aspectos
              administrativos, contables, fiscales, jurídicos e históricos de la información.
              Una vez cumplida la (las) finalidad(es) se procederá a la supresión de los datos
              y no podrá ser suministrada a usuarios o terceros cuando deje de servir para la
              finalidad del banco de datos.
            </p>
            <p className={style.textPolicy}> <strong>6.Principio de interpretación integral de
              derechos constitucionales.</strong>
              Todos y cada uno de los aspectos que conforman esta política de tratamiento de
              datos, ley se interpretarán en el sentido de que se amparen adecuadamente los
              derechos constitucionales, como son el hábeas data, el derecho al buen nombre,
              el derecho a la honra, el derecho a la intimidad y el derecho a la información.
              Los derechos de los titulares se interpretarán en armonía y en un plano de
              equilibrio con el derecho a la información previsto en el artículo 20de la
              Constitución y con los demás derechos constitucionales aplicables.
            </p>
            <p className={style.textPolicy}> <strong>7.Principio de seguridad.</strong>
              La información sujeta a Tratamiento por parte de BD GUIDANCE, sus
              empleados, colaboradores, contratistas y terceros, se deberá proteger mediante
              el uso de las medidas técnicas, humanas y administrativas que sean necesarias
              para otorgar seguridad a los registros evitando su adulteración, pérdida,
              consulta, uso o acceso no autorizado o fraudulento.
            </p>
            <p className={style.textPolicy}> <strong>8.Principio de confidencialidad.</strong>
              Todas las personas naturales y jurídicas, que no sean de carácter público, que
              intervengan en el Tratamiento de datos personales recolectados por DB GUIDANCE
              están obligadas a garantizar la reserva de la información, inclusive después de
              finalizada su relación con alguna de las labores que comprende el Tratamiento,
              pudiendo sólo realizar suministro o comunicación de datos personales cuando ello
              corresponda al desarrollo de las actividades autorizadas en la ley.
            </p>
            <p className={style.textPolicy}> <strong>9.Principio de legalidad.</strong>
              El Tratamiento de Datos Personales en Colombia es una actividad reglamentada y
              por ende los procesos de negocios y destinatarios de este Manual deben sujetarse
              a lo dispuesto en esta Política.
            </p>
            <p className={style.textPolicy}> <strong>10.Principio de libertad.</strong>
              El Tratamiento sólo puede ejercerse con el consentimiento, previo, expreso
              e informado del Titular. Los datos personales no podrán ser obtenidos o
              divulgados sin previa autorización, o en ausencia de mandato legal o judicial
              que releve el consentimiento.
            </p>
            <p className={style.textPolicy}> <strong>11.Principio de transparencia.</strong>
              En el tratamiento debe garantizarse el derecho del titular a obtener de BD GUIDANCE
              o del encargado del tratamiento, en cualquier momento y sin restricciones,
              información acerca de la existencia de datos que le conciernan.
            </p>
            <p className={style.textPolicy}> <strong>12.Principio de acceso.</strong>
              Sólo se permitirá acceso a los datos personales obtenidos por BD GUIDANCE a las
              siguientes personas:
            </p>
            <ul>
              <p className={style.textPolicy}>Al titular del dato.</p>
              <p className={style.textPolicy}>A las personas autorizadas por el titular del dato</p>
              <p className={style.textPolicy}>A las personas que por mandato legal u orden judicial
                sean autorizadas para conocer la información del titular del dato.</p>
              <p className={style.textPolicy}>A las demás personas legitimadas según lo indica el
                artículo 20 del decreto 1377 de 2013.</p>
            </ul>
            <p className={style.textPolicy}>En todos los casos, antes de dar acceso a los datos se
              debe establecer con certeza y suficiencia la identidad de la persona que solicita
              conocer los datos personales. Los datos personales, salvo la información pública,
              no podrán estar disponibles en internet u otros medios de divulgación o comunicación
              masiva, salvo que el acceso sea técnicamente controlable para brindar un
              conocimiento restringido sólo a los titulares o terceros autorizados conforme
              a la ley y a la presente política.
            </p>
          </ul>
          <h2 className={style.subtitlePolicy}>7.Tratamiento y finalidad del tratamiento de
            datos.</h2>
          <p className={style.textPolicy}>BD GUIDANCE solicitará los datos necesarios para la
            adquisición de servicios e interacción con sus clientes, así como aquella requerida
            por el gobierno para el proceso de facturación y pago. En algunos casos, puede
            solicitar información adicional y sensible la cual es de libre y voluntaria entrega
            por parte del titular del dato.
          </p>
          <p className={style.textPolicy}>Una vez suministrados sus datos personales, de manera
            voluntaria y libre, a través del registro en nuestros portales Web, entre otros, los
            mismos son almacenados en la base de datos pertinente de acuerdo al servicio o producto
            adquirido. Las bases de datos se encuentran detrás de un firewall para mayor seguridad
            y los servidores en los que reposan las bases de datos están protegidos físicamente
            en un lugar seguro. Sólo personal autorizado que ha firmado acuerdos de
            confidencialidad de la información, puede acceder a él y por tanto a los datos
            personales de nuestros clientes y/o usuarios.
          </p>
          <p className={style.textPolicy}>La información que consta y constará en las bases de
            datos de BD GUIDANCE será tratada de conformidad al ordenamiento normativo vigente
            y a los principios en ellos implementados, con la intención de cumplir, de igual manera,
            con las actividades propias de su objeto social, y podrá recolectar, transferir,
            almacenar, usar, circular, suprimir, compartir, actualizar y transmitir la
            información para efectos de cumplir con las siguientes finalidades:
          </p>
          <ul>
            <p className={style.textPolicy}>1.Efectuar las gestiones pertinentes para el desarrollo
              de las actividades que configuran el objeto social de la compañía, con la intención
              de cumplir con los convenios alcanzados con los titulares de la información.
            </p>
            <p className={style.textPolicy}>2.Realizar el registro en las bases de datos de
              BG GUIDANCE de clientes, proveedores, contratistas y terceros.
            </p>
            <p className={style.textPolicy}>3.Suministrar la información a terceros con los cuales
              BD GUIDANCE tenga relación contractual y que sea necesario entregársela para el
              cumplimiento del objeto contratado, quienes estarán obligados a emplear dicha
              información para el cumplimiento del mencionado objeto; en caso tal que BD GUIDANCE
              sea a quien le suministren la información, esta será tratada conforme a la presente
              política.
            </p>
            <p className={style.textPolicy}>4.Transferir la información recolectada a distintas
              áreas de BD GUIDANCE cuando ello sea necesario para ejecutar actividades tales como,
              pero sin limitarse a éstas: recaudo de cartera, cobros administrativos, tesorería,
              contabilidad, área de talento humano, entre otros.
            </p>
            <p className={style.textPolicy}>5.Realizar invitaciones a eventos, presentar
              promociones u ofrecer nuevos productos y servicios.
            </p>
            <p className={style.textPolicy}>6.Validar la información en cumplimiento de la exigencia
              legal de conocimiento del cliente aplicable a BD GUIDANCE.
            </p>
            <p className={style.textPolicy}>7.Mantener constantemente las actividades
              correspondientes al seguimiento de las obligaciones contraídas; en razón a la
              relación civil, comercial y/o de servicios que exista entre BD GUIDANCE y sus
              clientes, proveedores o contratistas.
            </p>
            <p className={style.textPolicy}>8.Enviar o solicitar cotizaciones sobre los servicios
              que ofrece BD GUIDANCE o sobre los procesos de compra y pago de estos.
            </p>
            <p className={style.textPolicy}>9.Procesar pagos y verificar saldos pendientes.
            </p>
            <p className={style.textPolicy}>10.Adelantar las acciones de cobro y de recuperación
              de cartera, en virtud de la naturaleza pública de los recursos colocados en procura
              de hacer sostenible el sistema de BD GUIDANCE.
            </p>
            <p className={style.textPolicy}>11.Verificar referencias comerciales y/o de experiencia
              de contratos comerciales, civiles o laborales suscritos y servicios prestados.
            </p>
            <p className={style.textPolicy}>12.Gestionar solicitudes, quejas y reclamos,
              presentados por el titular de los datos.
            </p>
            <p className={style.textPolicy}>13.Contactar al titular de los datos por medios
              telefónicos para realizar encuestas, estudios y/o confirmación de datos personales
              necesarios para la ejecución del objeto social de BD GUIDANCE.
            </p>
            <p className={style.textPolicy}>14.Contactar al titular de los datos a través de
              medios electrónicos como mensajes de texto o chat para el envío de noticias
              relacionadas con campañas de fidelización o mejora de servicio, promociones o
              demás información que se considere deba conocer.
            </p>
            <p className={style.textPolicy}>15.Contactar al titular de los datos a través de
              correo electrónico para el envío de información, requisitos, avisos de privacidad,
              promociones, extractos, estados de cuenta o facturas en relación con las
              obligaciones derivadas del contrato celebrado entre las partes y demás documentos
              que puedan hacer parte de la información a requerir o entregar.
            </p>
            <p className={style.textPolicy}>16.Prestar los servicios ofrecidos por BD GUIDANCE y
              aceptados en el contrato suscrito.
            </p>
            <p className={style.textPolicy}>17.Analizar mercados y realizar análisis estadísticos.
            </p>
            <p className={style.textPolicy}>18.Realizar encuestas de satisfacción.
            </p>
            <p className={style.textPolicy}>19.Efectuar las gestiones pertinentes para el desarrollo
              de la etapa precontractual, contractual y postcontractual de los titulares de los
              datos con BD GUIDANCE, cualquiera de los contratos en los que hace parte, así como
              dar cumplimiento a la ley colombiana o extranjera y a las órdenes de autoridades
              judiciales o administrativas.
            </p>
            <p className={style.textPolicy}>20.Contactar con proveedores de bienes o servicios. El
              tratamiento de los datos personales de los empleados, contratistas o proveedores de BD
              GUIDANCE, serán recolectados mediante el diligenciamiento de formatos, vía telefónica,
              o con la entrega de documentos (hojas de vida, ofertas de servicio, sus anexos, etc.),
              los cuales serán tratados con fines contractuales y laborales acorde al ordenamiento
              legal según el caso. Respecto de los empleados de BD GUIDANCE, la finalidad de la
              recolección de los datos estará encaminada a:
            </p>
            <ul>
              <li className={style.textPolicy}>Dar cumplimiento a las leyes de derecho laboral,
                seguridad social, pensiones, riesgos profesionales, cajas de compensación familiar
                (Sistema Integral de Seguridad Social) e impuestos y demás normatividad aplicable.
              </li>
              <li className={style.textPolicy}>Cumplir las instrucciones de las autoridades
                judiciales y administrativas competentes.
              </li>
              <li className={style.textPolicy}>Implementar las políticas y estrategias
                laborales y organizacionales.
              </li>
            </ul>
            <p className={style.textPolicy}>Respecto de los contratistas y proveedores de BD
              GUIDANCE, la información se utilizará principalmente para adelantar los procesos
              precontractuales, contractuales y postcontractuales pertinentes y mantener
              comunicación con los mismos para efectos contractuales y legales, la finalidad
              de la recolección de los datos estará encaminada y obedecerá a la recolección
              realizada directamente en los puntos de seguridad, tomados de los documentos
              que suministran las personas al personal de seguridad y los obtenidos de las
              videograbaciones que se realizan dentro o fuera de las instalaciones de BD
              GUIDANCE, éstos se utilizarán para fines de seguridad de las personas, los
              bienes e instalaciones de BD GUIDANCE y podrán ser utilizados como prueba
              en cualquier tipo de proceso.
            </p>
            <p className={style.textPolicy}>Respecto de los accionistas de BD GUIDANCE, la
              finalidad de la recolección de los datos estará encaminada a cumplir los deberes
              legales pertinentes como llevar los libros y papeles comerciales. De igual forma,
              la información se utilizará para efectuar todas las actividades relacionadas
              con los derechos económicos y políticos administrativos de los socios o
              accionistas. Adicionalmente, sus datos se emplearán para mantener comunicación
              con los mismos.
            </p>
            <p className={style.textPolicy}>Sin perjuicio de lo enunciado y fundamentados
              en lo dispuesto en la normatividad vigente sobre la materia, BD GUIDANCE podrá
              suministrar a las autoridades competentes la información que aquellas soliciten.
              En todo caso, BD GUIDANCE velará por el correcto uso de los datos personales
              recolectados a través de cualquier medio (físico o electrónico), garantizando
              el cumplimiento de las exigencias legales.
            </p>
            <p className={style.textPolicy}>Los datos de carácter sensible, de los niños,
              niñas y de los adolescentes se tratarán de conformidad a lo establecido en
              la ley 1581 de 2012, atendiendo estrictamente la autorización del titular,
              respetando su interés y respetando los derechos fundamentales de los que
              son titulares.
            </p>
          </ul>
          <h2 className={style.subtitlePolicy}>8.Derechos de los titulares de la información</h2>
          <p className={style.textPolicy}>Las personas obligadas cumplir estas políticas deben
            respetar y garantizar los siguientes derechos de los titulares de los datos, de
            conformidad con lo establecido en la ley 1581 de 2012:
          </p>
          <ul>
            <p className={style.textPolicy}>1.Conocer, actualizar y rectificar sus datos
              personales frente a la BD GUIDANCE como responsable y encargado del tratamiento.
              Este derecho se podrá ejercer entre otros ante datos parciales, inexactos,
              incompletos, fraccionados, que induzcan a error, o aquellos cuyo Tratamiento
              esté expresamente prohibido o no haya sido autorizado.
            </p>
            <p className={style.textPolicy}>2.Solicitar prueba de la autorización otorgada a la BD
              GUIDANCE como responsable y encargado del tratamiento, salvo cuando expresamente se
              exceptúe como requisito para el tratamiento, de conformidad con lo previsto en el
              artículo 10 de la Ley 1581 de 2012.
            </p>
            <p className={style.textPolicy}>3.Ser informado por BD GUIDANCE como responsable del
              tratamiento y encargado del tratamiento, previa solicitud, respecto del uso que le
              ha dado a los datos personales del Titular.
            </p>
            <p className={style.textPolicy}>4.Presentar ante la Superintendencia de Industria y
              Comercio quejas por infracciones a lo dispuesto en la Ley 1581 de 2012 y las demás
              normas que la modifiquen, adicionen o complementen.
            </p>
            <p className={style.textPolicy}>5.Revocar la autorización y/o solicitar la supresión
              del dato personal cuando en el tratamiento no se respeten los principios, derechos y
              garantías constitucionales y legales. La revocatoria y/o supresión procederá cuando
              la Superintendencia de Industria y Comercio haya determinado que en el tratamiento
              el responsable o encargado han incurrido en conductas contrarias a la Ley 1581 de
              2012 y a la Constitución.
            </p>
            <p className={style.textPolicy}>6.Acceder en forma gratuita a sus datos personales
              que hayan sido objeto de tratamiento.
            </p>
            <p className={style.textPolicy}>7.Abstenerse de responder las preguntas sobre datos
              sensibles. Tendrá carácter facultativo las respuestas que versen sobre datos sensibles
              o sobre datos de las niñas y niños y adolescentes.
            </p>
          </ul>
          <p className={style.textPolicy}>De igual manera, de conformidad a lo dispuesto en el
            artículo 20 del Decreto 1377 de 2013, las personas legitimadas para ejercer los
            derechos en materia de tratamientos de datos son:
          </p>
          <ul>
            <p className={style.textPolicy}>1.El Titular, quien deberá acreditar su identidad en
              forma suficiente por los distintos medios que le ponga a disposición de BD GUIDANCE.
            </p>
            <p className={style.textPolicy}>2.Los causahabientes, quienes deberán acreditar tal
              calidad.
            </p>
            <p className={style.textPolicy}>3.El representante y/o apoderado del Titular,
              previa acreditación de la representación o apoderamiento.
            </p>
            <p className={style.textPolicy}>4.Por estipulación a favor de otro o para otro.
            </p>
            <p className={style.textPolicy}>5.Los derechos de los niños, niñas o adolescentes
              se ejercerán por las personas que estén facultadas para representarlos.
            </p>
          </ul>
          <h2 className={style.subtitlePolicy}>9.Deberes del responsable del tratamiento.</h2>
          <p className={style.textPolicy}>Cuando BD GUIDANCE obre como responsable del tratamiento
            de datos personales cumplirá con los siguientes deberes, de conformidad con lo
            establecido en la Ley 1581 de 2012:
          </p>
          <ul>
            <p className={style.textPolicy}>1.Garantizar al titular, en todo tiempo, el pleno y
              efectivo ejercicio del derecho de hábeas data.
            </p>
            <p className={style.textPolicy}>2.Solicitar y conservar, en las condiciones previstas
              en la presente política y la ley, copia de la respectiva autorización otorgada
              por el titular.
            </p>
            <p className={style.textPolicy}>3.Informar debidamente al Titular sobre la finalidad
              de la recolección y los derechos que le asisten por virtud de la autorización
              otorgada.

            </p>
            <p className={style.textPolicy}>4.Conservar la información bajo las condiciones
              de seguridad necesarias para impedir su adulteración, pérdida, consulta, uso o
              acceso no autorizado o fraudulento.
            </p>
            <p className={style.textPolicy}>5.Garantizar que la información que se
              suministre al encargado del tratamiento sea veraz, completa, exacta,
              actualizada, comprobable y comprensible.
            </p>
            <p className={style.textPolicy}>6.Actualizar la información, comunicando
              de forma oportuna al encargado del tratamiento, todas las novedades respecto
              de los datos que previamente le haya suministrado y adoptar las demás medidas
              necesarias para que la información suministrada a este se mantenga actualizada.
            </p>
            <p className={style.textPolicy}>7.Rectificar la información cuando sea incorrecta
              y comunicar lo pertinente al encargado del tratamiento.
            </p>
            <p className={style.textPolicy}>8.Suministrar al encargado del tratamiento,
              según el caso, únicamente datos cuyo tratamiento esté previamente autorizado
              de conformidad con lo previsto en la presente política y la ley.
            </p>
            <p className={style.textPolicy}>9.Exigir al encargado del tratamiento en todo momento,
              el respeto a las condiciones de seguridad y privacidad de la información del titular.
            </p>
            <p className={style.textPolicy}>10.Tramitar las consultas y reclamos formulados en los
              términos señalados en la presente política y la ley.
            </p>
            <p className={style.textPolicy}>11.Adoptar un manual interno de políticas y
              procedimientos para garantizar el adecuado cumplimiento de la protección de datos
              y en especial,para la atención de consultas y reclamos.
            </p>
            <p className={style.textPolicy}>12.Informar al encargado del tratamiento cuando
              determinada información se encuentra en discusión por parte del titular, una vez
              se haya presentado la reclamación y no haya finalizado el trámite respectivo.
            </p>
            <p className={style.textPolicy}>13.Informar a solicitud del titular sobre el
              uso dado a sus datos.
            </p>
            <p className={style.textPolicy}>14.Informar a la autoridad de protección de
              datos cuando se presenten violaciones a los códigos de seguridad y existan
              riesgos en la administración de la información de los titulares.
            </p>
            <p className={style.textPolicy}>15.Cumplir las instrucciones y requerimientos
              que imparta la Superintendencia de Industria y Comercio.
            </p>
          </ul>
          <h2 className={style.subtitlePolicy}>10.Deberes del encargado del tratamiento</h2>
          <p className={style.textPolicy}>Cuando BD GUIDANCE obre como encargado del tratamiento
            de datos personales cumplirá con los siguientes deberes, de conformidad con lo
            establecido en la Ley 1581 de 2012:
          </p>
          <ul>
            <p className={style.textPolicy}>1.Garantizar al titular, en todo tiempo, el pleno y
              efectivo ejercicio del derecho de hábeas data.
            </p>
            <p className={style.textPolicy}>2.Conservar la información bajo las condiciones
              de seguridad necesarias para impedir su adulteración, pérdida, consulta, uso o
              acceso no autorizado o fraudulento.
            </p>
            <p className={style.textPolicy}>3.Realizar oportunamente la actualización,
              rectificación o supresión de los datos en los términos de la presente
              política y la ley.

            </p>
            <p className={style.textPolicy}>4.Actualizar la información reportada por
              los responsables del tratamiento dentro de los cinco (5) días hábiles
              contados a partir de su recibo.
            </p>
            <p className={style.textPolicy}>5.Tramitar las consultas y los reclamos
              formulados por los titulares en los términos señalados en la presente
              política y la ley.
            </p>
            <p className={style.textPolicy}>6.Adoptar un manual interno de políticas y
              procedimientos para garantizar el adecuado cumplimiento de la ley de protección
              de datos y, en especial, para la atención de consultas y reclamos por parte de
              los titulares.
            </p>
            <p className={style.textPolicy}>7.Registrar en la base de datos la leyenda
              “reclamo en trámite” en la forma en que se regula en la presente política y
              la ley.
            </p>
            <p className={style.textPolicy}>8.Insertar en la base de datos la leyenda “información
              en discusión judicial” una vez notificado por parte de la autoridad competente sobre
              procesos judiciales relacionados con la calidad del dato personal.
            </p>
            <p className={style.textPolicy}>9.Abstenerse de circular información que esté siendo
              controvertida por el titular y cuyo bloqueo haya sido ordenado por la Superintendencia
              de Industria y Comercio.
            </p>
            <p className={style.textPolicy}>10.Permitir el acceso a la información únicamente
              a las personas que pueden tener acceso a ella.
            </p>
            <p className={style.textPolicy}>11.Informar a la Superintendencia de Industria y
              Comercio cuando se presenten violaciones a los códigos de seguridad y existan riesgos
              en la administración de la información de los titulares.
            </p>
            <p className={style.textPolicy}>12.Cumplir las instrucciones y requerimientos que
              imparta la Superintendencia de Industria y Comercio.
            </p>
          </ul>
          <p className={style.textPolicy}>En el evento en que concurran las calidades de
            responsable del tratamiento y encargado del tratamiento en BD GUIDANCE, le será
            exigible el cumplimiento de los deberes previstos para cada uno.
          </p>
          <h2 className={style.subtitlePolicy}>11.Recolección de los datos personales.</h2>
          <p className={style.textPolicy}>La ley 1581 de 2012 exige a BD GUIDANCE tener
            autorización informada para la recolección de los datos personales bajo las
            siguientes premisas:
          </p>
          <p className={style.textPolicy}>Los obligados a cumplir esta política, deberán
            obtener la autorización previa, expresa e informada para recolectar y tratar sus
            datos personales. Esta obligación no es necesaria cuando se trate de datos de
            naturaleza pública, tratamiento de información para fines históricos, estadísticos
            o científicos en los cuales no se vincule la información a una persona específica
            y datos relacionados con el Registro Civil de las Personas. Para obtener la autorización
            se deberán seguir las siguientes instrucciones:
          </p>
          <p className={style.textPolicy}>En primer lugar, antes de que la persona autorice es
            necesario informarle de forma clara y expresa lo siguiente:
          </p>
          <ul>
            <p className={style.textPolicy}>1.El tratamiento al cual serán sometidos sus
              datos personales y la finalidad del mismo.
            </p>
            <p className={style.textPolicy}>2.El carácter facultativo de la respuesta a las
              preguntas que le sean hechas, cuando estas versen sobre datos sensibles o sobre
              los datos de las niñas, niños y adolescentes.
            </p>
            <p className={style.textPolicy}>3.Los derechos que le asisten como titular previstos
              en el artículo 8 de la ley 1581 de 2012.
            </p>
            <p className={style.textPolicy}>4.La identificación, dirección física o electrónica
              y teléfono de BD GUIDANCE.
            </p>
          </ul>
          <p className={style.textPolicy}>En segundo lugar, obtener la autorización del titular
            para el tratamiento de sus datos personales por cualquiera de los medios establecidos
            por la ley, que deberán constar en cualquier medio idóneo para su posterior consulta. La
            autorización podrá obtenerse por medio escrito u oral, de lo cual deberá prueba, si el
            titular requiere una copia de dicha autorización, esta deberá serle suministrada.
          </p>
          <p className={style.textPolicy}>La autorización también podrá obtenerse a partir de
            conductas inequívocas del Titular del Dato que permitan concluir de manera razonable
            que éste otorgó su consentimiento para el tratamiento de su información. Dicha (s)
            conducta (s) debe (n) ser muy clara (s) de manera que no admita (n) duda o equivocación
            sobre la voluntad de autorizar el tratamiento y en ningún caso el silencio del titular
            podrá considerarse como una conducta inequívoca.
          </p>
          <p className={style.textPolicy}>Los legitimados para otorgar autorización para el
            tratamiento de los datos personales son:
          </p>
          <ul>
            <p className={style.textPolicy}>1.El Titular, quien deberá acreditar su identidad
              en forma suficiente por los distintos medios que le ponga a disposición BD GUIDANCE.
            </p>
            <p className={style.textPolicy}>2.Los causahabientes del Titular, quienes deberán
              acreditar tal calidad.
            </p>
            <p className={style.textPolicy}>3.El (la) representante y/o apoderado (a) del Titular,
              previa acreditación de la representación o apoderamiento.
            </p>
            <p className={style.textPolicy}>4.La autorización también podrá otorgarse cuando se
              den casos de estipulación a favor de otro o para otro.
            </p>
          </ul>
          <p className={style.textPolicy}>Autorización para el tratamiento de datos sensibles
          </p>
          <p className={style.textPolicy}>Cuando se trate de la recolección de datos sensibles se
            deben cumplir los siguientes requisitos:
          </p>
          <ul>
            <p className={style.textPolicy}>1.La autorización debe ser explícita.
            </p>
            <p className={style.textPolicy}>2.Se debe informar al Titular que no está obligado
              a autorizar el tratamiento de dicha información
            </p>
            <p className={style.textPolicy}>3.Se debe informar de forma explícita y previa al
              Titular cuáles de los datos que serán objeto de tratamiento son sensibles y la
              finalidad del mismo.
            </p>
          </ul>
          <p className={style.textPolicy}>Cabe anotar que ninguna actividad podrá condicionarse
            a que el titular suministre datos personales sensibles.
          </p>
          <p className={style.textPolicy}>Autorización de tratamiento de datos de niños,
            niñas y adolescentes (NNA).
          </p>
          <p className={style.textPolicy}>La recolección y tratamiento de datos de niños,
            niñas y adolescentes se deben cumplir los siguientes requisitos:
          </p>
          <ul>
            <p className={style.textPolicy}>1.La autorización debe ser otorgada por personas que
              estén facultadas para representar los NNA. El representante de los NNA deberá
              garantizarles el derecho a ser escuchados y valorar su opinión del tratamiento
              teniendo en cuenta la madurez, autonomía y capacidad de los NNA para entender
              el asunto.
            </p>
            <p className={style.textPolicy}>2.Se debe informar que es facultativo responder
              preguntas sobre datos de los NNA.
            </p>
            <p className={style.textPolicy}>3.El tratamiento debe respetar el interés superior
              de los NNA y asegurar el respeto de sus derechos fundamentales Se debe informar
              de forma explícita y previa al Titular cuáles de los datos que serán objeto de
              tratamiento son sensibles y la finalidad del mismo.
            </p>
          </ul>
          <h2 className={style.subtitlePolicy}>12.Clasificación y tratamiento especial de
            ciertos datos personales.</h2>
          <p className={style.textPolicy}>Las personas obligadas a cumplir esta política
            deberán identificar los datos sensibles y de los niños, niñas y adolescentes
            con miras a:
          </p>
          <ul>
            <li className={style.textPolicy}>
              Implementar responsabilidad reforzada en el tratamiento de estos datos que se traduce
              en una exigencia mayor en términos de cumplimiento de los principios y los deberes.
            </li>
            <li className={style.textPolicy}>
              Aumentar los niveles de seguridad de esa información.
            </li>
            <li className={style.textPolicy}>
              Incrementar las restricciones de acceso y uso por parte del personal de BD GUIDANCE
              y de terceros.
            </li>
            <li className={style.textPolicy}>
              Tener presente los requisitos legales y de esta política para su recolección.
            </li>
          </ul>
          <h2 className={style.subtitlePolicy}>13.Transferencia internacional de datos
            personales.</h2>
          <p className={style.textPolicy}>Cuando se envíen o transfieran datos a otro país
            será necesario contar con la autorización del titular de la información que es objeto
            de transferencia. Salvo que la ley diga lo contrario, dicha autorización es
            prerrequisito para efectuar la circulación internacional de datos. En este sentido,
            antes de enviar datos personales a responsables del Tratamiento ubicados en otro país,
            los obligados de cumplir esta política deberán verificar que se cuenta con la
            autorización previa, expresa e inequívoca del titular que permita trasmitir
            sus datos personales.
          </p>
          <h2 className={style.subtitlePolicy}>14.Transmisiones internacionales y nacionales
            de datos a Encargados.
            personales.</h2>
          <p className={style.textPolicy}>Cuando BD GUIDANCE desee enviar o transmitir datos a uno
            o varios encargados ubicados dentro fuera del territorio de la República de Colombia,
            deberá mediante cláusulas contractuales o a través de un contrato de transmisión de
            datos personales en el que, entre otros, se pacte lo siguiente:
          </p>
          <ul>
            <li className={style.textPolicy}>
              Los alcances del tratamiento de datos personales.
            </li>
            <li className={style.textPolicy}>
              Las actividades que el encargado realizará en nombre de BD GUIDANCE.
            </li>
            <li className={style.textPolicy}>
              Las obligaciones que debe cumplir el encargado respecto del titular del dato
              y BD GUIDANCE.
            </li>
            <li className={style.textPolicy}>
              La obligación del encargado de dar cumplimiento a las obligaciones del responsable
              observando la presente política.
            </li>
            <li className={style.textPolicy}>
              El deber del encargado de tratar los datos de acuerdo con la finalidad autorizada para
              el mismo y observando los principios establecidos en la ley colombiana y la presente
              política.
            </li>
            <li className={style.textPolicy}>
              La obligación del Encargado de proteger adecuadamente los datos personales y las bases
              de datos, así como de guardar confidencialidad respecto del tratamiento de los datos
              transmitidos.
            </li>
          </ul>
          <h2 className={style.subtitlePolicy}>15.Procedimientos para que los titulares puedan
            ejercer sus derechos.</h2>
          <p className={style.textPolicy}>Para realizar peticiones, consultas o reclamos con el fin
            de ejercer los derechos a conocer, actualizar, rectificar, suprimir los datos o revocar
            la autorización otorgada, el Titular o sus causahabientes pueden utilizar cualquiera de
            los canales de comunicación dispuestos por BD GUIDANCE.
          </p>
          <p className={style.textPolicy}>Todas las consultas y reclamos se canalizarán a través
            de los medios habilitados por BD GUIDANCE, a través de los correos electrónicos
            info@bdguidance.com y manuel.saavedra@bdguidance.com, el teléfono 3005979907 y en la
            calle 72 BIS # 6 – 44 oficina 501 Edificio APA de Bogotá, de lunes a viernes en el
            horario de atención al público (9:00 AM a 4:30 PM), quien adoptará mecanismos de prueba
            de la radicación y trámite de los mismos.
          </p>
          <ul>
            <p className={style.textPolicy}> <strong>Consultas</strong></p>
            <p className={style.textPolicy}>Solo las personas legitimadas para el ejercicio de los
              derechos en materia de protección de datos, podrán presentar consultas (de manera
              verbal o escrita) respecto de la información que reposa en las bases de BD GUIDANCE,
              por medio de los canales dispuestos para el efecto. Cuando se reciban dichas
              consultas será necesario dejar prueba de:
            </p>
            <ul>
              <li className={style.textPolicy}>
                Fecha de radicación o presentación de la consulta.
              </li>
              <li className={style.textPolicy}>
                Identificación de quien consulta.
              </li>
            </ul>
            <p className={style.textPolicy}>Una vez verificada la identidad del titular o la
              legitimidad de quien realiza la consulta, se le suministrarán los datos personales
              requeridos. La respuesta a la consulta deberá comunicarse al solicitante en un término
              máximo de diez (10) días hábiles contados a partir de la fecha de recibo de la misma.
              Cuando no fuere posible atender la consulta dentro de dicho término, se informará al
              interesado, expresando los motivos de la demora y señalando la fecha en que se
              atenderá su consulta, la cual en ningún caso podrá superar los cinco (5) días
              hábiles siguientes al vencimiento del primer término.
            </p>
          </ul>
          <ul>
            <p className={style.textPolicy}> <strong>Reclamos</strong></p>
            <p className={style.textPolicy}>El titular o las personas que se encuentran legitimadas
              para presentar reclamos, podrán mediante este, solicitar la corrección, actualización
              o supresión de los datos personales, además, elevar quejas por presunto
              incumplimiento de cualquiera de los deberes contenidos en la ley 1581 de 2012 y de la
              presente política.
            </p>
            <p className={style.textPolicy}>Los reclamos (verbales o escritos) podrán presentarse
              directamente por medio de los canales de BD GUIDANCE y deberá contener como mínimo la
              siguiente información:
            </p>
            <ul>
              <li className={style.textPolicy}>
                Identificación del titular o la persona legitimada.
              </li>
              <li className={style.textPolicy}>
                Narración precisa de los hechos que fundamentan la reclamación.
              </li>
              <li className={style.textPolicy}>
                Dirección de correo electrónico, dirección física y número de teléfono para informar
                estado del tramite y remitir la respuesta correspondiente.
              </li>
              <li className={style.textPolicy}>
                Documentos y todas las pruebas pertinentes que pretenda hacer valer.
              </li>
            </ul>
            <p className={style.textPolicy}>Cuando el reclamo resulte incompleto BD GUIDANCE
              requerirá al interesado dentro de los cinco (5) días siguientes a la recepción del
              reclamo para que subsane las fallas. Si transcurridos dos (2) meses desde la fecha
              del requerimiento, sin que el solicitante presente la información requerida, se
              entenderá que ha desistido del reclamo.
            </p>
            <p className={style.textPolicy}>En caso de que BD GUIDANCE no sea competente para
              resolverlo, dará traslado a quien corresponda en un término máximo de dos (2) días
              hábiles e informará de la situación al interesado.
            </p>
            <p className={style.textPolicy}>Una vez recibido el reclamo completo, BD GUIDANCE
              se incluirá en la base de datos una leyenda que diga “reclamo en trámite” y el motivo
              del mismo, en un término no mayor a dos (2) días hábiles. Dicha leyenda deberá
              mantenerse hasta que el reclamo sea decidido.
            </p>
            <p className={style.textPolicy}>El término máximo para atender el reclamo será de
              quince (15) días hábiles contados a partir del día siguiente a la fecha de su
              recibo. Cuando no fuere posible atender el reclamo dentro de dicho término, BD
              GUIDANCE informará al interesado los motivos de la demora y la fecha en que se
              atenderá su reclamo, la cual en ningún caso podrá superar los ocho (8) días
              hábiles siguientes al vencimiento del primer término.
            </p>
          </ul>
          <h2 className={style.subtitlePolicy}>16.Persona o área responsable de la protección de
            datos personales.</h2>
          <p className={style.textPolicy}>La Gerencia de BD GUIDANCE es la dependencia o persona
            encargada de la función de protección de datos la cual se puede contactar a través del
            correo electrónico info@bdguidance.com y manuel.saavedra@bdguidance.com y el teléfono
            3005979907 de Bogotá.
          </p>
          <h2 className={style.subtitlePolicy}>17.Video vigilancia.</h2>
          <ul>
            <li className={style.textPolicy}>
              BD GUIDANCE utiliza diversos medios de video-vigilancia instalados en diferentes
              sitios internos y externos de sus instalaciones u oficinas.
            </li>
            <li className={style.textPolicy}>
              BD GUIDANCE informa sobre la existencia de estos mecanismos mediante la difusión en
              sitios visibles de anuncios de video-vigilancia.
            </li>
            <li className={style.textPolicy}>
              La información recolectada se utilizará para fines de seguridad de las personas, los
              bienes e instalaciones. Esta información puede ser empleada como prueba en cualquier
              tipo de proceso ante cualquier tipo de autoridad y organización.
            </li>
          </ul>
          <h2 className={style.subtitlePolicy}>18.Aviso de privacidad</h2>
          <p className={style.textPolicy}>Cuando BD GUIDANCE no pueda poner a disposición del
            titular de la información las políticas de tratamiento de datos, informará por medio
            de del aviso de privacidad, dirigido al titular, la existencia de las políticas de
            tratamientos de datos y la forma de acceder a la misma, al momento de realizar la
            recolección de los datos personales.
          </p>
          <p className={style.textPolicy}>El aviso de publicidad de BD GUIDANCE tendrá como mínimo
            la siguiente información:
          </p>
          <ul>
            <p className={style.textPolicy}>1.Razón social, domicilio y datos de contacto del
              responsable del tratamiento.
            </p>
            <p className={style.textPolicy}>2.El tipo de tratamiento al cual serán sometidos
              los datos y la finalidad del mismo.
            </p>
            <p className={style.textPolicy}>3.Los derechos del titular
            </p>
            <p className={style.textPolicy}>4.Los mecanismos generales dispuestos por el
              responsable para que el titular conozca la política de tratamiento de la información
              y los cambios sustanciales que se produzcan en ella. En todos los casos, debe
              informar al titular cómo acceder o consultar la política de tratamiento de
              información.
            </p>
            <p className={style.textPolicy}>5.El carácter facultativo de la respuesta relativa a
              preguntas sobre datos sensibles.
            </p>
          </ul>
          <p className={style.textPolicy}>BD GUIDANCE podrá valerse de documentos físicos o
            electrónicos, mensajes de datos, medios telefónicos, verbales o cualquier medio
            tecnológico, siempre y cuando cumpla con el deber de informar al titular.
          </p>
          <h2 className={style.subtitlePolicy}>19.Otros documentos que hacen parte de esta
            política.</h2>
          <p className={style.textPolicy}>Esta política será de aplicación preferente en
            caso de existir eventual inconsistencia sobre tratamiento de datos personales
            entre los anteriores documentos y la presente.
          </p>
          <h2 className={style.subtitlePolicy}>20.Responsabilidad demostrada frente al
            tratamiento de datos personales.</h2>
          <p className={style.textPolicy}>BD GUIDANCE adoptará las medidas necesarias para,
            de ser necesario, demostrar ante la Superintendencia de Industria y Comercio la
            implementación de medidas apropiadas y efectivas para cumplir con sus obligaciones
            legales relacionadas con el tratamiento de datos personales. Dichas medidas serán
            consistentes con las instrucciones que para el efecto imparta la Superintendencia
            de Industria y Comercio, y los mandatos de los artículos 26 y 27 del decreto
            1377 de 2013.
          </p>
          <h2 className={style.subtitlePolicy}>21.Modificaciones a las políticas de tratamiento
            de datos personales.</h2>
          <p className={style.textPolicy}>BD GUIDANCE se reserva el derecho de modificar, en
            cualquier momento, de manera unilateral, sus políticas y procedimientos de tratamiento
            de datos personales. Cualquier cambio será publicado y anunciado. Además, se conservarán
            las versiones anteriores de la presente política de tratamiento de datos personales.
          </p>
          <p className={style.textPolicy}>El uso continuo de los servicios o no desvinculación de
            los mismos por el titular del dato después de la notificación de los nuevos lineamientos
            constituye la aceptación de la misma.
          </p>
          <h2 className={style.subtitlePolicy}>22.Fecha de entrada en vigencia de la presente
            política y período de vigencia de la base de datos.</h2>
          <p className={style.textPolicy}>Esta política fue aprobada luego de la expedición de la
            ley 1581 de 2012 y modificada para incorporar algunos aspectos que establecen los
            decretos 1377 de 2013, 886 de 2014 y 1074 de 2015 razón por la cual entrarán en vigencia
            las modificaciones a partir del diez (10) de junio de 2021.
          </p>
          <p className={style.textPolicy}>La vigencia de la base de datos será el tiempo razonable
            y necesario para cumplir las finalidades del tratamiento teniendo en cuenta lo dispuesto
            en el artículo 11 del decreto 1377 de 2013.
          </p>
          <h2 className={style.subtitlePolicy}>23.Datos del responsable del tratamiento.</h2>
          <ul>
            <p className={style.textPolicy}> <strong>Nombre o razón social:</strong>
              BD GUIDANCE S.A.S.
            </p>
            <p className={style.textPolicy}> <strong>NIT:</strong>
              900.657.897-4
            </p>
            <p className={style.textPolicy}> <strong>Domicilio o dirección:</strong>
              calle 72 BIS # 6 – 44 oficina 501 Edificio APA de Bogotá
            </p>
            <p className={style.textPolicy}> <strong>Correo electrónico:</strong>
              info@bdguidance.com y manuel.saavedra@bdguidance.com
            </p>
            <p className={style.textPolicy}> <strong>Teléfono:</strong>
              3005979907
            </p>
          </ul>
          <Checkbox label={'Acepta el tratamiento de sus datos'}
                    onChange={(evt) => {
                      setAuthData(evt.currentTarget.checked);
                      setGetOpenDialog(false);
                      }} />
        </DialogContent>
      </Dialog>
    </>
  );
};
DataPolicy.propTypes = {
  openDialog: PropTypes.bool.isRequired,
  onChange: PropTypes.bool.isRequired,
};

export default DataPolicy;

