import React, { useEffect, useState, useContext } from 'react';
import DataProcess from '../../api/InfoOport';
import Loader from '../loader/Loader';
import { Button } from '@rmwc/button';
import { Select } from '@rmwc/select';
import style from './showInfoProcess.module.css';
import FoldersActions from '../../api/Folder';
import { Dialog, DialogContent, DialogTitle } from '@rmwc/dialog';
import { useParams } from 'react-router-dom';
import { UserContext } from '../../contexts/user-context';
import NotificationContext from '../../contexts/notification-context';
import insertDataBigQuery from '../../api/insertBigQuery';
import { ERROR, SUCCESSFULL } from '../../utils/Messages';
import PropTypes from 'prop-types';

const ShowInfoProcess = ({ id }) => {
  const { currentUser } = useContext(UserContext);
  const { dispatchData } = useContext(NotificationContext);
  const { idOportunity } = useParams();
  const [infoOportunity, setInfoOportunity] = useState();
  const [initShowNamesFolder, setInitShowNamesFolders] = useState(false);
  const [namesFolderOptions, setNamesFolderOptions] = useState();
  const [nameFolder, setNamesFolder] = useState();
  const [loading, setLoading] = useState(true);
  const [idProcess, setIdProcess] = useState(id);
  useEffect(() => {
    if (idOportunity) {
      setIdProcess(idOportunity);
    }
    if (idProcess) {
     setLoading(idProcess);
     DataProcess.getDataInfo(idProcess)
       .then(({ data: dataProcessId }) => setInfoOportunity(dataProcessId))
       .catch((error) => dispatchData({ text: ERROR.SHOW_OPORTUNITY, error }))
       .finally(() => setLoading(false));
     const { userId, nameUser } = currentUser;
     insertDataBigQuery.insertBigQuery({ idProcess, userId, nameUser });
   }
 }, [id]);
  const optionsNamesFolders = async () => {
    const { userId, nameEnterprise, rol } = currentUser;
    FoldersActions.getNamesFolder({ userId, nameEnterprise, rol })
      .then(({ data }) => {
        const options = (data.data || []).map((oportunity) => ({
          label: oportunity.nameFolder,
          value: oportunity.folderId,
        }));
        setNamesFolderOptions(options);
      })
      .catch((e) => dispatchData({ text: ERROR.GENERAL_ERROR, error: e }));
  };
  const addOportuniyToFolder = () => {
    const { userId, nameEnterprise } = currentUser;
    FoldersActions.postAddProcessInFolder(idProcess, nameFolder, userId, nameEnterprise)
    .then(() => dispatchData({ text: SUCCESSFULL.SAVE_OPORTUNITY }))
    .catch(() => dispatchData({ text: ERROR.OPORTUNITY_EXISTS }));
  };

  return (
    <>
      <div style={{ maxWidth: '80vw' }}>
        <Dialog
            open={initShowNamesFolder}
            onClose={(evt) => setInitShowNamesFolders(false)}
          >
          <DialogTitle>Añadir a carpeta</DialogTitle>
          <DialogContent>
            <Select
                  label={'Carpetas'}
                  options={namesFolderOptions}
                  onChange={(e) => setNamesFolder(e.target.value)}
              />
            <Button label={'Guardar'} onClick={() => {
                addOportuniyToFolder();
                setInitShowNamesFolders(false);
              }}/>
          </DialogContent>
        </Dialog>
        {
            infoOportunity
              ? <>
                <div className={style.tabla_contenedor}>
                  <table className={style.tabla}>
                    <tr className={style.fila}>
                      <td className={style.celda} colSpan={'3'}
                          style={{ backgroundColor: '#FFC400' }}>
                        <h1
                          style={{
                            fontWeight: 'bold',
                            textAlign: 'left',
                            color: '#FFFF',
                            fontSize: '15px',
                            whiteSpace: 'pre-wrap',
                            wordWrap: 'break-word',
                            }}>
                          {infoOportunity.details.toUpperCase()} </h1>
                      </td>
                    </tr>
                    <tr className={style.fila}>
                      <td className={style.celda} colSpan={'2'}>
                        <h2 className={style.celda_titulo}>Descripcion Oportunidad</h2>
                        <p className={style.celda_descripcion}>
                          {infoOportunity.details.toUpperCase()}</p>
                      </td>
                    </tr>
                    <tr className={style.fila}>
                      <td className={style.celda}>
                        <h3 className={style.celda_titulo}>ID Oportunidad</h3>
                        <p className={style.celda_descripcion}>{infoOportunity.idProcess}</p>
                      </td>
                      <td className={style.celda}>
                        <h3 className={style.celda_titulo}>Entidad</h3>
                        <p className={style.celda_descripcion}>
                          {infoOportunity.nameEntity.toUpperCase()}</p>
                      </td>
                      <td className={style.celda}>
                        <h3 className={style.celda_titulo}>Codigos Principales</h3>
                        <p className={style.celda_descripcion}>
                          {infoOportunity.mainCategory.toUpperCase()}</p>
                      </td>
                    </tr>
                    <tr className={style.fila}>
                      <td className={style.celda}>
                        <h3 className={style.celda_titulo}>Presentación Oferta</h3>
                        <p className={style.celda_descripcion}>{infoOportunity.presentationDate}</p>
                      </td>
                      <td className={style.celda}>
                        <h3 className={style.celda_titulo}>Estado del proceso</h3>
                        <p className={style.celda_descripcion}>
                          {infoOportunity.stateProcess.toUpperCase()}</p>
                      </td>
                      <td className={style.celda}>
                        <h3 className={style.celda_titulo}>Lugar Ejecución</h3>
                        <p className={style.celda_descripcion}>
                          {infoOportunity.municipality.toUpperCase()}</p>
                      </td>
                    </tr>
                    <tr className={style.fila}>
                      <td className={style.celda}>
                        <h3 className={style.celda_titulo}>Cuantia del proceso</h3>
                        <p className={style.celda_descripcion}>
                          {infoOportunity.price.toUpperCase()}</p>
                      </td>
                      <td className={style.celda}>
                        <h3 className={style.celda_titulo}>Origen</h3>
                        <p className={style.celda_descripcion}>
                          {infoOportunity.origen.toUpperCase()}</p>
                      </td>
                      <td className={style.celda}>
                        <h3 className={style.celda_titulo}>Links Oportunidad Secop</h3>
                        <a className={style.celda_descripcion}
                           href={infoOportunity.urlProcess.url}
                           src={'url Secop'} target={'_blank'}
                           rel={'noopener noreferrer'}>CLICK AQUI</a>
                      </td>
                    </tr>
                    <tr className={style.fila}>
                      <td className={style.celda}>
                        <h3 className={style.celda_titulo}>Modalidad de Contratación</h3>
                        <p className={style.celda_descripcion}>
                          {infoOportunity.modalityHiring.toUpperCase()}</p>
                      </td>
                      <td className={style.celda}>
                        <h3 className={style.celda_titulo}>Fecha de ultima Publicación</h3>
                        <p className={style.celda_descripcion}>
                          {infoOportunity.lastDate.toUpperCase()}</p>
                      </td>
                      <td className={style.celda}>
                        <h3 className={style.celda_titulo}>Fecha de apertura</h3>
                        <p className={style.celda_descripcion}>
                          {infoOportunity?.publishedDate}</p>
                      </td>
                    </tr>
                    <tr className={style.fila}>
                      <td className={style.celda}>
                        <button
                        className= {style.formulario__btn}
                        onClick={() => {
                            optionsNamesFolders();
                            setInitShowNamesFolders(!initShowNamesFolder);
                          }}>Añadir a Carpeta</button>
                      </td>
                    </tr>
                  </table>
                </div>
              </>
              : loading
                ? <Loader />
                : <h2 style={{ textAlign: 'center' }}> No hay información para mostrar</h2>
          }
      </div>
    </>
  );
};

ShowInfoProcess.propTypes = {
  id: PropTypes.string,
};

export default ShowInfoProcess;
