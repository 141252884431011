import React, { useState, useEffect, useContext } from 'react';
import style from './showPartners.module.css';
import { Typography } from '@rmwc/typography';
import { TextField } from '@rmwc/textfield';
import { Checkbox } from '@rmwc/checkbox';
import { Button } from '@rmwc/button';
import userPartners from '../../api/showAndCreatedPartners';
import Loader from '../loader/Loader';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../../contexts/user-context';
import NotificationContext from '../../contexts/notification-context';
import userImage from '../../imgs/user avatar.png';
import { Dialog, DialogTitle, DialogContent, DialogActions, DialogButton } from '@rmwc/dialog';
import { ERROR, SUCCESSFULL, INFO } from '../../utils/Messages';
import MDIcon from '../mdc/MDIcon';
import { NavigationContext } from '../../contexts/navigation-context';

const ShowPartners = () => {
    const history = useHistory();
    const { currentUser } = useContext(UserContext);
    const { dispatchData } = useContext(NotificationContext);
    const [dataPartners, setDataPartners] = useState();
    const [idUserForPags, setIdUserForPags] = useState([]);
    const [pag, setPag] = useState(1); // paginas totales
    const [userSelected, setUserSelected] = useState('');
    const [userDelete, setUserDelete] = useState('');
    const [lastIndex, setLastIndex] = useState('');
    const [firstIndex, setFirstIndex] = useState('');
    const [haveNextPage, setHaveNextPage] = useState(false);
    const [previous, setPrevious] = useState(false);
    const { navigationHistory, goBack, addToHistory } = useContext(NavigationContext);

    useEffect(() => {
      addToHistory('showpartners');
    }, []);

    useEffect(() => {
        const { nameEnterprise = '' } = currentUser;
        const dataUser = {
            nameEnterprise,
            lastIndex,
            firstIndex,
            previous,
        };
        dispatchData({ text: INFO.SEARCH_PARTNER });
        userPartners.getUserPartners(dataUser)
            .then(({ data: dataUsers }) => {
                const { firstIndex, lastIndex, data, nextPage } = dataUsers;
                setFirstIndex(firstIndex);
                setLastIndex(lastIndex);
                setHaveNextPage(nextPage);
                setIdUserForPags(idUserForPags);
                setDataPartners(data);
            })
            .catch(e => dispatchData({ text: ERROR.SHOW_PARTNER, error: e }));
    }, [pag]);

    const editPermissions = (status, permission, email, nameEnterprise) => {
        const userRow = dataPartners.find(({ emailUser }) => {
            return emailUser === email;
        });
        const { permissions } = userRow;
        let permissionAux = permissions;
        const userIndex = dataPartners.indexOf(userRow);
        const firstPart = dataPartners.slice(0, userIndex);
        const lastPart = dataPartners.slice(userIndex + 1, dataPartners.lenght);
        if (!permissions.includes(permission) && status) {
          permissionAux.push(permission);
        }
        if (!status && permissions.includes(permission)) {
          permissionAux = permissionAux.filter((data) => data !== permission);
        }
        const body = {
            permissions: permissionAux,
        };
        userPartners.editCollaborator(email, nameEnterprise, body)
            .then((dataResponse) => {
                userRow.permissions = permissionAux;
                firstPart.push(userRow);
                setDataPartners([...firstPart, ...lastPart]);
            })
            .catch((error) => dispatchData({ text: ERROR.UPDATE_ERROR, error }));
    };

    const editCollaborator = (email, nameEnterprise, status) => {
        const userRow = dataPartners.find(({ emailUser }) => emailUser === email);
        const userIndex = dataPartners.indexOf(userRow);
        const firstPart = dataPartners.slice(0, userIndex);
        const lastPart = dataPartners.slice(userIndex + 1, dataPartners.lenght);
        const body = {
            delete: status,
        };
        userPartners.editCollaborator(email, nameEnterprise, body)
            .then((dataResponse) => {
                userRow.delete = status;
                firstPart.push(userRow);
                setDataPartners([...firstPart, ...lastPart]);
            })
            .catch((error) => dispatchData({ text: ERROR.UPDATE_ERROR, error }))
            .finally(() => status
              ? dispatchData({ text: SUCCESSFULL.DELETE_PARTNET })
              : dispatchData({ text: SUCCESSFULL.ACTIVE_PARTNET }));
    };

    const notifiactionCollaborator = (email, nameEnterprise, status, nameNotification) => {
        const userRow = dataPartners.find(({ emailUser }) => emailUser === email);
        const userIndex = dataPartners.indexOf(userRow);
        const firstPart = dataPartners.slice(0, userIndex);
        const lastPart = dataPartners.slice(userIndex + 1, dataPartners.lenght);
        const body = {
            [nameNotification]: status,
        };

        userPartners.editCollaborator(email, nameEnterprise, body)
            .then((dataResponse) => {
                userRow[nameNotification] = status;
                firstPart.push(userRow);
                setDataPartners([...firstPart, ...lastPart]);
            })
            .catch((error) => dispatchData({ text: ERROR.UPDATE_ERROR, error }));
    };

    const showCard = () => {
      return dataPartners.length === 0
      ? <div style={{ margin: 'auto' }}>
        <h2>Aún no tiene colaboradores registrados</h2>
      </div>
        : dataPartners.map((responseNames, index) => (
          <table className={style.tableStyle} key={index}>
            <tr>
              <td colSpan={'4'} className={style.style_td}>
                <button onClick={() => setUserDelete(responseNames.userId)}
                        className={style.btn_add}><MDIcon icon={'delete'}/>
                </button>
                <Dialog open={userDelete === responseNames.userId}
                        onClose={(evt) => setUserSelected('')}>
                  <DialogTitle>Desea Eliminar el colaborador {responseNames.nameUser}?</DialogTitle>
                  <DialogActions>
                    <DialogButton action={'close'}>Cancelar</DialogButton>
                    <DialogButton onClick={ () => {
                    editCollaborator(
                      responseNames.emailUser,
                      responseNames.nameEnterprise,
                      true);
                    setUserDelete('');
                    }}>Aceptar
                    </DialogButton>
                  </DialogActions>
                </Dialog>
              </td>
            </tr>
            <tr>
              <td colSpan={'4'} className={style.style_td}>
                <img src={userImage} alt={'user'} className={style.profile_pic}></img>
              </td>
            </tr>
            <tr>
              <td colSpan={'4'} className={style.style_td}>
                <h2 className={style.text_name}>{responseNames.nameUser || null}</h2>
                <h2 className={style.text_email}>{responseNames.emailUser || null}</h2>
              </td>
            </tr>
            <tr>
              <td className={style.style_td}>
                <button onClick={() => setUserSelected(responseNames.userId)}
                        className={style.btn_permisos}>Permisos</button>
                <Dialog
                        open={userSelected === responseNames.userId}
                        onClose={(evt) => setUserSelected('')}>
                  <DialogTitle>Permisos del Colaborador {responseNames.nameUser}</DialogTitle>
                  <DialogContent> Permisos <br />
                    <Checkbox label={'delete'}
                              checked={(responseNames.permissions || []).includes('delete')}
                              onChange={(evt) =>
                                    editPermissions(
                                        !!evt.currentTarget.checked,
                                        'delete',
                                        responseNames.emailUser,
                                        responseNames.nameEnterprise
                                    )}/>
                    <Checkbox label={'created'}
                              checked={(responseNames.permissions || []).includes('created')}
                              onChange={(evt) =>
                                    editPermissions(
                                        !!evt.currentTarget.checked,
                                        'created',
                                        responseNames.emailUser,
                                        responseNames.nameEnterprise
                                    )}/>
                    <Checkbox label={'update'}
                              checked={(responseNames.permissions || []).includes('update')}
                              onChange={(evt) =>
                                    editPermissions(
                                        !!evt.currentTarget.checked,
                                        'update',
                                        responseNames.emailUser,
                                        responseNames.nameEnterprise
                                    )}/>
                    <Checkbox label={'read'}
                              checked={(responseNames.permissions || []).includes('read')}
                              onChange={(evt) =>
                                    editPermissions(
                                        !!evt.currentTarget.checked,
                                        'read',
                                        responseNames.emailUser,
                                        responseNames.nameEnterprise
                                    )}/>
                  </DialogContent>
                  <DialogActions>
                    <DialogButton action={'exit'} isDefaultAction>Cerrar</DialogButton>
                  </DialogActions>
                </Dialog>
              </td>
            </tr>
            <tr>
              <td colSpan={'4'} className={style.style_td}>
                <h2 className={style.text_name}>Notificaiones Email</h2>
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '10px',
                  }}>
                  <p className={style.label_inactivo}
                     style={{ color: responseNames.notificationEmail ? '#D9D9D9' : '#9747FF' }}>
                    <MDIcon icon={'bell-sleep'}/>
                  </p>
                  <label className={style.switch}>
                    <input type={'checkbox'}
                           checked={responseNames.notificationEmail}
                           onChange={(evt) =>
                            notifiactionCollaborator(
                                responseNames.emailUser,
                                responseNames.nameEnterprise,
                                !!evt.currentTarget.checked,
                                'notificationEmail'
                            )}/>
                    <span className={style.slider}></span>
                  </label>
                  <p className={style.label_activo}
                     style={{ color: responseNames.notificationEmail ? '#9747FF' : '#D9D9D9' }}>
                    <MDIcon icon={'bell-ring'}/>
                  </p>
                </div>
              </td>
            </tr>
            <tr>
              <td colSpan={'4'} className={style.style_td}>
                <h2 className={style.text_name}>Notificaiones Telegram</h2>
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '10px',
                  }}>
                  <p className={style.label_inactivo}
                     style={{ color: responseNames.notificationTelegram ? '#D9D9D9' : '#9747FF' }}>
                    <MDIcon icon={'bell-sleep'}/>
                  </p>
                  <label className={style.switch}>
                    <input type={'checkbox'}
                           checked={responseNames.notificationTelegram}
                           onChange={(evt) =>
                                   notifiactionCollaborator(
                                       responseNames.emailUser,
                                       responseNames.nameEnterprise,
                                       !!evt.currentTarget.checked,
                                       'notificationTelegram'
                                   )}/>
                    <span className={style.slider}></span>
                  </label>
                  <p className={style.label_activo}
                     style={{ color: responseNames.notificationTelegram ? '#9747FF' : '#D9D9D9' }}>
                    <MDIcon icon={'bell-ring'}/>
                  </p>
                </div>
              </td>
            </tr>
            <tr>
              <td colSpan={'4'} className={style.style_td}>
                <h2 className={style.text_name}>Estado del Colaborador</h2>
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '10px',
                  }}>
                  <p className={style.label_inactivo}
                     style={{ color: responseNames.delete ? '#9747FF' : '#D9D9D9' }}>
                    Inactivo
                  </p>
                  <label className={style.switch}>
                    <input type={'checkbox'}
                           checked={!responseNames.delete}
                           onChange={(evt) =>
                            editCollaborator(
                                responseNames.emailUser,
                                responseNames.nameEnterprise,
                                !evt.currentTarget.checked
                            )} />
                    <span className={style.slider}></span>
                  </label>
                  <p className={style.label_activo}
                     style={{ color: responseNames.delete ? '#D9D9D9' : '#9747FF' }}>
                    Activo
                  </p>
                </div>
              </td>
            </tr>
          </table>
        ));
    };

    return (<>
      <div className={'generalContainer'}>
        {navigationHistory.length > 1 && (
          <button className={style.buttonPop} type={'button'} onClick={goBack}>Regresar</button>
      )}
        <div className={'containerModule flex-column'}>
          <Typography use={'headline2'} style={{ padding: '35px' }}>Colaboradores</Typography>
          <div className={style.container_navSearch}>
            <button className={style.createColaborator}
                    onClick={() => history.push('createdPartners')}>Crear Nuevo</button>
            <TextField placeholder={'Buscar'}/>
          </div>
          <div className={style.table_container}>{dataPartners ? showCard() : <Loader />}</div>
          <div className={style.container__butonsPag}>
            { pag > 1 && <button className={style.butonsPag}
                                 onClick={() => {
                              setPrevious(true);
                              setPag((pag - 1));
                           }}>
              <MDIcon icon={'arrow-left'} />Anterior
            </button> }
            <div className={style.centro}>
              <Button key={pag} className={style.butonsPagNumber} label={pag}/>
            </div>
            { haveNextPage && <button className={style.butonsPag} onClick={() => {
                  setPrevious(false);
                  setPag((pag + 1));
                }}>Siguiente<MDIcon icon={'arrow-right'} /></button>
            }
          </div>
        </div>
      </div>
    </>
    );
};
export default ShowPartners;
