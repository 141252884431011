import { api, getResponseData, escalateError } from './index.js';

export default class DataRegister {
    static postRegis(dataUser) {
        return api.post('api/regi', dataUser)
            .then(getResponseData)
            .catch(escalateError);
    }

    static getUsers(dataUsers) {
        return api.get(`api/regi?email=${dataUsers}`)
            .then(getResponseData)
            .catch(escalateError);
    }

    static async validateEnterprise(email, enterprise) {
        return api.get(`/api/loginenterprise?emailUser=${email}&nameEnterprise=${enterprise}`)
            .then(getResponseData)
            .catch(escalateError);
    }

    static async editUser(emailUser, body) {
        return api.post(`api/regi/update?emailUser=${emailUser}`, body)
            .then(getResponseData)
            .catch(escalateError);
    }

    static async getUserByEmail(email) {
        return api.get(`api/regi/user?email=${email}`)
            .then(getResponseData)
            .catch(escalateError);
    }
};

