import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@rmwc/textfield';

const SelecDateRecep = ({ onChange, changeDate }) => {
    const [dateFilters, setDateFilters] = useState({});
    const [preventReload, setPreventReload] = useState(true);

    useEffect(() => {
      if (Object.values(dateFilters).length > 1 && dateFilters.dateEnd) {
        setPreventReload(false);
        return onChange(dateFilters);
      } else if (changeDate && !preventReload) {
        const dataSet = changeDate || {};
        return setDateFilters(dataSet);
      }
    }, [dateFilters]);

    return (
      <>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
          <TextField label={'fechaInicio'} type={'date'} onChange={(e) => {
            setDateFilters({ ...dateFilters, dateStart: e.target.value });
        }}/>
          <TextField label={'fechaFin'} type={'date'} onChange={(e) => {
            setPreventReload(true);
            setDateFilters({ ...dateFilters, dateEnd: e.target.value });
        }}/>
        </div>
      </>
);
};
SelecDateRecep.propTypes = {
    onChange: PropTypes.func.isRequired,
    changeDate: PropTypes.func,
};

export default SelecDateRecep;
