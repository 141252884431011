/* eslint-disable max-lines */
import React, { useState, useEffect, useContext } from 'react';
import {
  DataTable, DataTableContent, DataTableRow, DataTableHeadCell,
  DataTableHead, DataTableBody
} from '@rmwc/data-table';
import { Select } from '@rmwc/select';
import { useParams } from 'react-router-dom';
import style from './result.module.css';
import { TextField } from '@rmwc/textfield';
import { Chip, ChipSet } from '@rmwc/chip';
import { Button } from '@rmwc/button';
import MDIcon from '../mdc/MDIcon';
import VentanaDialogo from './VentanaDialogo';
import Loader from '../loader/Loader';
import DataApi from '../../api/Result'; // Resultados de la tabla
import TableBody from './TableBody'; // componente para mostrar el cuerpo de los datos
import SelecLocat from '../perfilUsers/SeleccionLugar';
import SelecDateRecep from './SelecDateRecep';
import { UserContext } from '../../contexts/user-context';
import { ResultContext } from '../../contexts/result-context';
import NotificationContext from '../../contexts/notification-context';
import FoldersActions from '../../api/Folder';
import { ERROR, SUCCESSFULL } from '../../utils/Messages';
import ShowOrigen from './showOrigen';
import { NavigationContext } from '../../contexts/navigation-context';
import Utils from '../../utils/Utils';

const Result = () => {
  const { currentUser } = useContext(UserContext);
  const {
    profileContext,
    setProfileContext,
    stateContextProfile,
    setStateContextProfile,
} = useContext(ResultContext);
  const { dispatchData } = useContext(NotificationContext);
  const [data, setData] = useState([]);
  const [dataKeysSend, setDataKeysSend] = useState([]);
  const [isSuccesDelete, setIsSuccesDelete] = useState(false);
  const [limitDataSelectUser, setLimitDataSelectUser] = useState(5);
  const [pag, setPag] = useState(0);
  const [initFilter, setInitFilter] = useState(false);
  const [titleFilters, setTitleFilter] = useState(''); // titulos Filtros
  const [captureDataTextFilters, setCaptureDataTextFilters] = useState('');
  const [table, setTable] = useState([]);
  const numRegisters = [5, 10, 15, 20, 25]; // numero de datos que se visualizan en la tabla
  const [dataType, setDataType] = useState([]);
  const [idOporIsUp, setIdOportIsUp] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [profile, setProfile] = useState('');
  const [lastIndex, setLastIndex] = useState('');
  const [firstIndex, setFirstIndex] = useState('');
  const [haveNextPage, setHaveNextPage] = useState(false);
  const [folderName, setFolderName] = useState('');
  const [previous, setPrevious] = useState(false);
  const { idFolder } = useParams();
  const [saveIdProfile, setSaveIdProfile] = useState({});
  const { navigationHistory, goBack, addToHistory } = useContext(NavigationContext);

  useEffect(() => {
    navigationHistory.length === 0 && addToHistory('');
  }, []);

  useEffect(() => {
    if (!idFolder) {
      const { nameEnterprise = '', userId, rol } = currentUser;
      DataApi.getPerfilUser(userId, nameEnterprise, rol)
        .then(({ data: responseData }) => {
          const idsProfile = {};
          const namesProfiles = responseData.map(({ nameProfile, profileId }) => {
            idsProfile[nameProfile] = profileId;
            return nameProfile;
          });
          setSaveIdProfile(idsProfile);
          setDataType(namesProfiles);
        })
        .catch(e => dispatchData({ text: ERROR.SHOW_PREFERENCE, error: e }));
    }
  }, [idFolder]);

    useEffect(() => {
      if (!idFolder) {
        setIsLoading(true);
          SecopII();
      } else {
        setIsSuccesDelete(false);
        getOportunitiesByFolder();
      }
    }, [idFolder,
        pag,
        limitDataSelectUser,
        profile,
        dataKeysSend,
        captureDataTextFilters,
        isSuccesDelete,
      ]);
  const SecopII = async () => {
    const { nameEnterprise } = currentUser;
    if (table.length > 0) setTable([]);
    const { userId } = currentUser;
    const body = {
        limite: parseInt(limitDataSelectUser),
        pag,
        nameProfile: saveIdProfile[profile] || '',
        userId,
        enterprise: nameEnterprise || false,
        dataFilter: captureDataTextFilters || '',
        lastIndex,
        firstIndex,
        previous,
      };

    let dataSearch;
    if (!stateContextProfile) {
        setProfileContext(body);
        dataSearch = body;
    } else {
      dataSearch = profileContext;
    }
    DataApi.getDataApi({ ...dataSearch })
      .then(({ data: dataResponse }) => {
        const { lastIndex, data: oportunities, nextPage, firstIndex } = dataResponse;
        setLastIndex(lastIndex);
        setFirstIndex(firstIndex);
        setTable(oportunities);
        setHaveNextPage(nextPage);
        setData(oportunities);
        if (stateContextProfile) {
          if (pag === 0 && captureDataTextFilters.length === 0) {
            setPag(dataSearch.pag);
          }
          captureDataTextFilters.length > 0 && setPag(0);
          if (pag === 0 && captureDataTextFilters.length === 0) {
            setCaptureDataTextFilters(dataSearch.dataFilter);
          }
          captureDataTextFilters.length > 0 && setCaptureDataTextFilters('');
          saveIdProfile[profile] = dataSearch.nameProfile;
          setStateContextProfile(false);
        }
      })
      .catch(e => dispatchData({ text: ERROR.GENERAL_ERROR, error: e }))
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getOportunitiesByFolder = () => {
    const { nameEnterprise = '', userId } = currentUser;
    setIsLoading(true);
    FoldersActions.getDatabyFolder(idFolder, nameEnterprise, userId)
    .then(({ data: responseData }) => {
      const { dataOportunity, nameFolder } = responseData;
      setTable(dataOportunity);
      setFolderName(nameFolder);
      setData(dataOportunity);
    })
    .catch((error) => dispatchData({ text: ERROR.GENERAL_ERROR, error }))
    .finally(() => setIsLoading(false));
  };

  const clearTableToGetDataTitle = (dataKeys) => { // refactorizar
    setPag(0);
    if (dataKeysSend.includes(dataKeys) === false && dataKeys.length > 0) {
      const arraySend = dataKeysSend;
      arraySend.push(dataKeys);
      setDataKeysSend(arraySend);
      return arraySend;
    } else if (idFolder) {
      return setTable(data.filter(({ titulo, detalles }) =>
        titulo.toLowerCase().includes(dataKeys.toLowerCase())
        || detalles.toLowerCase().includes(dataKeys.toLowerCase())));
    }
    return null;
  };

  const clearFilter = () => {
      setIsLoading(true);
      setPag(0);
      setCaptureDataTextFilters('');
      setStateContextProfile(false);
      setLastIndex('');
      setFirstIndex('');
      setProfile('');
      setDataKeysSend([]);
      setTable([]);
      setTitleFilter('');
      dispatchData({ text: SUCCESSFULL.CLEAR_FILTER });
  };
  const getValuesOfObject = (dataObject, detArrayValues) => {
    if (detArrayValues) {
      const dataGet = Object.entries(dataObject).map(([key, value]) => Object.values(value))
      .flat(Infinity);
      return dataGet.filter(data => data).flat(Infinity);
    }
    const dataGet = Object.entries(dataObject).map(([key, value]) => {
      return Object.values(value).flat(Infinity)
      .filter(data => data);
    });
    const dataReturn = dataGet.flat(Infinity).filter(data => data);
    return dataReturn;
  };

  const createChips = () => {
    if (captureDataTextFilters) {
      const joinChip = Object.keys(captureDataTextFilters).length > 0
      && !Object.keys(captureDataTextFilters).includes('Lugar')
        ? Object.values(captureDataTextFilters).flat(Infinity)
        : getValuesOfObject(captureDataTextFilters);

      if (dataKeysSend.length <= 0) return <></>;
      const arrayKeys = [...new Set(joinChip)];
      return (
        <div className={style.componenteChips}>
          {
            arrayKeys.map((responseData, index) => (
              <ChipSet key={`${responseData}-${index}`}>
                <Chip
                  onRemove={() => {
                    const value = Object.entries(captureDataTextFilters).map(([key, value]) => {
                      const getArrays = key === 'Lugar'
                      ? getValuesOfObject(captureDataTextFilters, true)
                      : value;
                      const dataNotExist = getArrays.map((responseValue) => {
                        if (responseValue !== responseData) return responseValue;
                        return null;
                      });
                      const validateData = dataNotExist.filter(data => data);
                      if (validateData.length > 0) {
                        return { [key]: validateData };
                      }
                      return null;
                    });
                    if (value) {
                      const [dataSend] = value.flat(Infinity);
                      return setCaptureDataTextFilters(dataSend);
                    }
                    return setCaptureDataTextFilters({});
                  }
                }
                  key={index} label={responseData} trailingIcon={<MDIcon icon={'close'} />} />
              </ChipSet>
            ))}
        </div>
      );
    }
    return null;
  };

  return (<>
    <div className={'generalContainer'}>
      <div className={'containerModule flex-column'}>
        {navigationHistory.length > 6 && (
          <button type={'button'} onClick={goBack}>Regresar</button>
      )}
        <h1 className={style.title}>
          { !idFolder ? 'RESULTADO BUSQUEDA OPORTUNIDADES' : `Contenido de carpeta ${folderName}` }
        </h1>
        <div className={style.container_navSearch}>
          <Select
            label={'Número Registros'} options={numRegisters} name={'numReg'}
            type={'number'} onChange={(e) => setLimitDataSelectUser(e.target.value)}
            className={style.contenedorReg__select}
            enhanced
            disabled={Boolean(idFolder)}
          />
          <TextField className={style.styleSearch}
                     title={'La búsqueda se realiza en Título, Detalles y Entidad.'}
                     placeholder={'Buscar...'}
                     onKeyPress={(e) => {
                      if ((e.keyCode || e.which) === 13) {
                        const stringNormaliced = e.currentTarget.value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
                        const getArray = clearTableToGetDataTitle(stringNormaliced);
                        getArray && setCaptureDataTextFilters(
                          { ...captureDataTextFilters, keyWord: getArray });
                      };
                     }}
                     />
          <Select label={'Perfiles'} options={['-- Seleccionar --', ...dataType]}
                  value={profile}
                  onChange={(evt) => setProfile(evt.currentTarget.value)}
                  disabled={Boolean(idFolder)} />
          <Button label={'Limpiar Fitros'}
                  className={style.cleanDataButton}
                  disabled={idFolder}
                  onClick={() => {
                    clearFilter();
                    setProfile('');
                    }}/>
        </div>
        <VentanaDialogo title={titleFilters !== 'MUNICIPIO EJECUCION'
          && `Este es el Filtro de ${titleFilters}`} open={initFilter}
                        body={
            titleFilters === 'MUNICIPIO EJECUCION'
              ? <SelecLocat existMun = {true} onChange={(dataResponse) => {
                if (!Object.keys(dataResponse).length > 0) return;
                const dataChange = Object.entries(dataResponse).filter(([key, value]) => value)
                  .flat(Infinity);
                const [, values] = dataChange;
                clearTableToGetDataTitle(values);
                setCaptureDataTextFilters({ ...captureDataTextFilters, Lugar: dataResponse });
              }} dataFilterMun={ captureDataTextFilters && captureDataTextFilters.Lugar}/>
                : titleFilters === 'FECHA APERTURA'
                  ? <SelecDateRecep onChange={(dataRecep) => {
                    if (Object.entries(dataRecep).length <= 0) return;
                    clearTableToGetDataTitle(dataRecep);
                    setCaptureDataTextFilters({ ...captureDataTextFilters, rangeDate: dataRecep });
                    setInitFilter(false);
                  }} />
                  : titleFilters === 'ORIGEN'
                  && <ShowOrigen onChange={(e) => {
                    if (!e) return;
                    setLastIndex('');
                    setFirstIndex('');
                    clearTableToGetDataTitle(e);
                    setCaptureDataTextFilters({ ...captureDataTextFilters, Origen: [e] });
                    setInitFilter(false);
                  }} changeData={captureDataTextFilters && captureDataTextFilters.Origen} />
          } onClose={() => setInitFilter(false)}
        />
        {captureDataTextFilters && createChips()}
        <>
          {
            (table.length > 0)
            ? (<>
              <DataTable className={style.table_expand}>
                <DataTableContent>
                  <DataTableHead className={style.encabezado}>
                    <DataTableRow>
                      <DataTableHeadCell className={style.container__Filters}>
                        <span className={style.spanHeader}>
                          ID
                          <Button className={style.container__Filters}
                                  onClick={(e) => {
                                    setIdOportIsUp(!idOporIsUp);
                                    setTable(Utils.orderArray(data, 'id', idOporIsUp ? -1 : 1));
                                  }}>
                            <MDIcon icon={idOporIsUp ? 'arrow-up-bold' : 'arrow-down-bold'}
                                    size={16} className={style.iconStyle}/>
                          </Button>
                        </span>
                      </DataTableHeadCell>
                      <DataTableHeadCell className={style.container__Filters}>
                        <span className={style.spanHeader}> TITULO </span>
                      </DataTableHeadCell>
                      <DataTableHeadCell className={style.container__Filters}>
                        <span className={style.spanHeader}> DETALLES </span>
                      </DataTableHeadCell>
                      <DataTableHeadCell className={style.container__Filters}>
                        <span className={style.spanHeader}> ENTIDAD </span>
                      </DataTableHeadCell>
                      <DataTableHeadCell className={style.container__Filters}>
                        <span className={style.spanHeader}>
                          IDENOPORT
                          {
                            !idFolder && (
                              <Button className={style.container__Filters}
                                      onClick={(e) => {
                                    setIdOportIsUp(!idOporIsUp);
                                    setTable(Utils.orderArray(
                                      data,
                                      'idenOpor',
                                      idOporIsUp ? -1 : 1));
                                  }}>
                                <MDIcon icon={idOporIsUp ? 'arrow-up-bold' : 'arrow-down-bold'}
                                        size={16} className={style.iconStyle}/>
                              </Button>)
                          }

                        </span>
                      </DataTableHeadCell>
                      <DataTableHeadCell className={style.container__Filters}>
                        <span className={style.spanHeader}>
                          MUNICIPIO EJECUCION
                          {
                            !idFolder && (
                              <Button className={style.container__Filters}
                                      onClick={(e) => {
                                        setInitFilter(true);
                                        setTitleFilter('MUNICIPIO EJECUCION');
                                      }}>
                                <MDIcon name={'titulo'} icon={'filter-menu'} size={16}
                                        className={style.iconStyle}/>
                              </Button>)
                          }
                        </span>
                      </DataTableHeadCell>
                      <DataTableHeadCell className={style.container__Filters}>
                        <span className={style.spanHeader}>
                          ORIGEN
                          {
                            !idFolder && (
                              <Button className={style.container__Filters}
                                      onClick={() => {
                                        setInitFilter(true);
                                        setTitleFilter('ORIGEN');
                                      }}>
                                <MDIcon name={'titulo'} className={style.iconStyle}
                                        icon={'filter-menu'} size={16} />
                              </Button>)
                          }
                        </span>
                      </DataTableHeadCell>
                      <DataTableHeadCell className={style.container__Filters}>
                        <span className={style.spanHeader}>
                          CUANTIA PROCESO
                        </span>
                      </DataTableHeadCell>
                      <DataTableHeadCell className={style.container__Filters}>
                        <span className={style.spanHeader}>
                          ESTADO PROCESO
                        </span>
                      </DataTableHeadCell>
                      <DataTableHeadCell className={style.container__Filters}>
                        <span className={style.spanHeader}>
                          FECHA APERTURA
                          {
                            !idFolder && (
                              <Button className={style.container__Filters}
                                      onClick={(e) => {
                                        setInitFilter(true);
                                        setTitleFilter('FECHA APERTURA');
                                      }}>
                                <MDIcon name={'titulo'} className={style.iconStyle}
                                        icon={'filter-menu'} size={16} />
                              </Button>)
                          }
                        </span>
                      </DataTableHeadCell>
                      <DataTableHeadCell className={style.container__Filters}>
                        <span className={style.spanHeader}> ENLACE </span>
                      </DataTableHeadCell>
                      {
                        idFolder && <DataTableHeadCell
                        className={style.container__Filters}>
                          <span className={style.spanHeader}> ACCIONES </span>
                        </DataTableHeadCell>
                      }
                    </DataTableRow>
                  </DataTableHead>
                  <DataTableBody className={style.class_bodyTable}>
                    {limitDataSelectUser
                      && <TableBody table={table} idFolder={idFolder}
                                    onChange={(evt) => setIsSuccesDelete(evt.success)} />
                    }
                  </DataTableBody>
                </DataTableContent>
              </DataTable>
              <div className={style.container__butonsPag}>
                { pag > 0
                && <button className={style.butonsPag}
                           onClick={() => {
                              setPrevious(true);
                              setPag((pag - 1));
                           }}>
                  <MDIcon icon={'arrow-left'} />Anterior
                </button> }
                <div className={style.centro}>
                  <Button key={pag} className={style.butonsPagNumber} label={pag + 1} />
                </div>
                { haveNextPage
                && <button className={style.butonsPag} onClick={() => {
                  setPrevious(false);
                  setPag((pag + 1));
                }}>
                  Siguiente
                  <MDIcon icon={'arrow-right'} />
                </button>
                }
              </div>
            </>)
            : <div style={{ margin: 'auto' }}>
              { (isLoading
                ? <Loader />
                : <h2>No hay resultados para mostrar</h2>
                ) }
            </div>
          }
        </>
      </div>
    </div>
  </>);
};

export default Result;
