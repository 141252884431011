/* eslint-disable consistent-return */
import React, { useState, useEffect, useContext } from 'react';
import style from './perfilamientoUsers.module.css';
import { Card } from '@rmwc/card';
import { Typography } from '@rmwc/typography';
import { Button } from '@rmwc/button';
import selecTypeProcess from '../../api/selectTipoProceso';
import { Checkbox } from '@rmwc/checkbox';
import { TextField } from '@rmwc/textfield';
import { Tooltip } from '@rmwc/tooltip';
import PropTypes from 'prop-types';
import NotificationContext from '../../contexts/notification-context';
import { ERROR } from '../../utils/Messages';

const SelecType = ({ onChange, postData }) => {
  const { dispatchData } = useContext(NotificationContext);
  const [checkedAll, setCheckedAll] = useState(false);
  const [typeProcess, setTypeProcess] = useState([]);
  const [dataType, setDataType] = useState([]);
  const [searchData, setSearchData] = useState('');
  const [filterData, setFilterData] = useState([]);
  const [preventReload, setPreventReload] = useState(false);

  useEffect(() => {
    const filterOptions = dataType.filter((elemet) => elemet.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .includes(searchData.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()));
    setFilterData(filterOptions);
  }, [searchData]);

  useEffect(() => {
    selecTypeProcess.getTypeOfProcess().then(responseData => {
        const { data } = responseData || {};
        setDataType(data);
    })
    .catch((e) => dispatchData({ text: ERROR.GENERAL_ERROR, error: e }));
  }, []);
  useEffect(() => {
    if (postData && !preventReload) {
      setTypeProcess([...typeProcess, ...postData]);
      return setPreventReload(true);
    } else if (typeProcess.length >= 0) {
      return onChange(typeProcess);
    };
  }, [typeProcess, postData]);

  const controllerChecked = (isChecked, label) => {
    let processAux = typeProcess;
    processAux = isChecked
    ? processAux.concat(label)
    : processAux.filter((element) => element !== label);
    setTypeProcess(processAux);
  };
  const allProcess = () => {
    setCheckedAll(!checkedAll);
    setTypeProcess(checkedAll ? [] : [...dataType]);
  };
  return (
    <>
      <div className={style.containerCon}>
        <Typography use={'headline6'} style={{ color: '#9747FF' }}>
          Seleccion Tipo Proceso</Typography>
        <Typography use={'body6'} style={{ color: '#ADADAD' }}>
          Selecciona aqui los filtros de búsqueda de oportunidades según los
          tipos de proceso que se manejan en Colombia
        </Typography>
        <div className={style.containerSelct}>
          <Tooltip content={'Busqueda del tipo de Proceo'}>
            <TextField onChange={(e) => {
                          setSearchData(e.target.value);
                      }} placeholder={'Buscar... '}/>
          </Tooltip>
          <Button style={{ color: '#9747FF' }}
                  onClick={() => allProcess()}
                  label={'Seleccionar todo'}
         />
        </div>
        <Card className={style.containerRowsLoc} style={{ background: '#F6F6F6' }}>
          {dataType
            && (filterData.length > 0
              ? filterData
              : dataType).map((responseData, index) =>
            (<Checkbox key={index} label={responseData} className={style.checkBoxStyle}
                       checked={checkedAll || typeProcess.includes(responseData)}
                       onChange={(e) => controllerChecked(e.currentTarget.checked, responseData)}/>)
                )
            }
        </Card>
      </div>
    </>
  );
};
SelecType.propTypes = {
    onChange: PropTypes.func.isRequired,
    postData: PropTypes.oneOfType(
      [PropTypes.object, PropTypes.arrayOf(PropTypes.element)]),
};
export default SelecType;
