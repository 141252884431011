/* eslint-disable multiline-ternary */
import React, { useState, useEffect, useContext } from 'react';
import { ProfileContext } from '../../contexts/profile-context';
import { Chip, ChipSet } from '@rmwc/chip';
import MDIcon from '../mdc/MDIcon';
import PropTypes from 'prop-types';

const CodesUN = ({ newSearch, onChipRemove }) => {
  const [dataFilter, setDataFilter] = useState([]);
  const { dataProfile, setDataUserProfile } = useContext(ProfileContext);

  useEffect(() => {
    const { Clasificacion } = dataProfile ?? {};
    const arrayForConcatCodes = [];
    if (Clasificacion) {
      Object.keys(Clasificacion).forEach((key) => {
        Object.values(Clasificacion[key]).forEach((values) => arrayForConcatCodes.push(values));
      });
    }
    setDataFilter(arrayForConcatCodes);
  }, []);

  useEffect(() => {
    if (Object.keys(newSearch).length > 0) {
      const arrayForConcatCodes = [...dataFilter];
      arrayForConcatCodes.push(newSearch);
      const objectNewData = {};
      arrayForConcatCodes.forEach((data) => {
        const [keyCodesId] = Object.keys(data);
        const getIdCodes
        = keyCodesId.length === 4
        ? 'Familia' : keyCodesId.length === 2 ? 'Segmento'
        : keyCodesId.length === 6 ? 'Clase' : 'Producto';

        if (!objectNewData[getIdCodes]) objectNewData[getIdCodes] = [];
        objectNewData[getIdCodes].push(data);
      });

      setDataUserProfile({
        ...(dataProfile ?? {}),
        Clasificacion: objectNewData,
      });
      setDataFilter(arrayForConcatCodes);
      onChipRemove(arrayForConcatCodes);
    }
  }, [newSearch]);

  const showChips = () => {
    return dataFilter.map((responseObject, index) => {
      const [data] = Object.entries(responseObject);
      const [codId, nameCodeId] = data;

      if (codId === undefined && nameCodeId === undefined) {
        return null;
      }
      return (
        <ChipSet key={codId}>
          <Chip
            label={`${codId} - ${nameCodeId}`}
            trailingIcon={<MDIcon icon={'close'} />}
            onRemove={() => handleChipRemove(codId)}
          />
        </ChipSet>
      );
    });
  };

  const handleChipRemove = (codId) => {
    const newDataFilter = dataFilter.filter((dataGet) => {
      const [keyCode] = Object.keys(dataGet);
      return parseInt(keyCode) !== parseInt(codId);
    });
    setDataFilter(newDataFilter);
    onChipRemove(newDataFilter);
  };

  return <>{dataFilter.length > 0 && showChips()}</>;
};

CodesUN.propTypes = {
  newSearch: PropTypes.object.isRequired,
  onChipRemove: PropTypes.func.isRequired,
};

export default CodesUN;
