/* eslint-disable camelcase */
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import style from './result.module.css';
import { DataTableCell, DataTableRow } from '@rmwc/data-table';
import { UserContext } from '../../contexts/user-context';
import FoldersActions from '../../api/Folder';
import { Button } from '@rmwc/button';
import NotificationContext from '../../contexts/notification-context';
import MDIcon from '../mdc/MDIcon';
import { SUCCESSFULL } from '../../utils/Messages';
import ShowInfoProcess from '../showInfoProcess/ShowInfoProcess';

const TableBody = ({
  table,
  idFolder,
  onChange = undefined,
}) => {
  const { currentUser } = useContext(UserContext);
  const { dispatchData } = useContext(NotificationContext);
  const [idOportDialog, setIdOportDialog] = useState('');
  const deleteoportunityFolderAction = (idProcess) => {
    const { userId, nameEnterprise } = currentUser;
    FoldersActions.postDeleteOportunityOfFolder({
      idProcess,
      folderId: idFolder,
      userId,
      nameEnterprise,
    }).then(responseData => {
      dispatchData({ text: SUCCESSFULL.DELETE_OPORTUNITY });
      onChange({ success: true });
    })
    .catch(e => {
      dispatchData({ text: e.message, error: e });
      onChange({ success: false });
    });
  };
  const formaterPeso = new Intl.NumberFormat(
    'es-CO',
    {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 0,
    });

  return (
    <>
      {
        table.map((oportunity, index) => (
          <DataTableRow className={style.table}
                        key={`${oportunity?.idenOpor}-${index}`}>
            <DataTableCell className={style.select__Table}
                           onClick={() => setIdOportDialog(oportunity?.idenOpor)}>
              <p style={{ fontSize: '12px', whiteSpace: 'normal' }}>
                {(oportunity?.id || 'NA')}</p>
            </DataTableCell>
            <DataTableCell className={style.select__Table}
                           onClick={() => setIdOportDialog(oportunity?.idenOpor)}>
              <p style={{ fontSize: '12px', width: '400px', whiteSpace: 'normal' }}>
                {(oportunity?.titulo || 'NA')}</p>
            </DataTableCell>
            <DataTableCell className={style.select__Table}
                           onClick={() => setIdOportDialog(oportunity?.idenOpor)}>
              <p style={{ fontSize: '12px', width: '400px', whiteSpace: 'normal' }}>
                {(oportunity?.detalles || 'NA')}</p>
            </DataTableCell>
            <DataTableCell className={style.select__Table}
                           onClick={() => setIdOportDialog(oportunity?.idenOpor)}>
              <p style={{ fontSize: '12px', width: '200px', whiteSpace: 'normal' }}>
                {(oportunity?.entidad || 'NA')}</p>
            </DataTableCell>
            <DataTableCell className={style.select__Table}
                           onClick={() => setIdOportDialog(oportunity?.idenOpor)}>
              <p style={{ fontSize: '12px', whiteSpace: 'normal' }}>
                {(oportunity?.idenOpor || 'NA')}</p>
            </DataTableCell>
            <DataTableCell className={style.select__Table}
                           onClick={() => setIdOportDialog(oportunity?.idenOpor)}>
              <p style={{ fontSize: '12px', whiteSpace: 'normal' }}>
                {(oportunity?.municipio_ejecucion || 'NA')}</p>
            </DataTableCell>
            <DataTableCell className={style.select__Table}
                           onClick={() => setIdOportDialog(oportunity?.idenOpor)}>
              <p style={{ fontSize: '12px', whiteSpace: 'normal' }}>
                {(oportunity?.origen || 'NA')}</p>
            </DataTableCell>
            <DataTableCell className={style.select__Table}
                           onClick={() => setIdOportDialog(oportunity?.idenOpor)}>
              <p style={{ fontSize: '12px', whiteSpace: 'normal' }}>
                {(oportunity?.precio
                  ? formaterPeso.format(oportunity.precio)
                  : 'NA')}</p>
            </DataTableCell>
            <DataTableCell className={style.select__Table}
                           onClick={() => setIdOportDialog(oportunity?.idenOpor)}>
              <p style={{ fontSize: '12px', whiteSpace: 'normal' }}>
                {(oportunity?.estado_proceso || 'NA')}</p>
            </DataTableCell>
            <DataTableCell className={style.select__Table}
                           onClick={() => setIdOportDialog(oportunity?.idenOpor)}>
              <p style={{ fontSize: '12px', whiteSpace: 'normal' }}>
                {(oportunity?.fecha_recepcion || 'NA')}</p>
            </DataTableCell>
            <DataTableCell className={style.select__Table} >
              <a style={{ fontSize: '12px', whiteSpace: 'normal' }}
                 href={oportunity?.direccion_enlace['url']}
                 target={'_blank'} rel={'noreferrer'}>Click Aqui</a>
            </DataTableCell>
            {idOportDialog === oportunity?.idenOpor && (
              <div className={style.myDialog}>
                <div className={style.dialogContent}>
                  <button onClick={(evt) => setIdOportDialog('')} className={style.closeButton}>
                    X
                  </button>
                  <ShowInfoProcess id={oportunity?.idenOpor} />
                </div>
              </div>
            )}
            {
              idFolder && <DataTableCell> <Button
              style={{ color: 'red' }}
              onClick={() => deleteoportunityFolderAction(oportunity?.idenOpor)} >
                <MDIcon icon={'delete'} />
              </Button> </DataTableCell>
            }
          </DataTableRow >))
        }
    </>);
};
TableBody.propTypes = {
  table: PropTypes.array.isRequired,
  limit: PropTypes.number,
  keyChange: PropTypes.string,
  nameFilter: PropTypes.string,
  keyChangeObject: PropTypes.object,
  idFolder: PropTypes.string,
  onChange: PropTypes.func,
};
export default TableBody;
