import { api, escalateError, getResponseData } from './index';
export default class selecMod {
    static async getModality() {
        return api.get('/api/selecMod')
            .then(getResponseData)
            .catch(escalateError);
    }

    static async postModality(modData, userEmail, nameProfile, nameEnterprise) {
        const answerBack = await api.post('/api/selecMod', { // cambiar para manejo de errores
            dataMod: modData,
            userEmail,
            nameProfile,
            nameEnterprise,
        });
        const { data } = answerBack;
        return data;
    }

    static async getOriginModality() {
        return api.get('/api/selecMod/origen')
            .then(getResponseData)
            .catch(escalateError);
    }
}
