import React, { useEffect, useState, useContext } from 'react';
import { Typography } from '@rmwc/typography';
import { Dialog, DialogTitle, DialogActions, DialogButton } from '@rmwc/dialog';
import { UserContext } from '../../contexts/user-context';
import { Tooltip } from '@rmwc/tooltip';
import { Button } from '@rmwc/button';
import MDIcon from '../mdc/MDIcon';
import NotificationContext from '../../contexts/notification-context';
import { ERROR, SUCCESSFULL } from '../../utils/Messages';
import VentanaDialogo from '../resultSearch/VentanaDialogo';
import { TextField } from '@rmwc/textfield';
import Profiles from '../../api/PerfilUser';
import style from './usersProfileActions.module.css';
import { useHistory } from 'react-router-dom';
import { ProfileContext } from '../../contexts/profile-context';
import { NavigationContext } from '../../contexts/navigation-context';

const UsersProfileActions = () => {
    const { currentUser } = useContext(UserContext);
    const { dispatchData } = useContext(NotificationContext);
    const { setDataUserProfile } = useContext(ProfileContext);
    const [profileSelected, setProfileSelected] = useState('');
    const [dataProfile, setDataProfile] = useState([]);
    const [permissionsUser, setPermissionsUser] = useState([]);
    const [newNameProfile, setNewNameProfile] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [deletedProfileActions, setDeletedProfileActions] = useState({});
    const [searchProfile, setSearchProfile] = useState('');
    const [idUserChange, setIdUserChange] = useState('');
    const [dataUpdate, setDataUpdate] = useState({});
    const history = useHistory();
    const { navigationHistory, goBack, addToHistory } = useContext(NavigationContext);

    useEffect(() => {
      addToHistory('editProfils');
    }, []);

    useEffect(() => {
      if (currentUser && searchProfile.length <= 0) {
          const { nameEnterprise = '', userId, rol, permissions } = currentUser;
          setPermissionsUser(permissions);
          setIdUserChange(userId);
          Profiles.getDtaProfileByRol(userId, nameEnterprise, rol)
              .then(({ data }) => {
                const objectData = {};
                data.map(({ deleted, profileId, ...all }) => {
                  const deleteProfile = deleted;
                  objectData[profileId] = deleteProfile;
                });
                setDeletedProfileActions(objectData);
                setDataProfile(data);
              })
              .catch((e) => dispatchData({ text: ERROR.SHOW_PREFERENCE }));
      } else if (searchProfile.length > 0) {
        const dataFilter = dataProfile.map(({ nameProfile, ...all }) => {
          const normalizedData = nameProfile.toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '');
          if (normalizedData.search(searchProfile) !== -1) return { nameProfile, ...all };
          return null;
        });
        const clearnData = dataFilter.filter(data => data);
        setDataProfile(clearnData);
      }
    }, [currentUser, searchProfile]);

    const deletedProfile = (idProfile, userId, nameEnterprise, dataProfile) => {
      Profiles.deletedProfiles({
        userId,
        idProfile,
        nameEnterprise,
        dataProfile,
       })
       .then(() => {
        const mesaggeText = !deletedProfileActions[idProfile]
          ? SUCCESSFULL.DELETED_PROFILE
          : SUCCESSFULL.UPDATE_DELETED_PROFILE;
        return dispatchData({ text: mesaggeText });
      })
       .catch((e) => {
        return dispatchData({ text: ERROR.DELETED_ERROR });
      });
    };

    const updateDataProfile = () => {
      const { nameProfile, userId, nameEnterprise, profileId, ...all } = dataUpdate;
        const nameProfilesUser = newNameProfile.length > 0 ? newNameProfile : nameProfile;
        Profiles.updateProfiles({
          userId,
          nameEnterprise,
          profileId,
          dataProfile: {
            ...all,
            nameProfile: nameProfilesUser,
            updateBy: idUserChange,
          },
        }).then(() => {
          dispatchData({ text: SUCCESSFULL.UPDATE_PROFILE });
          window.location.reload();
        })
        .catch(() => dispatchData({ text: ERROR.UPDATE_ERROR }));
    };

    const showBodyByProfile = () => {
      if (dataProfile.length > 0) {
        return dataProfile.map(({
          deleted,
          nameProfile,
          nameUser,
          profileId,
          userId,
          nameEnterprise,
          ...all
        }, index) => {
            return (
              <table className={style.tableStyle} key={index}>
                <tr>
                  <td colSpan={'4'} className={style.style_td} style={{ textAlign: 'right' }}>

                    {
                 permissionsUser.includes('delete')
                 && <Tooltip content={
                   <div style={{ display: 'flex' }}>
                     <div style={{ marginLeft: '0.5rem' }}>
                       <b>Eliminar Perfil</b>
                       <div>Podras eliminar está Preferencia</div>
                     </div>
                   </div>}
                             align={'right'}
                 >
                   <Button
                 disabled={deletedProfileActions[profileId]}
                 className={style.btn_add}
                 style={{ color: '#FFC400' }}

                 onClick={() => setProfileSelected(profileId)} >
                     <MDIcon icon={'delete'} />
                   </Button>
                 </Tooltip>
              }
                    <Dialog open={profileSelected === profileId}
                            onClose={(evt) => setProfileSelected('')}>
                      <DialogTitle>¿Desea elminiar la preferencia {nameProfile}?</DialogTitle>
                      <DialogActions>
                        <DialogButton action={'exit'} isDefaultAction>
                          Cancelar
                        </DialogButton>
                        <DialogButton onClick={() => {
                          const statusProfile = !deletedProfileActions[profileId];
                          setDeletedProfileActions(
                            {
                            ...deletedProfileActions,
                            [profileId]: statusProfile,
                          });
                            deletedProfile(profileId, userId, nameEnterprise, {
                              nameProfile,
                              profileId,
                              nameEnterprise,
                              ...all,
                              deleted: statusProfile,
                              updateBy: idUserChange,
                            });
                          setProfileSelected('');
                          }}> Eliminar
                        </DialogButton>
                      </DialogActions>
                    </Dialog>
                    {
              permissionsUser.includes('update')
                && <Tooltip content={
                  <div style={{ display: 'flex' }}>
                    <div style={{ marginLeft: '0.5rem' }}>
                      <b>Editar Perfil</b>
                      <div>Podras cambiar el nombre de este Perfil</div>
                    </div>
                  </div>}
                            align={'right'}
                 >
                  <Button style={{ color: '#FFC400' }}
                          className={style.btn_add}
                          onClick={() => {
                            setDataUpdate({
                              deleted,
                              nameProfile,
                              nameUser,
                              profileId,
                              userId,
                              nameEnterprise,
                              ...all,
                              });
                            setOpenDialog(!openDialog);
                            }}>
                    <MDIcon icon={'folder-edit'} />
                  </Button>
                </Tooltip>
              }
                    {
                 permissionsUser.includes('read')
                 && <Tooltip content={
                   <div style={{ display: 'flex' }}>
                     <div style={{ marginLeft: '0.5rem' }}>
                       <b>Actualizar Perfil</b>
                       <div>Podrás Actualizar todos los filtros en este perfil</div>
                     </div>
                   </div>}
                             align={'right'}
                  >
                   <Button
                      className={style.button}
                      style={{ color: '#FFC400' }}
                      id={style.update}
                      onClick={() => {
                        setDataUserProfile({
                          ...all,
                          deleted,
                          nameProfile,
                          nameUser,
                          profileId,
                          userId,
                          nameEnterprise,
                        });
                        history
                        .push(`/showComponents/${profileId}`);
                        }}>
                     <MDIcon icon={'clipboard-file'} />
                   </Button>
                 </Tooltip>
              }
                  </td>
                </tr>
                <tr>
                  <td colSpan={'4'} className={style.style_td}>
                    <h2 className={style.text_name}>Nombre Preferencia: </h2>
                    <h2 className={style.text_email}>{nameProfile || null}</h2>
                  </td>
                </tr>
                <tr>
                  <td colSpan={'4'} className={style.style_td}>
                    <h2 className={style.text_name}>Creador: </h2>
                    <h2 className={style.text_email}>{nameUser || null}</h2>
                  </td>
                </tr>
                <tr>
                  {
              permissionsUser.includes('delete')
              && <td colSpan={'4'} className={style.style_td}>
                <h2 className={style.text_name}>Estado de la Preferencia</h2>
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '10px',
                  }}>
                  <p className={style.label_inactivo}
                     style={{ color: deletedProfileActions[profileId] ? '#9747FF' : '#D9D9D9' }}>
                    Inactiva
                  </p>
                  <label className={style.switch}>
                    <input type={'checkbox'}
                           checked={!deletedProfileActions[profileId]}
                           onChange={(e) => {
                            const statusProfile = !deletedProfileActions[profileId];
                            setDeletedProfileActions(
                              {
                              ...deletedProfileActions,
                              [profileId]: statusProfile,
                            });
                              deletedProfile(profileId, userId, nameEnterprise, {
                                nameProfile,
                                profileId,
                                nameEnterprise,
                                ...all,
                                deleted: statusProfile,
                                updateBy: idUserChange,
                              });
                            }}/>
                    <span className={style.slider}></span>
                  </label>
                  <p className={style.label_activo}
                     style={{
                      color: deletedProfileActions[profileId]
                        ? '#D9D9D9'
                        : '#9747FF',
                    }}>
                    Activa
                  </p>
                </div>
              </td>
              }
                </tr>
              </table>
        );
    });
      }
      return null;
    };
    return (
      <>
        <div className={'generalContainer'}>
          {navigationHistory.length >= 1 && (
            <button className={style.buttonPop} type={'button'} onClick={goBack}>Regresar</button>
          )}
          <div className={'containerModule flex-column'}>
            <VentanaDialogo title={'Cambio Nombre Perfil'}
                            body={ <TextField onKeyPress={(e) => {
                              if ((e.keyCode || e.which) === 13) setOpenDialog(false);
                            }} onChange={(e) => setNewNameProfile(e.currentTarget.value)
                            } />
            } open={openDialog} onClose={(e) => {
              setOpenDialog(false);
              if (e.detail.action === 'accept') updateDataProfile();
              }}/>
            <Typography use={'headline3'} style={{ color: '#6914A6' }}>
              Preferencias Usuarios
            </Typography>
            <div className={style.container__searchButton}>
              <TextField placeholder={'Buscar Perfil...'}
                         onChange={(e) => {
                          const nomalizedData = (e.currentTarget.value).toLowerCase()
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '');
                          setSearchProfile(nomalizedData);
                }}/>
            </div>
            <div className={style.table_container}>  {
              permissionsUser.includes('read') && (dataProfile
                    ? <>  { showBodyByProfile() } </>
                    : <Typography use={'body1'}>
                      No tiene permisos para ver este componente
                    </Typography>)
            }</div>
          </div>
        </div>
      </>
    );
};

export default UsersProfileActions;
