import React, { useContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import NotificationContext from '../contexts/notification-context';
import PopupLoginContext from '../contexts/popup-context';
import { UserContext } from '../contexts/user-context';

import { INFO } from '../utils/Messages';

const reducer = (data, action) => {
  if (action && action.text && action.text.length > 0) {
    return {
      ...data, show: true, text: action.text, type: action.type, error: action.error,
    };
  }
  return { ...data, show: false };
};

const NotificationProvider = ({ children }) => {
  const { dispatchData: dispatchPopUp } = useContext(PopupLoginContext);
  const { reauthenticate, signOut } = useContext(UserContext);
  const [data, dispatchData] = useReducer(reducer, {
    show: false,
    text: '',
    type: '',
    error: null,
  });

  const relogin = async () => {
    await reauthenticate();
    window.location.reload();
  };

  useEffect(() => {
    if (data.error?.status !== 401) return;
    dispatchPopUp({
      message: INFO.SESSION_EXPIRED,
      title: INFO.SESSION_EXPIRED_TITLE,
      buttonLabel: 'Iniciar sesión',
      btnCancel: 'Cerrar sesión',
      onclick: async () => await relogin(),
      oncancel: async () => await signOut(),
      onclosed: () => dispatchPopUp(),
    });
  }, [data.error]);

  return (
    <NotificationContext.Provider value={{ data, dispatchData }}>
      {children}
    </NotificationContext.Provider>
  );
};
NotificationProvider.propTypes = { children: PropTypes.node };
export default NotificationProvider;
