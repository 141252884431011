import React from 'react';
import DrawerWrapper from '../mdc/drawer/DrawerWrapper';
import SiteBody from '../mdc/SiteBody';

const NotFound = () => {
  return (
    <>
      <DrawerWrapper/>
      <SiteBody>
        <h1>Not Found</h1>
      </SiteBody>
    </>
  );
};

export default NotFound;
