import React, { useContext } from 'react';
import { BrowserRouter } from 'react-router-dom';
import UserData, { UserContext } from './contexts/user-context';
import Routes from './routes/Routes';
import MaterialWrapper from './components/mdc/MaterialWrapper';
import Toolbar from './components/mdc/Toolbar';
import NotificationContext from './data/notification-data';
import LoginPopUpContext from './data/popup-data';
import Notification from './components/Notification/Notification';
import NavBarLate from './components/navBarLateral/NavBarLateral';
import PopUp from './components/PopUp/PopUp';
import ProfileContextProvider from './contexts/profile-context';
import ResultContextProvider from './contexts/result-context';
import LocatedContextProvider from './contexts/located-context';
import NavigationProvider from './contexts/navigation-context';
import { listenignUser } from './utils/activityUser';
import firebase from './firebase';

const App = () => {
  const minutes = 1000 * 60;
  const { signIn } = useContext(UserContext);

  const relogin = async () => {
    await signIn;
  };
  listenignUser();
  setInterval(() => {
    const exist = window.localStorage.getItem('activate');
    if (window.localStorage.getItem('token')) {
      if (!exist) {
        getOut();
        return;
      }
      window.localStorage.removeItem('activate');
      firebase.auth().currentUser.getIdToken(true)
      .then(function (idToken) {
        localStorage.setItem('token', idToken);
      });
    }
  }, [10 * minutes]);

  const getOut = () => {
    setTimeout(async () => {
      const exist = window.localStorage.getItem('activate');
      if (exist) {
        window.localStorage.removeItem('activate');
        await relogin();
      } else {
        localStorage.clear();
        await firebase.auth().signOut();
        window.location.href = '/';
      }
    }, [2 * minutes]);
  };
  return (<>
    <BrowserRouter>
      <UserData>
        <LoginPopUpContext>
          <NotificationContext>
            <ProfileContextProvider>
              <LocatedContextProvider>
                <Toolbar />
                <NavBarLate />
                <MaterialWrapper>
                  <NavigationProvider>
                    <ResultContextProvider>
                      <Routes />
                    </ResultContextProvider>
                  </NavigationProvider>
                </MaterialWrapper>
                <Notification />
                <PopUp />
              </LocatedContextProvider>
            </ProfileContextProvider>
          </NotificationContext>
        </LoginPopUpContext>
      </UserData>
    </BrowserRouter>
  </>);
};

export default App;
