import React, { useEffect, useState, useContext } from 'react';
import { Select } from '@rmwc/select';
import PropTypes from 'prop-types';
import ShowOrig from '../../api/ShowOrigen';
import NotificationContext from '../../contexts/notification-context';
import { ERROR } from '../../utils/Messages';

const ShowOrigen = ({ onChange, changeData }) => {
  const { dispatchData } = useContext(NotificationContext);
    const [dataOrig, setDataOrig] = useState([]);
    const [datChangeOrig, setDataChangeOrig] = useState('');
    const [preventReload, setPreventReload] = useState(true);

    useEffect(() => {
        ShowOrig.getDataOrigen().then((responseData) => {
            const { data } = responseData;
            const { Modalidades } = data;
            setDataOrig(['-- Seleccionar --', ...Modalidades.flat(Infinity)]);
        })
        .catch(() => dispatchData({ text: ERROR.GET_ORIGINS }));
    }, []);

    useEffect(() => {
      if (preventReload && datChangeOrig) {
        setPreventReload(false);
        onChange(datChangeOrig);
      } else {
        setDataChangeOrig('');
      };
    }, [datChangeOrig, changeData]);

    return (
      <>
        {dataOrig
        && <div>
          <Select label={'Origen'} options={dataOrig}
                  onChange={(e) => {
                    console.log(e.currentTarget.value);
                    setPreventReload(true);
                    if (e.currentTarget.value !== '-- Seleccionar --') {
                      return setDataChangeOrig(e.currentTarget.value);
                    }
                    return null;
                    }}/>
        </div>}
      </>
    );
};
ShowOrigen.propTypes = {
    onChange: PropTypes.func.isRequired,
    changeData: PropTypes.func,
};
export default ShowOrigen;
